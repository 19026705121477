import { Duration } from "luxon";

export const formatDuration = (seconds: number, format = ""): string => {
  const units = Duration.fromObject({ seconds })
    .shiftTo("hours", "minutes")
    .toObject();

  return format
    .replace("{hours}", String(units.hours || 0))
    .replace("{minutes}", String(units.minutes || 0));
};
