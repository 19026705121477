/** @jsx jsx */
import { Props } from "gatsby-theme-footer/src/components/Footer/Component";
import { StoreBadges } from "gatsby-theme-store-badges";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import sxStyles from "./styles";

const FooterComponent: React.FC<Props> = ({
  socialLinksComponent,
  secondaryMenuComponent,
  includeMobileLinks,
}) => {
  return (
    <div sx={sxStyles.footer}>
      {socialLinksComponent && (
        <div sx={sxStyles.socialLinks}>{socialLinksComponent}</div>
      )}
      {includeMobileLinks && (
        <StoreBadges displayAndroidLink displayIOSLink buttonSize={"SMALL"} />
      )}
      <div sx={sxStyles.secondaryMenu}>{secondaryMenuComponent}</div>
    </div>
  );
};

export default memo(FooterComponent);
