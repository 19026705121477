/** @jsx jsx */
import React, { memo, useState } from "react";
import { FormattedNumber } from "shared/components/FormattedNumber";
import useIntl, { FormattedMessage } from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import usePageContext from "shared/hooks/usePageContext";
import { Button, Flex, Grid, Input, jsx, Label, Spinner } from "theme-ui";

import { getGiftCardBalance } from "../../helpers";
import useLoyaltySettings from "../../hooks/useLoyaltySettings";
import ErrorMessage from "./ErrorMessage";
import { getCurrencyFromCountryCode } from "./helpers";
import messages from "./i18n";

type FormShape = {
  giftCardNumber: string;
};

interface Props {
  widgetId: string;
}

const GiftCardBalanceChecker: React.FC<Props> = ({ widgetId }) => {
  const [balance, setBalance] = useState<number | null | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(undefined);
  const { formatMessage } = useIntl();
  const pageContext = usePageContext();

  const { values, setValue, setFieldTouched, errors, touched, isValid } =
    useForm<FormShape>({
      initialValues: {
        giftCardNumber: "",
      },
      validationSchema: yup.object({
        giftCardNumber: yup.string().required("required"),
      }),
    });

  const config = useLoyaltySettings();

  const onSubmit = async () => {
    setLoading(true);
    setError(undefined);
    getGiftCardBalance(config, { id: values.giftCardNumber })
      .then((data) => {
        setLoading(false);
        if (data.peachCode === 0 && data.id) {
          setBalance(data.balanceRemaining);
        } else {
          setBalance(null);
          setError(formatMessage(messages.genericError));
        }
      })
      .catch(() => {
        setLoading(false);
        setBalance(null);
        setError(formatMessage(messages.genericError));
      });
  };

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (!error) {
          await onSubmit();
        }
      }}
    >
      <Grid gap={4}>
        <div>
          <Label htmlFor={`${widgetId}-loyalty-gift-card-number`}>
            {`${formatMessage(messages.giftCardNumberField)} *`}
          </Label>
          <Input
            id={`${widgetId}-loyalty-gift-card-number`}
            name={"giftCardNumber"}
            type={"text"}
            value={values.giftCardNumber}
            onChange={(event) => {
              setValue("giftCardNumber", event.target.value);
              setBalance(null);
              setError(undefined);
            }}
            onBlur={() => {
              setFieldTouched("giftCardNumber");
            }}
          />
          {touched.giftCardNumber && errors.giftCardNumber && (
            <ErrorMessage type={errors.giftCardNumber} />
          )}
        </div>

        <Flex sx={{ alignItems: "center", gap: 3 }}>
          <div>
            <Button
              variant={"buttons.secondary"}
              disabled={!isValid || loading}
              type={"submit"}
            >
              {formatMessage(messages.submit)}
            </Button>
          </div>
          <div>
            {loading && (
              <div>
                <Spinner size={"2rem"} />
              </div>
            )}
            {error && <div sx={{ fontWeight: "bold" }}>{error}</div>}
            {!error && balance && (
              <div sx={{ fontWeight: "bold" }}>
                <FormattedMessage
                  {...messages.results}
                  values={{
                    value: (
                      <FormattedNumber
                        value={balance / 100}
                        style="currency"
                        currency={getCurrencyFromCountryCode(
                          pageContext.intl.country.code
                        )}
                      />
                    ),
                  }}
                />
              </div>
            )}
          </div>
        </Flex>
      </Grid>
    </form>
  );
};

export default memo(GiftCardBalanceChecker);
