/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import type { ScheduleOptionsBarComponentProps } from "gatsby-theme-schedule-options-bar";
import React from "react";
import { jsx } from "theme-ui";

const ScheduleOptionsBarComponent: React.FC<ScheduleOptionsBarComponentProps> =
  ({
    theaterSelectorRender,
    collectionSelectorRender,
    periodSelectorRender,
    view,
    fullWidth,
  }) => {
    const canSeePeriodSelector = periodSelectorRender && view === "NOW_PLAYING";

    return (
      <div
        sx={{
          display: responsive({ lg: "grid" }),
          gridTemplateColumns: responsive({
            xs: "1fr",
            lg: fullWidth ? undefined : "1fr 10fr 1fr",
            xl: fullWidth ? undefined : "2fr 8fr 2fr",
          }),
          "&:before": {
            content: responsive({ lg: '""' }),
          },
          "&:after": {
            content: responsive({ lg: '""' }),
          },
        }}
      >
        <div>
          {(collectionSelectorRender || theaterSelectorRender) && (
            <div
              sx={{
                display: responsive({ md: "flex" }),
                justifyContent: responsive({ md: "center" }),
                alignItems: responsive({ md: "center" }),
                marginBottom:
                  canSeePeriodSelector && periodSelectorRender ? 4 : undefined,
              }}
            >
              {collectionSelectorRender && (
                <div
                  sx={{
                    marginBottom: responsive({ xs: 4, md: 0 }),
                    marginRight: theaterSelectorRender
                      ? responsive({ xs: 0, md: 4 })
                      : undefined,
                  }}
                >
                  {collectionSelectorRender}
                </div>
              )}
              {theaterSelectorRender ? (
                <div sx={{ flex: 1 }}>{theaterSelectorRender}</div>
              ) : null}
            </div>
          )}
          {canSeePeriodSelector && periodSelectorRender && (
            <div
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {periodSelectorRender}
            </div>
          )}
        </div>
      </div>
    );
  };

export default React.memo(ScheduleOptionsBarComponent);
