export const getPresentationHeight = (
  presentationWidth: number,
  ratio: number | null | undefined
): number => {
  return ratio ? Math.ceil(presentationWidth / ratio) : 0;
};

export const getPresentationWidth = (
  presentationHeight: number,
  ratio: number | null | undefined
): number => {
  return Math.ceil(presentationHeight * (ratio || 0));
};
