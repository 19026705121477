/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import {
  DidomiInstance,
  DidomiRegulation,
} from "gatsby-theme-trackers/declarations";
import React, { memo, useEffect, useRef, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import { preparePortalMenuItem } from "shared/helpers/menu/portalItem";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import type { SecondaryMenuQuery } from "../../../__generated__/gatsby.types";
import messages from "./i18n";
import Menu, { Item } from "./Menu";

const SecondaryMenu: React.FC<Record<string, unknown>> = () => {
  const data = useStaticQuery<SecondaryMenuQuery>(graphql`
    query SecondaryMenu {
      menu(position: { eq: FOOTER }) {
        items {
          label
          url
          page {
            slug
            parentType
            childPages {
              slug
              relatedEntity {
                id
              }
            }
          }
        }
      }
    }
  `);

  const [selectedTheaterId] = useSelectedTheaterId();
  const { formatMessage } = useIntl();
  const didomiInstance = useRef<DidomiInstance | null>(null);
  const [hasRegulation, setHasRegulation] = useState<boolean>(false);
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;

    if (!window.didomiOnReady) {
      window.didomiOnReady = [];
    }

    window.didomiOnReady.push((_didomiInstance) => {
      let regulation: DidomiRegulation | null = null;
      didomiInstance.current = _didomiInstance;

      if (didomiInstance.current?.isRegulationApplied?.("ccpa")) {
        regulation = "ccpa";
      }

      if (didomiInstance.current?.isRegulationApplied?.("gdpr")) {
        regulation = "gdpr";
      }

      switch (regulation) {
        case "gdpr":
        case "ccpa": {
          if (isMountedRef.current) {
            setHasRegulation(true);
          }
          break;
        }
        default:
          break;
      }
    });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const items: Item[] = [];

  data.menu?.items?.forEach((item) => {
    if (item) {
      const preparedItem = preparePortalMenuItem(item, selectedTheaterId);
      items.push(preparedItem);
    }
  });

  if (hasRegulation) {
    items.push({
      __typename: "MenuItem",
      label: formatMessage(messages.privacySettings),
      url: "/#",
      onClick: (event) => {
        event.preventDefault();
        didomiInstance.current?.preferences?.show?.();
      },
    });
  }

  return <Menu items={items} />;
};

export default memo(SecondaryMenu);
