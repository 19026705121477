/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import { Button, Flex, Grid, Input, jsx, Label, Spinner } from "theme-ui";

import { sendUsernameReminder } from "../../../helpers";
import { LoyaltyConfig } from "../../../types";
import ErrorMessage from "../ErrorMessage";
import messages from "./i18n";

type FormShape = {
  email: string;
};

interface Props {
  widgetId: string;
  loyaltySettings: LoyaltyConfig;
  handleGoBack: (message?: string) => void;
  email?: string;
}

const ForgotUsername: React.FC<Props> = ({
  widgetId,
  loyaltySettings,
  handleGoBack,
  email,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { values, setValue, setFieldTouched, errors, touched, isValid } =
    useForm<FormShape>({
      initialValues: {
        email: email || "",
      },
      validationSchema: yup.object({
        email: yup.string().email().required("required"),
      }),
    });

  const onSubmit = async () => {
    setLoading(true);
    sendUsernameReminder(loyaltySettings, values.email)
      .then((data) => {
        if (data.peachCode === 0) {
          handleGoBack(formatMessage(messages.success));
        } else {
          handleGoBack(formatMessage(messages.genericError));
        }
      })
      .catch(() => {
        handleGoBack(formatMessage(messages.genericError));
      });
  };

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (!errors?.email) {
          await onSubmit();
        }
      }}
    >
      <Grid columns={responsive({ xs: "1fr" })}>
        <div>
          <Label htmlFor={`${widgetId}-loyalty-email`}>
            {`${formatMessage(messages.usernameField)} *`}
          </Label>
          <Input
            id={`${widgetId}-loyalty-email`}
            name={"email"}
            type={"text"}
            value={values.email}
            onChange={(event) => {
              setValue("email", event.target.value);
            }}
            onBlur={() => {
              setFieldTouched("email");
            }}
          />
          {touched.email && errors.email && (
            <ErrorMessage type={errors.email} />
          )}
        </div>

        <div>
          <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
            <div>
              <Button
                variant={"buttons.secondary"}
                disabled={!isValid || loading}
                type={"submit"}
              >
                {formatMessage(messages.submit)}
              </Button>
            </div>
            <div sx={{ marginLeft: 3 }}>
              {loading && (
                <div>
                  <Spinner size={"2rem"} />
                </div>
              )}
            </div>
          </Flex>
        </div>
        <div
          role="button"
          sx={{
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={() => {
            handleGoBack();
          }}
        >
          {formatMessage(messages.goBack)}
        </div>
      </Grid>
    </form>
  );
};

export default memo(ForgotUsername);
