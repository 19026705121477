/** @jsx jsx */
import React, { memo } from "react";
import { Flex, jsx } from "theme-ui";

const SelectArrow: React.FC = () => (
  <Flex
    sx={{
      ml: -44,
      alignSelf: "center",
      pointerEvents: "none",
      border: "2px solid",
      borderColor: "primary",
      borderRadius: "0 4px 4px 0",
      color: "black",
      height: 44,
      width: 44,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#ECEBEB",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
    </svg>
  </Flex>
);

export default memo(SelectArrow);
