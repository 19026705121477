/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo, ReactNode } from "react";
import { jsx } from "theme-ui";

const PeriodSelectorComponentWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <div
      sx={{
        display: responsive({ sm: "flex" }),
        minHeight: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gap: responsive({ sm: 2 }),
      }}
    >
      {children}
    </div>
  );
};

export default memo(PeriodSelectorComponentWrapper);
