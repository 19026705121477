/** @jsx jsx */
import { Heading, responsive } from "@boxoffice/screenplay";
import React, { Fragment, memo, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  jsx,
  Label,
  Spinner,
  Themed,
} from "theme-ui";

import { createNonTieredMember, createTieredMember } from "../../helpers";
import useLoyaltySettings from "../../hooks/useLoyaltySettings";
import GenderField from "../form/GenderField";
import ErrorMessage from "./ErrorMessage";
import messages from "./i18n";
import type { SignupFormShape } from "./types";

interface Props {
  widgetId: string;
}

const Signup: React.FC<Props> = ({ widgetId }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const {
    values,
    setValue,
    setFieldTouched,
    resetValues,
    errors,
    touched,
    isValid,
  } = useForm<SignupFormShape>({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      email: "",
      mobileNumber: "",
      password: "",
      confirmPassword: "",
      sendNewsletter: true,
      termsAndConditions: false,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      gender: yup.string(),
      dateOfBirth: yup.string().required("required"),
      email: yup.string().email("email").required("required"),
      mobileNumber: yup.string().required("required"),
      password: yup.string().required("required"),
      confirmPassword: yup
        .string()
        .required("required")
        .test({
          test: (value, context) => context.parent.password === value,
          message: "matchPassword",
        }),
      sendNewsletter: yup.boolean().required("required"),
      termsAndConditions: yup
        .boolean()
        .required("required")
        .oneOf([true], "mustBeChecked"),
    }),
  });

  const config = useLoyaltySettings();

  if (!config || config.type === "NONE") {
    return null;
  }

  const onSubmit = async () => {
    setLoading(true);
    setError(undefined);
    let createMember:
      | typeof createTieredMember
      | typeof createNonTieredMember
      | null = null;
    switch (config.type) {
      case "TIERED": {
        createMember = createTieredMember;
        break;
      }
      case "NON_TIERED": {
        createMember = createNonTieredMember;
        break;
      }
    }

    if (createMember) {
      createMember(config, {
        dateOfBirth: values.dateOfBirth,
        email: values.email,
        firstname: values.firstName,
        gender: values.gender,
        lastname: values.lastName,
        password: values.password,
        mobileNumber: values.mobileNumber,
        sendNewsletter: values.sendNewsletter ? "True" : "False",
      })
        .then((response) => {
          setLoading(false);
          if (response?.peachCode === 0) {
            resetValues();
            setSuccess(true);
          } else {
            setError(formatMessage(messages.genericError));
          }
        })
        .catch(() => {
          setLoading(false);
          setError(formatMessage(messages.genericError));
        });
    }
  };

  if (success) {
    return (
      <Fragment>
        <Heading level={2}>{formatMessage(messages.successTitle)}</Heading>
        <Themed.p>{formatMessage(messages.successMessage)}</Themed.p>
      </Fragment>
    );
  }

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (!error) {
          await onSubmit();
        }
      }}
    >
      <Grid gap={4}>
        <div>
          <Heading level={2}>{formatMessage(messages.aboutYou)}</Heading>
          <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-firstname`}>
                {`${formatMessage(messages.firstNameField)} *`}
              </Label>
              <Input
                id={`${widgetId}-loyalty-firstname`}
                name={"firstName"}
                type={"text"}
                value={values.firstName}
                onChange={(event) => {
                  setValue("firstName", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("firstName");
                }}
              />
              {touched.firstName && errors.firstName && (
                <ErrorMessage type={errors.firstName} />
              )}
            </div>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-lastname`}>
                {`${formatMessage(messages.lastNameField)} *`}
              </Label>
              <Input
                id={`${widgetId}-loyalty-lastname`}
                name={"lastName"}
                type={"text"}
                value={values.lastName}
                onChange={(event) => {
                  setValue("lastName", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("lastName");
                }}
              />
              {touched.lastName && errors.lastName && (
                <ErrorMessage type={errors.lastName} />
              )}
            </div>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-gender`}>
                {`${formatMessage(messages.genderField)}`}
              </Label>
              <GenderField
                id={`${widgetId}-loyalty-gender`}
                name="gender"
                value={values.gender || ""}
                onChange={(event) => {
                  setValue("gender", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("gender");
                }}
              />
            </div>
            <div>
              <Label
                htmlFor={`${widgetId}-loyalty-date-of-birth`}
              >{`${formatMessage(messages.dateOfBirthField)} *`}</Label>
              <Input
                id={`${widgetId}-loyalty-date-of-birth`}
                name={"dateOfBirth"}
                type={"date"}
                value={values.dateOfBirth}
                onChange={(event) => {
                  setValue("dateOfBirth", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("dateOfBirth");
                }}
              />
              {touched.dateOfBirth && errors.dateOfBirth && (
                <ErrorMessage type={errors.dateOfBirth} />
              )}
            </div>
          </Grid>
        </div>

        <div>
          <Heading level={2}>{formatMessage(messages.contactDetails)}</Heading>
          <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-email`}>
                {`${formatMessage(messages.emailField)} *`}
              </Label>
              <Input
                id={`${widgetId}-loyalty-email`}
                name={"email"}
                type={"text"}
                value={values.email}
                onChange={(event) => {
                  setValue("email", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("email");
                }}
              />
              {touched.email && errors.email && (
                <ErrorMessage type={errors.email} />
              )}
            </div>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-phone-number`}>
                {`${formatMessage(messages.mobileNumberField)} *`}
              </Label>
              <Input
                id={`${widgetId}-loyalty-phone-number`}
                name={"mobileNumber"}
                type={"text"}
                value={values.mobileNumber}
                onChange={(event) => {
                  setValue("mobileNumber", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("mobileNumber");
                }}
              />
              {touched.mobileNumber && errors.mobileNumber && (
                <ErrorMessage type={errors.mobileNumber} />
              )}
            </div>
          </Grid>
        </div>

        <div>
          <Heading level={2}>{formatMessage(messages.createPassword)}</Heading>
          <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-password`}>
                {`${formatMessage(messages.passwordField)} *`}
              </Label>
              <Input
                id={`${widgetId}-loyalty-password`}
                name={"password"}
                type={"password"}
                value={values.password}
                onChange={(event) => {
                  setValue("password", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("password");
                }}
              />
              {touched.password && errors.password && (
                <ErrorMessage type={errors.password} />
              )}
            </div>
            <div>
              <Label htmlFor={`${widgetId}-loyalty-confirm-password`}>
                {`${formatMessage(messages.confirmPasswordField)} *`}
              </Label>
              <Input
                id={`${widgetId}-loyalty-confirm-password`}
                name={"confirmPassword"}
                type={"password"}
                value={values.confirmPassword}
                onChange={(event) => {
                  setValue("confirmPassword", event.target.value);
                  setError(undefined);
                }}
                onBlur={() => {
                  setFieldTouched("confirmPassword");
                }}
              />
              {touched.confirmPassword && errors.confirmPassword && (
                <ErrorMessage type={errors.confirmPassword} />
              )}
            </div>
          </Grid>
        </div>
        <Label sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Checkbox
            id={`${widgetId}-send-newsletter`}
            name="send-newsletter"
            type="checkbox"
            checked={values.sendNewsletter}
            onChange={(event) => {
              setValue("sendNewsletter", event.target.checked);
            }}
          />
          {`${formatMessage(messages.sendNewsletterField)}`}
        </Label>
        <div>
          <Label sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Checkbox
              id={`${widgetId}-terms-and-conditions`}
              name="terms-and-conditions"
              type="checkbox"
              checked={values.termsAndConditions}
              onChange={(event) => {
                setValue("termsAndConditions", event.target.checked);
              }}
            />
            {`${formatMessage(messages.termsAndConditionsField)}`}
          </Label>
          {errors.termsAndConditions && (
            <ErrorMessage type={errors.termsAndConditions} />
          )}
        </div>
        <Flex sx={{ alignItems: "center", gap: 3 }}>
          <div>
            <Button
              variant={"buttons.secondary"}
              disabled={!isValid || loading}
              type={"submit"}
            >
              {formatMessage(messages.register)}
            </Button>
          </div>
          <div>
            {loading && (
              <div>
                <Spinner size={"2rem"} />
              </div>
            )}
            {error && <div sx={{ fontWeight: "bold" }}>{error}</div>}
          </div>
        </Flex>
      </Grid>
    </form>
  );
};

export default memo(Signup);
