/** @jsx jsx */
import { DateTime } from "luxon";
import React, { Fragment, memo } from "react";
import Truncate from "shared/components/Truncate";
import { FALLBACK_TIME_ZONE } from "shared/constants";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { Dig } from "shared/utils/ts";
import { jsx } from "theme-ui";

import type { TheaterSelectorQuery } from "../../../../__generated__/gatsby.types";
import messages from "../i18n";

export type Theater = Exclude<
  Dig<TheaterSelectorQuery, ["allTheater", "nodes", number]>,
  undefined
>;

type Props = {
  theater?: Theater;
  isTrigger?: boolean;
  prefix?: React.ReactNode;
  lines?: number;
};

const ItemLabel: React.FC<Props> = ({
  theater,
  isTrigger = false,
  prefix,
  lines = 0,
}) => {
  const { formatMessage } = useIntl();
  const {
    intl: {
      locale: { long: locale },
    },
  } = usePageContext();

  const theaterName =
    isTrigger && !theater?.name ? formatMessage(messages.all) : theater?.name;

  let closureText;

  if (theater?.practicalInfo?.closed) {
    closureText = formatMessage(messages.closed);
  } else if (theater?.practicalInfo?.temporaryClosure?.endsAt) {
    const timeZone = theater.timeZone || FALLBACK_TIME_ZONE;

    const reopenDate = DateTime.fromISO(
      theater.practicalInfo.temporaryClosure.endsAt,
      { zone: timeZone }
    ).plus({ days: 1 });

    closureText = formatMessage(messages.reopensOn, {
      date: reopenDate.setLocale(locale).toLocaleString({
        day: "numeric",
        month: "numeric",
      }),
    });
  }

  return (
    <Fragment>
      {prefix && <div sx={{ marginRight: ".5em" }}>{prefix}</div>}
      <div sx={{ flex: 1 }}>
        <Truncate lines={lines}>
          {theaterName}
          {!!closureText && (
            <span sx={{ fontSize: 1 }}>{` (${closureText})`}</span>
          )}
        </Truncate>
      </div>
    </Fragment>
  );
};

export default memo(ItemLabel);
