/** @jsx jsx */
import { CDNImage } from "@boxoffice/screenplay";
// @ts-expect-error no type defs for this library
import { hexToCSSFilter } from "hex-to-css-filter";
import startCase from "lodash/startCase";
import React, { memo } from "react";
import ThemeProvider from "shared/components/ThemeProvider";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { getPresentationWidth } from "shared/helpers/image";
import { Dig } from "shared/utils/ts";
import { jsx } from "theme-ui";

import { AllAttributesQuery } from "../../../__generated__/gatsby.types";
import sxStyles from "../styles";
import messages from "./i18n";

type Attribute = Dig<AllAttributesQuery, ["allAttribute", "nodes", number]>;

export type AttributeSize = "small" | "large";

type Props = {
  tag: string;
  attribute?: Attribute;
  wrapped?: boolean;
  size: AttributeSize;
};

const Attribute: React.FC<Props> = ({ tag, attribute, wrapped, size }) => {
  const { formatMessage } = useIntl();

  const attributeLabel =
    attribute?.description ||
    attribute?.label ||
    (messages[tag] ? formatMessage(messages[tag]) : "") ||
    startCase(tag.split(".").pop());

  let imageHeight = 40;

  switch (size) {
    case "small":
      imageHeight = 22;
      break;
    case "large":
      imageHeight = 40;
      break;
  }

  return (
    <ThemeProvider
      colors={(rawColors) => ({
        background: wrapped
          ? getTinyColor(rawColors?.background).shade(50).toHexString()
          : rawColors?.background,
      })}
    >
      <div sx={wrapped ? sxStyles.attributeWrapped : sxStyles.attribute}>
        {attribute?.logo ? (
          <CDNImage
            src={attribute.logo}
            alt={attributeLabel}
            height={imageHeight}
            width={getPresentationWidth(
              imageHeight,
              attribute.logoProbe?.ratio
            )}
            sx={{
              borderRadius: 0,
              filter: (theme) => {
                return `brightness(0) saturate(100%) ${
                  hexToCSSFilter(theme.rawColors?.text).filter
                }`;
              },
            }}
          />
        ) : (
          attributeLabel
        )}
      </div>
    </ThemeProvider>
  );
};

export default memo(Attribute);
