import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  yourTheater: {
    id: "header.location-selector.your-theater-label",
    defaultMessage: "Your theater:",
  },
});

export default messages;
