/** @jsx jsx */
import React, { Fragment, memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import { isoStringToDateTime, localizeDateTime } from "../../helpers";
import messages from "./i18n";
import sxStyles from "./styles";

export type Props = {
  type?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  timeZone?: string | null;
};

const MetaData: React.FC<Props> = ({ type, startAt, endAt, timeZone }) => {
  const {
    intl: {
      locale: { long: locale },
    },
  } = usePageContext();
  const { formatMessage } = useIntl();

  const startDateTime = startAt
    ? isoStringToDateTime(startAt, timeZone)
    : undefined;

  const endDateTime = endAt ? isoStringToDateTime(endAt, timeZone) : undefined;

  const Children = [];

  if (type) {
    Children.push(type);
  }

  if (startDateTime && endDateTime) {
    // Event starts and ends on the same day.
    if (startDateTime.hasSame(endDateTime, "day")) {
      Children.push(
        <span>
          {formatMessage(messages.sameDayPeriod, {
            date: localizeDateTime(startDateTime, locale, "DATE"),
            startTime: localizeDateTime(startDateTime, locale, "TIME"),
            endTime: localizeDateTime(endDateTime, locale, "TIME"),
          })}
        </span>
      );
    } else {
      // Start and end days are different.
      Children.push(
        <span>
          {formatMessage(messages.startAt, {
            date: localizeDateTime(startDateTime, locale),
          })}
        </span>,
        <span>
          {formatMessage(messages.endAt, {
            date: localizeDateTime(endDateTime, locale),
          })}
        </span>
      );
    }
  } else if (startDateTime) {
    // Only start date is available.
    Children.push(<span>{localizeDateTime(startDateTime, locale)}</span>);
  } else if (endDateTime) {
    // Only end date is available.
    Children.push(
      <span>
        {formatMessage(messages.endAt, {
          date: localizeDateTime(endDateTime, locale),
        })}
      </span>
    );
  }

  return (
    <div>
      {Children.map((child, index) => {
        return (
          <Fragment key={index}>
            {index !== 0 && <span sx={sxStyles.separator}>{" • "}</span>}
            {child}
          </Fragment>
        );
      })}
    </div>
  );
};

export default memo(MetaData);
