/** @jsx jsx */
import times from "lodash/times";
import React, { Fragment, memo, useState } from "react";
import { jsx, Spinner } from "theme-ui";

import Column, { ColumnProps } from "./Column";
import Pagination from "./Pagination";
import sxStyles from "./styles";

export { default as Column } from "./Column";
export type ColumnComponent = React.ReactElement<ColumnProps>;

type Props = {
  "aria-label"?: string;
  children: React.ReactNode;
  emptyStateMessage?: string;
  idResolver?: (rowIndex: number) => string | number | null | undefined;
  loading?: boolean;
  rowCount: number;
  pageSize?: number;
};

// @ts-expect-error insert-comment
const isColumn = (child: React.ReactNode): boolean => child?.type === Column;

const Table: React.FC<Props> = ({
  "aria-label": ariaLabel,
  children,
  emptyStateMessage,
  idResolver,
  loading,
  rowCount,
  pageSize = 0,
}) => {
  const childrenArray = React.Children.toArray(children);
  const columns = childrenArray.filter(isColumn) as ColumnComponent[];

  const [pageIndex, setPageIndex] = useState(0);

  const first = pageIndex * pageSize;
  const displayedCount = pageSize
    ? Math.min(pageSize, rowCount - first)
    : rowCount;

  return (
    <Fragment>
      <div sx={sxStyles.wrapper}>
        <table aria-label={ariaLabel} sx={{ ...sxStyles.table }}>
          <thead sx={{ opacity: loading ? 0.2 : 1 }}>
            <tr sx={sxStyles.row}>
              {columns.map((column) => (
                <td key={column.props.id} sx={sxStyles.header}>
                  {column.props.header}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={columns.length}>
                  <div sx={sxStyles.loadingWrapper}>
                    <Spinner size={"2rem"} />
                  </div>
                </td>
              </tr>
            )}
            {!loading && !rowCount && (
              <tr sx={sxStyles.row}>
                <td colSpan={columns.length}>
                  <div sx={sxStyles.emptyState}>{emptyStateMessage}</div>
                </td>
              </tr>
            )}
            {!loading &&
              !!displayedCount &&
              times(displayedCount, (i) => {
                const rowIndex = i + first;
                const id = idResolver
                  ? idResolver(rowIndex) || rowIndex
                  : rowIndex;
                return (
                  <tr key={id} sx={sxStyles.row}>
                    {columns.map((column) => {
                      return (
                        <td key={`${id}-${column.props.id}`} sx={sxStyles.cell}>
                          {column.props.children?.(rowIndex)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {!!pageSize && !loading && (
        <Pagination
          rowCount={rowCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          onChange={setPageIndex}
        />
      )}
    </Fragment>
  );
};

export default memo(Table);
