/** @jsx jsx */
import { FC, Fragment, memo } from "react";
import { jsx } from "theme-ui";

import { Maybe } from "../../utils/ts";

interface Props {
  readonly location?: Maybe<{
    readonly address?: Maybe<string>;
    readonly city?: Maybe<string>;
    readonly state?: Maybe<string>;
    readonly zip?: Maybe<string>;
  }>;
}

const TheaterAddress: FC<Props> = ({ location }) => {
  const zipState = [location?.state, location?.zip].filter(Boolean).join(" ");

  return (
    <Fragment>
      <div>{location?.address}</div>
      <div>{[location?.city, zipState].filter(Boolean).join(", ")}</div>
    </Fragment>
  );
};

export default memo(TheaterAddress);
