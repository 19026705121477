import React, { memo, useContext, useState } from "react";
import {
  FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
  FALLBACK_TIME_ZONE,
} from "shared/constants";
import { getRanges, Range, RangeKey } from "shared/helpers/ranges";

type Source = "initial" | "shortcut" | "slider" | "calendar";

type RangeSelection = {
  range: Range;
  source: Source;
};

type ScheduleContextFromHook = {
  ranges: Record<RangeKey, Range>;
  rangeSelection: RangeSelection;
  setRangeSelection: (rangeSelection: RangeSelection) => void;
};

const INITIAL_RANGES = getRanges(
  FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
  FALLBACK_TIME_ZONE
);

export const ScheduleContext = React.createContext<ScheduleContextFromHook>({
  ranges: INITIAL_RANGES,
  rangeSelection: { range: INITIAL_RANGES.TODAY, source: "initial" },
  setRangeSelection: () => undefined,
});

type Props = {
  showtimeWeekReleaseDay: number | null | undefined;
  timeZone: string | null | undefined;
  defaultRange: RangeKey | null | undefined;
};

export const ScheduleContextProvider: React.FC<Props> = memo(
  ({ children, showtimeWeekReleaseDay, timeZone, defaultRange }) => {
    const ranges = getRanges(showtimeWeekReleaseDay, timeZone);

    const [rangeSelection, setRangeSelection] = useState<RangeSelection>({
      range: defaultRange ? ranges[defaultRange] : ranges.TODAY,
      source: "initial",
    });

    return (
      <ScheduleContext.Provider
        value={{ ranges, rangeSelection, setRangeSelection }}
      >
        {children}
      </ScheduleContext.Provider>
    );
  }
);

const useScheduleContext = (): ScheduleContextFromHook => {
  return useContext(ScheduleContext);
};

export default useScheduleContext;
