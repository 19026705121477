import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages<string>({
  "Showtime.Accessibility.Subtitled+Localization.Subtitle.Spanish": {
    id: "shared.attribute.showtime-accessibility-subtitled+localization-subtitle-spanish",
    defaultMessage: "Spanish subtitled",
  },
  "Format.Sound.Dubbed+Localization.Language.Spanish": {
    id: "shared.attribute.format-sound-dubbed+localization-language-spanish",
    defaultMessage: "Spanish dubbed",
  },
  "Showtime.Accessibility.Subtitled+Localization.Subtitle.English": {
    id: "shared.attribute.showtime-accessibility-subtitled+localization-subtitle-english",
    defaultMessage: "English subtitled",
  },
  "Format.Sound.Dubbed+Localization.Language.English": {
    id: "shared.attribute.format-sound-dubbed+localization-language-english",
    defaultMessage: "English dubbed",
  },
  "Localization.Language.French": {
    id: "shared.attribute.localization-language-french",
    defaultMessage: "French",
  },
  DUBBED: {
    id: "schedule.movie.showtimes.showtime.audio-version.dubbed-label",
    defaultMessage: "Dubbed",
  },
  ORIGINAL: {
    id: "schedule.movie.showtimes.showtime.audio-version.original-label",
    defaultMessage: "OV",
  },
});

export default messages;
