/** @jsx jsx */
import { DOMAttributes, FC, memo, ReactElement } from "react";
import Link from "shared/components/Link";
import { getMostReadable } from "shared/helpers/colors";
import { jsx } from "theme-ui";

import { SocialNetwork } from "../types";
import Facebook from "./icons/Facebook";
import Instagram from "./icons/Instagram";
import Letterboxd from "./icons/Letterboxd";
import LinkedIn from "./icons/Linkedin";
import Pinterest from "./icons/Pinterest";
import Snapchat from "./icons/Snapchat";
import Tiktok from "./icons/Tiktok";
import TwitterIcon from "./icons/Twitter";
import VkIcon from "./icons/Vk";
import Wechat from "./icons/Wechat";
import YouTube from "./icons/Youtube";

export type Props = {
  type: SocialNetwork;
  url: string;
  onClick?: DOMAttributes<HTMLAnchorElement>["onClick"];
  title?: string;
};

const SocialLink: FC<Props> = ({ type, url, onClick, title }) => {
  const icons: Record<SocialNetwork, ReactElement> = {
    facebook: <Facebook />,
    instagram: <Instagram />,
    letterboxd: <Letterboxd />,
    linkedin: <LinkedIn />,
    pinterest: <Pinterest />,
    snapchat: <Snapchat />,
    tiktok: <Tiktok />,
    twitter: <TwitterIcon />,
    vk: <VkIcon />,
    wechat: <Wechat />,
    youtube: <YouTube />,
  };

  return (
    <Link
      to={url}
      onClick={onClick}
      title={title}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover, &:focus": {
          outline: "none",
          opacity: 0.8,
          color: (theme) =>
            getMostReadable(theme.rawColors?.background, [
              theme.rawColors?.primary,
            ]).toHexString(),
        },
      }}
    >
      {icons[type]}
    </Link>
  );
};

export default memo(SocialLink);
