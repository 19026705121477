import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  info: {
    id: "movies.carousel.movie.info-label",
    defaultMessage: "Movie info",
  },
  tickets: {
    id: "movies.carousel.movie.tickets-label",
    defaultMessage: "Tickets",
  },
  trailer: {
    id: "movies.carousel.movie.trailer-label",
    defaultMessage: "Trailer",
  },
});

export default messages;
