/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { useBreakpointIndex } from "@theme-ui/match-media";
import {
  MainMenuDropdown,
  MainMenuDropdownArrow,
  MainMenuItemComponentProps,
  mainMenuStyles,
  OpenSubMenuAltIcon,
  OpenSubMenuIcon,
} from "gatsby-theme-main-menu";
import React, { memo } from "react";
import Link from "shared/components/Link";
import { ANIMATION_DURATION } from "shared/constants";
import {
  getMostReadable,
  getMostVisible,
  UIColors,
} from "shared/helpers/colors";
import useThemeUI from "shared/hooks/useThemeUI";
import type { Property } from "shared/types/css";
import { jsx } from "theme-ui";

const MainMenuItemComponent: React.FC<MainMenuItemComponentProps> = ({
  attributes,
  hasChildren,
  isDropdownMode,
  item,
  setArrowElement,
  setPopperElement,
  setReferenceElement,
  showDropdown,
  slug,
  styles,
  onClick,
}) => {
  const { theme } = useThemeUI();
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 3 });

  const headerUIColor = getMostVisible(theme.rawColors?.headerBackground, [
    theme.rawColors?.primary,
  ]);

  const headerUIAltColor = getMostVisible(theme.rawColors?.muted, [
    theme.rawColors?.primary,
  ]);

  return (
    <React.Fragment>
      <div>
        <div ref={setReferenceElement}>
          <Link
            key={item?.label || ""}
            to={slug}
            partiallyActive={slug !== "/"}
            sx={{
              display: "inline-block",
              fontSize: 3,
              position: "relative",
              textDecoration: "none",
              textAlign: responsive<Property.TextAlign>({
                xs: "left",
                md: "center",
              }),
              lineHeight: 1.25,
              paddingY: 1,
              color: responsive({
                xs: getMostReadable(theme.rawColors?.muted).toHexString(),
                lg: getMostReadable(
                  theme.rawColors?.headerBackground
                ).toHexString(),
              }),
              "&:after": {
                content: '""',
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                borderBottomStyle: "solid",
                borderBottomWidth: "large",
                borderBottomColor: responsive({
                  xs: headerUIAltColor.toHexString(),
                  lg: headerUIColor.toHexString(),
                }),
                display: "block",
                transform: "scaleX(0)",
                transformOrigin: "center",
                transition: `transform ${ANIMATION_DURATION}`,
              },
              "&:hover, &:focus, &.active": {
                outline: "none",
                textDecoration: "none",
                "&:after": {
                  transform: "scaleX(1)",
                },
              },
            }}
            onClick={onClick}
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {item?.label}
              {hasChildren && (
                <div
                  sx={{
                    color: responsive({
                      xs: getMostReadable(theme.rawColors?.muted, [
                        UIColors.lightAlt.toHexString(),
                        UIColors.darkAlt.toHexString(),
                      ]).toHexString(),
                      lg: getMostReadable(theme.rawColors?.headerBackground, [
                        UIColors.lightAlt.toHexString(),
                        UIColors.darkAlt.toHexString(),
                      ]).toHexString(),
                    }),
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {breakpointIndex > 2 ? (
                    <OpenSubMenuIcon />
                  ) : (
                    <div
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: 4,
                        marginTop: ".125em",
                      }}
                    >
                      <OpenSubMenuAltIcon />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Link>
        </div>
      </div>
      {isDropdownMode && showDropdown && hasChildren && (
        <div
          ref={setPopperElement}
          style={isDropdownMode ? styles.popper : undefined}
          {...attributes.popper}
          onClick={(event) => {
            event.stopPropagation();
          }}
          sx={mainMenuStyles.dropdown}
        >
          <MainMenuDropdown item={item} />
          <MainMenuDropdownArrow
            setArrowElement={setArrowElement}
            styles={styles}
            attributes={attributes}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default memo(MainMenuItemComponent);
