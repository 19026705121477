/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const OpenSubMenuIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    sx={{
      width: "1em",
      height: "1em",
    }}
  >
    <path fill="currentColor" d="M7,10L12,15L17,10H7Z" />
  </svg>
);

export default memo(OpenSubMenuIcon);
