/** @jsx jsx */
import { DateTime } from "luxon";
import React, { Fragment, memo } from "react";
import { FormattedNumber } from "shared/components/FormattedNumber";
import useIntl, { FormattedMessage } from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx, Spinner } from "theme-ui";

import useLoyaltyMember from "../../hooks/useLoyaltyMember";
import messages from "./i18n";
import sxStyles from "./style";

const BALANCE_TYPE_LOYALTY = "1";

const WelcomeMessage: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    intl: {
      locale: { long: locale },
    },
  } = usePageContext();
  const [member, loading] = useLoyaltyMember();

  if (!member && !loading) return null;

  const balance = member?.balances?.find(
    (balance) => balance?.balanceTypeId === BALANCE_TYPE_LOYALTY
  );

  const amount = balance?.total || 0;

  const expiryDetails = member?.expiryDetails?.find(
    (expiryDetails) => expiryDetails?.balanceTypeId === BALANCE_TYPE_LOYALTY
  );

  return (
    <div>
      {loading ? (
        <Spinner size={"2rem"} />
      ) : (
        <Fragment>
          <div sx={sxStyles.greeting}>
            {formatMessage(messages.greeting, {
              name: member?.firstName,
            })}
          </div>
          {/* Loyalty points with no expiration, or 0 points */}
          {(!expiryDetails?.date || !amount) && !balance?.currencyCode && (
            <FormattedMessage
              {...messages.pointsBalance}
              values={{
                value: (chunks: string) => (
                  <span sx={sxStyles.value}>{chunks}</span>
                ),
                amount,
              }}
            />
          )}
          {/* Loyalty points with expiration */}
          {!!expiryDetails?.date && !!amount && (
            <FormattedMessage
              {...messages.pointsAndExpiration}
              values={{
                value: (chunks: string) => (
                  <span sx={sxStyles.value}>{chunks}</span>
                ),
                expiryDate: DateTime.fromISO(expiryDetails.date || "")
                  .setLocale(locale)
                  .toLocaleString(DateTime.DATE_SHORT),
                amount,
              }}
            />
          )}
          {/* Loyalty cash */}
          {!!balance?.currencyCode && (
            <FormattedMessage
              {...messages.cashBalance}
              values={{
                value: (chunks: string) => (
                  <span sx={sxStyles.value}>{chunks}</span>
                ),
                amount: (
                  <FormattedNumber
                    value={amount / 100}
                    style={"currency"}
                    currency={balance.currencyCode || ""}
                  />
                ),
              }}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default memo(WelcomeMessage);
