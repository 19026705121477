/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { keyframes } from "@emotion/react";
import { MainMenuWrapperProps, MenuIcon } from "gatsby-theme-main-menu";
import { FC, memo, useState } from "react";
import CloseIcon from "shared/components/icons/CloseIcon";
import { ANIMATION_DURATION } from "shared/constants";
import { getMostReadable } from "shared/helpers/colors";
import { Property } from "shared/types/css";
import { jsx } from "theme-ui";

const MainMenu: FC<MainMenuWrapperProps> = ({
  onVisibilityChange,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <label
        htmlFor="main-menu"
        sx={{
          fontSize: 4,
          lineHeight: "1em",
          display: responsive({
            xs: "block",
            lg: "none",
          }),
          color: isOpen ? "primary" : undefined,
        }}
      >
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </label>
      <input
        checked={!isOpen}
        type="checkbox"
        id="main-menu"
        sx={{
          display: "none",
          "&:checked ~ nav": {
            display: responsive({
              xs: "none",
              lg: "block",
            }),
          },
        }}
        onChange={() => {
          setIsOpen(!isOpen);
          if (onVisibilityChange) {
            onVisibilityChange(isOpen);
          }
        }}
      />
      <nav
        sx={{
          left: responsive({ xs: 2, lg: 0 }),
          right: responsive({ xs: 2, lg: 0 }),
          borderBottomLeftRadius: "medium",
          borderBottomRightRadius: "medium",
          color: (theme) =>
            getMostReadable(theme.rawColors?.muted).toHexString(),
          backgroundColor: (theme) =>
            responsive({
              xs: theme.rawColors?.muted,
              lg: "transparent",
            }),
          padding: responsive({
            xs: 4,
            lg: 0,
          }),
          position: responsive<Property.Position>({
            xs: "absolute",
            lg: "static",
          }),
          top: "100%",
          boxShadow: responsive({
            xs: "0 2rem 1rem 0 rgba(0,0,0,.15)",
            lg: "none",
          }),
          animationDuration: responsive<Property.AnimationDuration>({
            xs: ANIMATION_DURATION,
            lg: "0s",
          }),
          animationName: responsive({
            xs: keyframes({
              "0%": {
                opacity: 0,
              },
              "100%": {
                opacity: 1,
              },
            }).toString(),
            lg: "none",
          }),
          maxHeight: responsive({
            xs: "60vh",
            lg: "none",
          }),
          overflow: responsive({
            xs: "hidden",
            lg: "visible",
          }),
          overflowY: responsive<Property.OverflowY>({
            xs: "auto",
            lg: "visible",
          }),
        }}
      >
        {children}
      </nav>
    </div>
  );
};

export default memo(MainMenu);
