/** @jsx jsx */
import React, { memo } from "react";
import ParsedHTML from "shared/components/ParsedHTML";
import ThemeProvider from "shared/components/ThemeProvider";
import { jsx } from "theme-ui";

import Wrapper from "./Wrapper";

interface Props {
  title?: string;
  subtitle?: string;
  message?: string;
  withContainer?: boolean;
  messageTextAlign?: "center" | "left" | "right";
}

const WarningMessage: React.FC<Props> = ({
  title,
  subtitle,
  message,
  withContainer,
  messageTextAlign = "center",
}) => {
  return (
    <Wrapper withContainer={withContainer}>
      <ThemeProvider colors={{ background: "#a62c3b" }}>
        <div
          sx={{
            backgroundColor: "background",
            color: "text",
            borderRadius: "small",
            marginBottom: 4,
            padding: 2,
            textAlign: "center",
          }}
        >
          <div
            sx={{
              alignItems: "center",
              display: "flex",
              fontSize: "1.25em",
              justifyContent: "center",
            }}
          >
            {title}
          </div>
          {subtitle && <div>{subtitle}</div>}
          {message && (
            <div sx={{ textAlign: messageTextAlign }}>
              <ParsedHTML>{message}</ParsedHTML>
            </div>
          )}
        </div>
      </ThemeProvider>
    </Wrapper>
  );
};

export default memo(WarningMessage);
