import { defineMessages } from "../../helpers/i18n/defineMessages";

const messages = defineMessages({
  new: {
    id: "shared.movie-poster.ribbon.new-message",
    defaultMessage: "New",
  },
});

export default messages;
