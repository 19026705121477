/** @jsx jsx */
import type { Props } from "gatsby-theme-pages/src/components/Widget/Section/Wrapper";
import Container from "gatsby-theme-pages/src/components/Widget/Section/Wrapper/Component";
import { FC, memo } from "react";
import ThemeProvider from "shared/components/ThemeProvider";
import { getTinyColor } from "shared/helpers/colors";
import { ColorValue } from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

const SectionWidgetWrapper: FC<Props> = ({
  children,
  wrapperSize,
  containerSize,
  backgroundColor,
  border,
}) => {
  return (
    <ThemeProvider
      colors={(rawColors) => {
        let resolvedBackgroundColor: ColorValue = rawColors?.background;

        switch (backgroundColor) {
          case "highlight":
            resolvedBackgroundColor = rawColors?.highlight;
            break;
          case "muted":
            resolvedBackgroundColor = rawColors?.muted;
            break;
          case "transparent":
            resolvedBackgroundColor = rawColors?.background;
            break;
        }

        return {
          background: resolvedBackgroundColor,
        };
      }}
    >
      <Container
        wrapperSize={wrapperSize}
        containerSize={containerSize}
        sx={(theme) => {
          const bg = getTinyColor(theme.rawColors?.background);

          return {
            paddingX:
              border ||
              backgroundColor !== "transparent" ||
              containerSize === "FULL"
                ? // FIXME: type this
                  // @ts-expect-error no type for containerGutter
                  theme.sizes?.containerGutter
                : undefined,
            paddingY:
              border || backgroundColor !== "transparent"
                ? // @ts-expect-error no type for sectionVerticalGutter
                  (theme) => theme.sizes?.sectionVerticalGutter
                : undefined,
            backgroundColor: "background",
            color: "text",
            borderColor: border
              ? bg.isLight()
                ? bg.shade(20).toHexString()
                : bg.tint(20).toHexString()
              : undefined,
            borderWidth: border ? "1px" : undefined,
            borderStyle: border ? "solid" : undefined,
            borderLeft: wrapperSize === "FULL" ? "none" : undefined,
            borderRight: wrapperSize === "FULL" ? "none" : undefined,
            borderRadius: wrapperSize !== "FULL" ? "medium" : undefined,
          };
        }}
      >
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default memo(SectionWidgetWrapper);
