/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const TiktokIcon: React.FC<Record<string, unknown>> = () => (
  <svg viewBox="0 0 24 24" sx={{ width: "1em", height: "1em" }}>
    <path
      fill="currentColor"
      d="M20.69,8h4a4.94,4.94,0,0,1,.1.53,5.61,5.61,0,0,0,5.55,5.12v3.77a1.66,1.66,0,0,1-.58.08A20.28,20.28,0,0,1,27,17a18.55,18.55,0,0,1-2.28-1.06v.5c0,2,0,4.06,0,6.09A16.13,16.13,0,0,1,24.56,25a8.06,8.06,0,0,1-4.68,6.32,17.26,17.26,0,0,1-2.26.66H16.23a1.34,1.34,0,0,0-.18-.07,7.14,7.14,0,0,1-6-5.25,13.66,13.66,0,0,1-.38-1.82V23.67c.11-.55.19-1.1.35-1.64a7.59,7.59,0,0,1,6.62-5.3c.45-.07.9-.09,1.38-.13,0,1.3,0,2.5,0,3.71,0,.29-.15.33-.39.4a15.46,15.46,0,0,0-2.25.7,2.92,2.92,0,0,0-1.89,2.78,3.48,3.48,0,0,0,1.92,3.33,3.31,3.31,0,0,0,3.69-.14,3.5,3.5,0,0,0,1.44-2.45,21.6,21.6,0,0,0,.15-3q0-6.66,0-13.33C20.72,8.39,20.7,8.2,20.69,8Z"
    />
  </svg>
);

export default memo(TiktokIcon);
