/** @jsx jsx */
import { CDNImage, responsive, Stack } from "@boxoffice/screenplay";
import {
  allEventsItemMessages,
  AllEventsItemMetaData,
  AllEventsItemProps,
} from "gatsby-theme-event-pages";
import React, { memo } from "react";
import Link from "shared/components/Link";
import ThemeProvider from "shared/components/ThemeProvider";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { AspectRatio, Grid, jsx, Themed } from "theme-ui";

import sxStyles from "../styles";

const Event: React.FC<AllEventsItemProps> = ({ event }) => {
  const { formatMessage } = useIntl();

  return (
    <ThemeProvider
      colors={(rawColors) => ({
        background:
          rawColors?.background === rawColors?.muted
            ? getTinyColor(rawColors?.muted).tint(6).toHexString()
            : rawColors?.muted,
      })}
    >
      <div sx={sxStyles.itemWrapper}>
        <Grid columns={responsive({ xs: "1fr", md: "1fr 1fr" })} gap={0}>
          <div sx={{ position: "relative", zIndex: 1 }}>
            <AspectRatio ratio={16 / 9} sx={sxStyles.itemImageWrapper}>
              <CDNImage
                src={event.poster}
                alt={event.title}
                width={580}
                height={Math.ceil(580 / (16 / 9))}
                fit={"cover"}
                sx={sxStyles.itemImage}
              />
            </AspectRatio>
          </div>
          {/* this div is used to absorb the grid child stretch */}
          <div sx={sxStyles.itemContentWrapper}>
            <Stack space={4} justifyContent={"space-between"}>
              <div>
                <Stack space={4}>
                  <Stack space={0}>
                    <Themed.h2>{event.title}</Themed.h2>
                    <AllEventsItemMetaData
                      type={event.type}
                      timeZone={event.timeZone}
                      startAt={event.startAt}
                      endAt={event.endAt}
                    />
                  </Stack>
                  <div>{event.shortDescription}</div>
                </Stack>
              </div>
              <div sx={sxStyles.eventLinkWrapper}>
                <Link to={event.path || ""} sx={sxStyles.eventLink}>
                  {formatMessage(allEventsItemMessages.readMoreLink)}
                </Link>
              </div>
            </Stack>
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default memo(Event);
