import { DateTime } from "luxon";

export const getVisibilityStatus = (
  visibleStartAt: string | null | undefined,
  visibleEndAt: string | null | undefined,
  timeZone: string
): { isScheduled: boolean; isLive: boolean; isExpired: boolean } => {
  const now = DateTime.local().setZone(timeZone);
  const start = visibleStartAt
    ? DateTime.fromISO(visibleStartAt, { zone: timeZone })
    : now;
  const end = visibleEndAt
    ? DateTime.fromISO(visibleEndAt, { zone: timeZone })
    : now.plus({ milliseconds: 1 });

  // scheduled   N[---------[
  // live         [----N----[
  // expired      [---------[N
  const isScheduled = now < start && now < end;
  const isLive = now >= start && now < end;
  const isExpired = now >= start && now >= end;

  return { isScheduled, isLive, isExpired };
};
