/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { useLocation } from "@reach/router";
import { Link as GatsbyLink } from "gatsby";
import { parse, stringifyUrl } from "query-string";
import React, { memo } from "react";
import { jsx, Themed } from "theme-ui";

import { LOYALTY_COOKIE_NAME, useCookies } from "../../helpers/cookie";
import OutboundLink from "../OutboundLink";
import { WEBSITE_ACTIONS, WebsiteAction } from "./constants";

export const isExternalLink = (link: string): boolean => {
  try {
    return !!new URL(link);
  } catch (e) {
    return false;
  }
};

interface Props {
  alignIcon?: "start" | "end";
  icon?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  partiallyActive?: boolean;
  title?: string;
  to?: string;
}

const Link: React.FC<Props> = ({
  alignIcon = "start",
  children,
  className,
  icon,
  onClick,
  partiallyActive,
  title,
  to,
}) => {
  const { search } = useLocation();
  const [, , removeCookie] = useCookies([LOYALTY_COOKIE_NAME]);

  if (!to) {
    return <span className={className}>{children}</span>;
  }

  const enhancedTo = stringifyUrl({ url: to, query: parse(search) });

  if (isExternalLink(enhancedTo)) {
    return (
      <OutboundLink
        className={className}
        href={enhancedTo}
        onClick={onClick}
        title={title}
        icon={icon}
      >
        {children}
      </OutboundLink>
    );
  }

  const enhancedOnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const action = to as WebsiteAction;

    if (WEBSITE_ACTIONS.includes(action)) {
      switch (action) {
        case "#loyalty-logout": {
          event.preventDefault();
          event.stopPropagation();
          removeCookie(LOYALTY_COOKIE_NAME, { path: "/" });
        }
      }
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Themed.a
      // @ts-ignore
      activeClassName="active"
      as={GatsbyLink}
      className={className}
      onClick={enhancedOnClick}
      partiallyActive={partiallyActive}
      title={title}
      to={enhancedTo}
    >
      {icon && (
        <span
          sx={{
            display: "flex",
            flexDirection: alignIcon === "end" ? "row-reverse" : "row",
            alignItems: "center",
          }}
        >
          <span
            sx={{
              display: "flex",
              marginRight: alignIcon === "end" ? 0 : 2,
              marginLeft: alignIcon === "end" ? 2 : 0,
            }}
          >
            {icon}
          </span>
          <span>{children}</span>
        </span>
      )}
      {!icon && children}
    </Themed.a>
  );
};

export default memo(Link);
