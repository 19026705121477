import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { SchemaOf, ValidationError } from "yup";

export * as yup from "yup";

export type FormErrors<TValues> = {
  [K in keyof TValues]?: string;
};

export type FieldsTouched<TValues> = {
  [K in keyof TValues]?: boolean;
};

export interface FormState<TValues> {
  errors: FormErrors<TValues>;
  touched: FieldsTouched<TValues>;
  values: TValues;
}

export type SetValue<TValues> = <K extends keyof TValues, V extends TValues[K]>(
  key: K,
  value: V,
  touched?: boolean
) => void;

export type SetFieldTouched<TValues> = (fieldTouched: keyof TValues) => void;

export interface FormData<TValues> extends FormState<TValues> {
  isValid: boolean;
  isPristine: boolean;
  setFieldsTouched: (fieldsTouched: FieldsTouched<TValues>) => void;
  setFieldTouched: (fieldTouched: keyof TValues) => void;
  setValue: SetValue<TValues>;
  setValues: (values: TValues, touched?: boolean) => void;
  resetValues: () => void;
}

export interface FormOptions<TValues> {
  enableReinitialize?: boolean;
  initialValues: TValues;
  validationSchema: SchemaOf<TValues>;
  onValuesChange?: (
    nextValues: TValues,
    errors: FormErrors<TValues>,
    isValid: boolean
  ) => void;
}

function useForm<TValues extends Record<string, unknown>>({
  enableReinitialize = true,
  initialValues,
  validationSchema,
  onValuesChange = () => undefined,
}: FormOptions<TValues>): FormData<TValues> {
  const [memoInitialValues, setMemoInitialValues] =
    useState<TValues>(initialValues);
  const [values, setValues] = useState<TValues>(memoInitialValues);
  const [errors, setErrors] = useState<FormErrors<TValues>>({});
  const [touched, setFieldsTouched] = useState<FieldsTouched<TValues>>({});

  let mounted = true;

  // Validate the form values against the Yup schema.
  // Catch and return errors if any.
  const validateForm = (nextValues: TValues): Promise<FormErrors<TValues>> =>
    validationSchema
      .validate(nextValues, {
        abortEarly: false,
      })
      .then(() => {
        const noErrors = {};
        if (mounted) {
          setErrors(noErrors);
        }
        return noErrors;
      })
      .catch((catchedErrors: ValidationError) => {
        if (mounted) {
          setErrors(getWidgetErrors(catchedErrors));
        }
        return getWidgetErrors(catchedErrors);
      });

  // Validate form on values change
  useEffect(() => {
    validateForm(values).then((validationErrors) => {
      if (mounted && !isEqual(values, initialValues)) {
        onValuesChange(
          values,
          validationErrors,
          Object.keys(validationErrors).length === 0
        );
      }
    });
    return () => {
      mounted = false;
    };
  }, [values]);

  // Keep the initial values up to date
  useEffect(() => {
    if (enableReinitialize && !isEqual(memoInitialValues, initialValues)) {
      setMemoInitialValues(initialValues);
      setValues(initialValues);
    }
  }, [initialValues]);

  // Set multiple form fields values and touched
  const setValuesAndTouched = (nextValues: TValues, fieldsTouched = false) => {
    setValues((prevValues) => {
      const mergedValues = { ...prevValues, ...nextValues };

      return mergedValues;
    });

    if (fieldsTouched) {
      const nextTouched = Object.keys(values).reduce((acc, key) => {
        acc[key as keyof TValues] = true;
        return acc;
      }, {} as FieldsTouched<TValues>);

      setFieldsTouched((prevTouched) => ({ ...prevTouched, ...nextTouched }));
    }
  };

  // Set a single form field value and touched
  const setValueAndTouched: SetValue<TValues> = (
    key,
    value,
    fieldTouched = false
  ) => {
    setValues((prevValues) => {
      const mergedValues = { ...prevValues, [key]: value };
      return mergedValues;
    });

    if (fieldTouched) {
      setFieldsTouched((prevTouched) => {
        return { ...prevTouched, [key]: true };
      });
    }
  };

  // Set a single form field as touched
  const setFieldTouched: SetFieldTouched<TValues> = (field) => {
    setFieldsTouched((prevTouched) => {
      return { ...prevTouched, [field]: true };
    });
  };

  const resetValues = () => {
    setValues(memoInitialValues);
    setFieldsTouched({});
  };

  return {
    errors,
    isPristine: isEqual(values, initialValues),
    isValid: Object.keys(errors).length === 0,
    setFieldsTouched,
    setFieldTouched,
    setValue: setValueAndTouched,
    setValues: setValuesAndTouched,
    resetValues,
    touched,
    values,
  };
}

function getWidgetErrors<TValues>(catchedErrors: ValidationError) {
  const errors = (catchedErrors.inner || []).reduce((acc, error) => {
    const key = error.path as keyof TValues;
    try {
      acc[key] = JSON.parse(error.errors[0]);
    } catch {
      acc[key] = error.errors[0];
    }

    return acc;
  }, {} as FormErrors<TValues>);

  return errors;
}

export default useForm;
