import * as React from "react";
import useIntl from "shared/helpers/i18n/useIntl";

import { errorMessages } from "../i18n";

type Props = {
  type: string;
};

const ErrorMessage: React.FC<Props> = ({ type }) => {
  const { formatMessage } = useIntl();

  switch (type) {
    case "email":
      return <>{formatMessage(errorMessages.email)}</>;
    default:
      return <>{formatMessage(errorMessages.required)}</>;
  }
};

export default React.memo(ErrorMessage);
