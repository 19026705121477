import { defineMessages } from "shared/helpers/i18n/defineMessages";
import { RangeKey } from "shared/helpers/ranges";

const messages = defineMessages<
  Extract<RangeKey, "TODAY" | "TOMORROW" | "ALL_WEEK" | "NEXT_WEEK">
>({
  TODAY: {
    id: "schedule.periods.today-label",
    defaultMessage: "Today",
  },
  TOMORROW: {
    id: "schedule.periods.tomorrow-label",
    defaultMessage: "Tomorrow",
  },
  ALL_WEEK: {
    id: "schedule.periods.week-label",
    defaultMessage: "All week",
  },
  NEXT_WEEK: {
    id: "schedule.periods.next-week-label",
    defaultMessage: "Next week",
  },
});

export const otherMessages = defineMessages({
  anotherDate: {
    id: "schedule.periods.another-date-label",
    defaultMessage: "Another date",
  },
});

export default messages;
