/** @jsx jsx */

import { DateTime, Info } from "luxon";
import { FC, memo } from "react";
import { getMostReadable } from "shared/helpers/colors";
import useThemeUI from "shared/hooks/useThemeUI";
import { Grid, jsx } from "theme-ui";

import { getDaysInMonthMode } from "./helpers";

type Props = {
  cursorDate: DateTime;
  locale: string;
  maxDateTime?: DateTime;
  minDateTime?: DateTime;
  onChange: (dateTime: DateTime) => void;
  selectedDate: DateTime;
  setSelectedDate: (dateTime: DateTime) => void;
  shouldDisableDate?: (dateTime: DateTime) => boolean;
};

const CalendarComponent: FC<Props> = ({
  cursorDate,
  locale,
  maxDateTime,
  minDateTime,
  onChange,
  selectedDate,
  setSelectedDate,
  shouldDisableDate,
}) => {
  const { theme } = useThemeUI();

  return (
    <div>
      <Grid columns={"repeat(7, 1fr)"} gap={1}>
        {Info.weekdays("short", { locale }).map((weekDay, i) => (
          <div
            key={i}
            sx={{
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {weekDay}
          </div>
        ))}
      </Grid>
      <Grid columns={"repeat(7, 1fr)"} gap={1}>
        {getDaysInMonthMode(cursorDate).map((dateTime) => {
          const isOtherMonth = dateTime.month !== cursorDate.month;
          const isOutOfLowerBounds = minDateTime
            ? dateTime.startOf("day") < minDateTime.startOf("day")
            : false;
          const isOutOfUpperBounds = maxDateTime
            ? dateTime.startOf("day") > maxDateTime.startOf("day")
            : false;
          const isDisabled =
            isOtherMonth ||
            isOutOfLowerBounds ||
            isOutOfUpperBounds ||
            (shouldDisableDate ? shouldDisableDate(dateTime) : false);
          const isSelected =
            !isDisabled && dateTime.toISODate() === selectedDate.toISODate();

          const selectedBackgroundColor = getMostReadable(
            theme.rawColors?.background,
            [theme.rawColors?.accent]
          ).toHexString();

          return (
            <div
              key={dateTime.toISODate()}
              onClick={
                isDisabled
                  ? undefined
                  : () => {
                      setSelectedDate(dateTime);
                      onChange(dateTime);
                    }
              }
              sx={{
                backgroundColor: isSelected
                  ? selectedBackgroundColor
                  : "transparent",
                color: isSelected
                  ? getMostReadable(selectedBackgroundColor).toHexString()
                  : "currentcolor",
                borderRadius: "maximum",
                cursor: isOtherMonth || isDisabled ? undefined : "pointer",
                opacity: isDisabled ? 0.15 : 1,
                position: "relative",
                "&:hover": {
                  backgroundColor: !isDisabled
                    ? selectedBackgroundColor
                    : "transparent",
                  color: !isDisabled
                    ? getMostReadable(selectedBackgroundColor).toHexString()
                    : "currentcolor",
                },
              }}
            >
              <div sx={{ paddingTop: "100%" }} />
              <div
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  right: 0,
                  left: 0,
                  bottom: 0,
                  top: 0,
                }}
              >
                {isOtherMonth ? "" : dateTime.toFormat("d")}
              </div>
            </div>
          );
        })}
      </Grid>
    </div>
  );
};

export default memo(CalendarComponent);
