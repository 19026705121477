/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { DateTime } from "luxon";
import React, { memo, useEffect, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { Dig } from "shared/utils/ts";
import { jsx } from "theme-ui";

import type { WarningTheaterClosuresQuery } from "../../../__generated__/gatsby.types";
import WarningMessage from "../WarningMessage";
import messages from "./i18n";

interface Props {
  theaterId?: string;
  isSingleLocation?: boolean;
  withContainer?: boolean;
}

type Closure = Dig<
  WarningTheaterClosuresQuery,
  ["allTheater", "nodes", number, "practicalInfo"]
>;

const encodeClosure = (
  data: WarningTheaterClosuresQuery,
  isSingleLocation: boolean,
  theaterId?: string
): Closure | null => {
  const theater = isSingleLocation
    ? data?.allTheater?.nodes?.[0]
    : data?.allTheater?.nodes?.find(({ id }) => id === theaterId);
  const closure = theater?.practicalInfo;

  if (!closure?.closed && !closure?.temporaryClosure) {
    return null;
  }

  const today = DateTime.local()
    .setZone(theater?.timeZone || "UTC")
    .toFormat("yyyy-LL-dd");
  if (
    closure?.temporaryClosure?.startsAt &&
    today < closure.temporaryClosure.startsAt
  ) {
    return null;
  }
  if (
    closure?.temporaryClosure?.endsAt &&
    today > closure.temporaryClosure.endsAt
  ) {
    return null;
  }

  return closure;
};

const ClosureMessage: React.FC<Props> = ({
  theaterId,
  isSingleLocation = false,
  withContainer = false,
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const pageContext = usePageContext();
  const data = useStaticQuery<WarningTheaterClosuresQuery>(graphql`
    query warningTheaterClosures {
      allTheater {
        nodes {
          id
          timeZone
          practicalInfo {
            closed
            temporaryClosure {
              startsAt
              endsAt
              message
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    setMounted(true);
  }, []);

  const closure = encodeClosure(data, isSingleLocation, theaterId);

  if (!mounted || !closure) {
    return null;
  }

  const dateString =
    closure?.temporaryClosure?.startsAt && closure?.temporaryClosure?.endsAt
      ? formatMessage(messages.closedTime, {
          startsAt: DateTime.fromISO(closure.temporaryClosure.startsAt)
            .setLocale(pageContext.intl.locale.long)
            .toLocaleString(),
          endsAt: DateTime.fromISO(closure.temporaryClosure.endsAt)
            .setLocale(pageContext.intl.locale.long)
            .toLocaleString(),
        })
      : undefined;
  return (
    <WarningMessage
      title={
        closure?.temporaryClosure
          ? formatMessage(messages.closedTemp)
          : formatMessage(messages.closedPerm)
      }
      subtitle={dateString}
      message={closure?.temporaryClosure?.message || undefined}
      withContainer={withContainer}
    />
  );
};

export default memo(ClosureMessage);
