import { DocumentNode } from "graphql";
import { useQuery as useURQLQuery, UseQueryArgs, UseQueryResponse } from "urql";

export { gql } from "urql";

const useQuery = <TData, TVariables = Record<string, unknown>>(
  query: DocumentNode,
  options?: Omit<UseQueryArgs<TVariables, TData>, "query">
): UseQueryResponse<TData, TVariables> => {
  const queryResult = useURQLQuery<TData, TVariables>({
    query,
    ...options,
  });

  return queryResult;
};
export default useQuery;
