/** @jsx jsx */
import React, { memo, useEffect, useState } from "react";
import { jsx, Themed } from "theme-ui";

import useAnalytics from "../../hooks/useAnalytics";
import { getTarget } from "./helpers";

interface Props {
  children: React.ReactNode;
  className?: string;
  href: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  title?: string;
  icon?: React.ReactNode;
  alignIcon?: "start" | "end";
}

const OutboundLink: React.FC<Props> = ({
  children,
  className,
  href,
  onClick,
  title,
  icon,
  alignIcon = "start",
}) => {
  const [target, setTarget] = useState<string | undefined>(undefined);
  const analytics = useAnalytics();

  useEffect(() => {
    setTarget(getTarget(window.location.hostname, href));
  }, [href]);

  return (
    <Themed.a
      href={href}
      title={title}
      target={target}
      rel="noreferrer"
      className={className}
      onClick={(event) => {
        analytics.track("click", {
          category: "Navigation",
          label: `out: ${href}`,
        });
        if (onClick) {
          onClick(event);
        }
      }}
    >
      {icon && (
        <span
          sx={{
            display: "flex",
            flexDirection: alignIcon === "end" ? "row-reverse" : "row",
            alignItems: "center",
          }}
        >
          <span
            sx={{
              display: "flex",
              marginRight: alignIcon === "end" ? 0 : 2,
              marginLeft: alignIcon === "end" ? 2 : 0,
            }}
          >
            {icon}
          </span>
          <span>{children}</span>
        </span>
      )}
      {!icon && children}
    </Themed.a>
  );
};

export default memo(OutboundLink);
