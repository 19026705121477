import { Maybe } from "../../../__generated__/gatsby.types";

const SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG = "Showtime.Accessibility.Subtitled";
const LOCALIZATION_SUBTITLE_TAG = "Localization.Subtitle.";

const FORMAT_SOUND_DUBBED_TAG = "Format.Sound.Dubbed";
const LOCALIZATION_LANGUAGE_TAG = "Localization.Language.";

export const filterTags = (tags: Maybe<string>[]): Maybe<string>[] => {
  let filteredTags: Maybe<string>[] = [...tags];

  /**
   * Collecting the attributes indexes
   */
  const SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG_INDEX = filteredTags.findIndex(
    (tag) => tag?.match(SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG)
  );
  const LOCALIZATION_SUBTITLE_TAG_INDEX = filteredTags.findIndex((tag) =>
    tag?.match(LOCALIZATION_SUBTITLE_TAG)
  );
  const FORMAT_SOUND_DUBBED_TAG_INDEX = filteredTags.findIndex((tag) =>
    tag?.match(FORMAT_SOUND_DUBBED_TAG)
  );
  const LOCALIZATION_LANGUAGE_INDEX = filteredTags.findIndex((tag) =>
    tag?.match(LOCALIZATION_LANGUAGE_TAG)
  );

  /**
   * Creating synthetic combined attributes
   */
  // Combined subtitle attribute like:
  // Showtime.Accessibility.Subtitled+Localization.Subtitle.Spanish
  if (
    SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG_INDEX !== -1 &&
    LOCALIZATION_SUBTITLE_TAG_INDEX !== -1
  ) {
    filteredTags.push(
      `${filteredTags[SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG_INDEX]}+${filteredTags[LOCALIZATION_SUBTITLE_TAG_INDEX]}`
    );
  }

  // Combined dubbed attribute like:
  // Format.Sound.Dubbed+Localization.Language.Spanish
  if (
    FORMAT_SOUND_DUBBED_TAG_INDEX !== -1 &&
    LOCALIZATION_LANGUAGE_INDEX !== -1
  ) {
    filteredTags.push(
      `${filteredTags[FORMAT_SOUND_DUBBED_TAG_INDEX]}+${filteredTags[LOCALIZATION_LANGUAGE_INDEX]}`
    );
  }

  /**
   * Removing the tags that were used to create the synthetic combined attributes
   */
  filteredTags = filteredTags.filter((_, index) => {
    // Subtitle attributes
    if (
      SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG_INDEX !== -1 &&
      LOCALIZATION_SUBTITLE_TAG_INDEX !== -1 &&
      (index === SHOWTIME_ACCESSIBILITY_SUBTITLED_TAG_INDEX ||
        index === LOCALIZATION_SUBTITLE_TAG_INDEX)
    ) {
      return false;
    }

    // Dubbed attributes
    if (
      FORMAT_SOUND_DUBBED_TAG_INDEX !== -1 &&
      LOCALIZATION_LANGUAGE_INDEX !== -1 &&
      (index === FORMAT_SOUND_DUBBED_TAG_INDEX ||
        index === LOCALIZATION_LANGUAGE_INDEX)
    ) {
      return false;
    }

    return true;
  });

  return filteredTags;
};
