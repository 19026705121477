import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

import { LoyaltySettingsQuery } from "../../__generated__/gatsby.types";
import { LoyaltyConfig } from "../types";

const useLoyaltySettings = (): LoyaltyConfig => {
  const data = useStaticQuery<LoyaltySettingsQuery>(graphql`
    query LoyaltySettings {
      settings {
        loyalty {
          circuitId
          cinemaId
          platformVersion
          xAuthorization
          endpoint
          clubId
          type
          ticketingUrl
        }
      }
    }
  `);

  const loyaltySettings = data?.settings?.loyalty;

  const config = useMemo<LoyaltyConfig>(() => {
    return {
      circuitId: loyaltySettings?.circuitId || "",
      cinemaId: loyaltySettings?.cinemaId || "",
      platformVersion: loyaltySettings?.platformVersion || "",
      xAuthorization: loyaltySettings?.xAuthorization || "",
      endpoint: loyaltySettings?.endpoint || "",
      type: loyaltySettings?.type || "NONE",
      clubId: loyaltySettings?.clubId || "",
      ticketingUrl: loyaltySettings?.ticketingUrl || "",
    };
  }, [loyaltySettings]);

  return config;
};

export default useLoyaltySettings;
