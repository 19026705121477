/** @jsx jsx */
import { CDNImage } from "@boxoffice/screenplay";
import {
  eventSlideMessages,
  EventSlideProps,
} from "gatsby-theme-hero-carousel";
import React from "react";
import Link from "shared/components/Link";
import useIntl from "shared/helpers/i18n/useIntl";
import useThemeUI from "shared/hooks/useThemeUI";
import { AspectRatio, Flex, jsx } from "theme-ui";

import config from "../../config";
import sxStyles from "../../styles";

const EventSlide: React.FC<EventSlideProps> = ({ slide }) => {
  const { formatMessage } = useIntl();
  const { theme } = useThemeUI();

  const Image = (
    <AspectRatio
      ratio={config.imageRatio}
      sx={sxStyles.slideImageWrapperContent}
    >
      <CDNImage
        src={slide?.image?.src || slide?.movie?.poster}
        alt={slide?.image?.alt || ""}
        width={config.imageWidth}
        sx={sxStyles.slideImage}
      />
    </AspectRatio>
  );

  const Caption = (
    <Flex sx={sxStyles.slideContent}>
      {/* Caption text */}
      {(slide?.caption || slide?.subCaption) && (
        <div sx={sxStyles.slideCaptionWrapper}>
          <div sx={sxStyles.slideCaptionContent}>
            <div sx={sxStyles.slideCaptionBackdrop} />
            {slide.subCaption && (
              <div sx={sxStyles.slideCaptionSubCaption}>{slide.subCaption}</div>
            )}
            {slide.caption && (
              <div sx={sxStyles.slideCaptionText}>{slide.caption}</div>
            )}
          </div>
        </div>
      )}
      {/* Caption buttons */}
      <div
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Link
          sx={{
            variant: "buttons.secondary",
            borderRadius: "medium",
            backgroundColor: theme.rawColors?.background,
          }}
          to={slide?.event?.path || "/"}
        >
          {formatMessage(eventSlideMessages.info)}
        </Link>
      </div>
    </Flex>
  );

  return (
    <React.Fragment>
      <div sx={sxStyles.slideWrapper}>
        {Image}
        {Caption}
      </div>
    </React.Fragment>
  );
};

export default React.memo(EventSlide);
