import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  credits: {
    id: "footer.credits-message",
    defaultMessage: "© {year} The Boxoffice Company",
  },
});

export default messages;
