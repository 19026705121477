import { responsive } from "@boxoffice/screenplay";
import { Property } from "shared/types/css";
import { ThemeUIStyleObject } from "theme-ui";

const slideWrapper: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: responsive<Property.FlexDirection>({
    xs: "column-reverse",
    md: "row",
  }),
  margin: "0 auto",
  maxWidth: (theme) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore not typed yet
    theme.layout?.container?.maxWidth || "100%",
  paddingX: 4,
};

const slideContent: ThemeUIStyleObject = {
  marginRight: responsive({ xs: 0, md: 4 }),
  paddingTop: responsive({ xs: 0, lg: 5 }),
  width: responsive({ xs: "auto", md: "250px" }),
};

const slideLink: ThemeUIStyleObject = { variant: "buttons.secondary" };

const slideImageWrapper: ThemeUIStyleObject = {
  flex: 1,
  marginBottom: responsive({ xs: 4, md: 0 }),
};

const slideImageWrapperContent: ThemeUIStyleObject = {
  backgroundColor: "rgba(0,0,0,.25)",
  overflow: "hidden",
  borderRadius: "medium",
};

const slideImage: ThemeUIStyleObject = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
};

const slideImageWithPoster: ThemeUIStyleObject = {
  ...slideImage,
  objectFit: "contain",
  position: "relative",
};

const slidePosterWrapper: ThemeUIStyleObject = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

const slidePoster: ThemeUIStyleObject = {
  width: "100%",
  height: "100%",
  objectFit: "cover",
  opacity: 0.15,
};

const sxStyles: Record<
  | "wrapper"
  | "slideWrapper"
  | "slideContent"
  | "slideLink"
  | "slideImageWrapper"
  | "slideImageWrapperContent"
  | "slidePosterWrapper"
  | "slidePoster"
  | "slideImage"
  | "slideImageWithPoster",
  ThemeUIStyleObject
> = {
  wrapper: {
    variant: "boxes.hero",
    py: responsive({ xs: 4, md: 5 }),
  },
  slideWrapper,
  slideContent,
  slideLink,
  slideImageWrapper,
  slideImageWrapperContent,
  slidePosterWrapper,
  slidePoster,
  slideImage,
  slideImageWithPoster,
};

export default sxStyles;
