/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import type { DateSliderItemProps } from "gatsby-theme-schedule-options-bar";
import React, { memo } from "react";
import { getMostReadable, getMostVisible } from "shared/helpers/colors";
import usePageContext from "shared/hooks/usePageContext";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

const DateSliderItem: React.FC<DateSliderItemProps> = ({
  dateTime,
  isSelected,
}) => {
  const pageContext = usePageContext();
  const { theme } = useThemeUI();

  const backgroundColor = isSelected
    ? getMostVisible(theme.rawColors?.background, [
        theme.rawColors?.primary,
      ]).toHexString()
    : theme.rawColors?.background;

  return (
    <div
      sx={{
        variant: "buttons.secondary",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: backgroundColor,
        color: getMostReadable(backgroundColor).toHexString(),
        borderColor: getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
        cursor: "pointer",
        marginLeft: responsive({ xs: 2 }),
        marginRight: responsive({ xs: 2 }),
        padding: responsive({ xs: 1 }),
        minHeight: "60px",
        borderRadius: "medium",
        "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
          color: getMostReadable(backgroundColor).toHexString(),
          borderColor: getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.accent,
          ]).toHexString(),
        },
      }}
    >
      <div>
        <div
          sx={{
            fontSize: 2,
            lineHeight: 1,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {dateTime.setLocale(pageContext.intl.locale.long).toLocaleString({
            weekday: "short",
          })}
        </div>
        <div sx={{ fontSize: "22px", lineHeight: 1 }}>
          {dateTime.setLocale(pageContext.intl.locale.long).toLocaleString({
            day: "numeric",
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(DateSliderItem);
