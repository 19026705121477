import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  emptyState: {
    id: "events.empty-state-single-location-message",
    defaultMessage: "There are no events scheduled",
  },
  emptyStateMulti: {
    id: "events.empty-state-message",
    defaultMessage: "There are no events at the selected location",
  },
});

export default messages;
