/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import type { PeriodButtonProps } from "gatsby-theme-schedule-options-bar";
import { FC, memo } from "react";
import { getMostReadable, getMostVisible } from "shared/helpers/colors";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

const PeriodButton: FC<PeriodButtonProps> = ({
  onClick,
  option,
  disabled,
  active,
}) => {
  const { theme } = useThemeUI();

  const backgroundColor = active
    ? getMostVisible(theme.rawColors?.background, [
        theme.rawColors?.primary,
      ]).toHexString()
    : theme.rawColors?.background;

  return (
    <div
      role={"button"}
      sx={{
        variant: "buttons.secondary",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        margin: responsive({ xs: 0, xl: 0 }),
        opacity: disabled ? 0.15 : undefined,
        backgroundColor: backgroundColor,
        color: getMostReadable(backgroundColor).toHexString(),
        borderColor: getMostVisible(backgroundColor, [
          theme.rawColors?.primary,
        ]).toHexString(),
        minHeight: "60px",
        borderRadius: "medium",
        marginLeft: responsive({ sm: 2 }),
        marginTop: responsive({ xs: 2, sm: 0 }),
        "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
          color: getMostReadable(backgroundColor).toHexString(),
          borderColor: getMostVisible(backgroundColor, [
            theme.rawColors?.accent,
          ]).toHexString(),
        },
      }}
      className={active ? "active" : undefined}
      onClick={onClick}
    >
      {option.label}
    </div>
  );
};
export default memo(PeriodButton);
