import React, { memo } from "react";

import { ColumnSize } from "../types";

export interface ColumnProps {
  children?: (rowIndex: number) => React.ReactNode;
  header?: React.ReactNode;
  id: string;
  size?: ColumnSize;
}

const Column: React.FC<ColumnProps> = () => null;

export default memo(Column);
