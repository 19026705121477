import { ThemeUIStyleObject } from "theme-ui";

const attributeSxStyles: ThemeUIStyleObject = {
  margin: 2,
  fontWeight: "bold",
  fontSize: "1rem",
  lineHeight: "1em",
  display: "flex",
  alignItems: "center",
};

const sxStyles: Record<
  "attributes" | "attribute" | "attributeWrapped",
  ThemeUIStyleObject
> = {
  attributes: {
    display: "flex",
    flexWrap: "wrap",
    margin: (theme) => `calc(${theme.space?.[2] || 0} * -1)`,
  },
  attribute: {
    ...attributeSxStyles,
  },
  attributeWrapped: {
    ...attributeSxStyles,
    borderRadius: "medium",
    padding: 3,
    backgroundColor: "background",
    color: "text",
  },
};

export default sxStyles;
