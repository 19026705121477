/** @jsx jsx */
import { SocialLink, SocialLinksProps } from "gatsby-theme-social-links";
import React, { memo } from "react";
import useAnalytics from "shared/hooks/useAnalytics";
import { Flex, jsx } from "theme-ui";

const SocialLinks: React.FC<SocialLinksProps> = ({ links }) => {
  const analytics = useAnalytics();
  const Icons: React.ReactNode[] = [];

  if (links?.facebook) {
    Icons.push(
      <SocialLink
        key={"facebook"}
        type={"facebook"}
        title={"facebook"}
        url={links.facebook}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.facebook}`,
          })
        }
      />
    );
  }

  if (links?.instagram) {
    Icons.push(
      <SocialLink
        key={"instagram"}
        type={"instagram"}
        title={"instagram"}
        url={links.instagram}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.instagram}`,
          })
        }
      />
    );
  }

  if (links?.linkedin) {
    Icons.push(
      <SocialLink
        key={"linkedin"}
        type={"linkedin"}
        title={"linkedin"}
        url={links.linkedin}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.linkedin}`,
          })
        }
      />
    );
  }

  if (links?.pinterest) {
    Icons.push(
      <SocialLink
        key={"pinterest"}
        type={"pinterest"}
        title={"pinterest"}
        url={links.pinterest}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.pinterest}`,
          })
        }
      />
    );
  }

  if (links?.snapchat) {
    Icons.push(
      <SocialLink
        key={"snapchat"}
        type={"snapchat"}
        title={"snapchat"}
        url={links.snapchat}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.snapchat}`,
          })
        }
      />
    );
  }

  if (links?.twitter) {
    Icons.push(
      <SocialLink
        key={"twitter"}
        type={"twitter"}
        title={"twitter"}
        url={links.twitter}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.twitter}`,
          })
        }
      />
    );
  }

  if (links?.vk) {
    Icons.push(
      <SocialLink
        key={"vk"}
        type={"vk"}
        url={links.vk}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.vk}`,
          })
        }
      />
    );
  }

  if (links?.wechat) {
    Icons.push(
      <SocialLink
        key={"wechat"}
        type={"wechat"}
        title={"wechat"}
        url={links.wechat}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.wechat}`,
          })
        }
      />
    );
  }

  if (links?.youtube) {
    Icons.push(
      <SocialLink
        key={"youtube"}
        type={"youtube"}
        title={"youtube"}
        url={links.youtube}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.youtube}`,
          })
        }
      />
    );
  }

  if (links?.letterboxd) {
    Icons.push(
      <SocialLink
        key={"letterboxd"}
        type={"letterboxd"}
        title={"letterboxd"}
        url={links.letterboxd}
        onClick={() =>
          analytics.track("click", {
            category: "Navigation",
            label: `out: ${links.letterboxd}`,
          })
        }
      />
    );
  }

  return <Flex sx={{ gap: 3 }}>{Icons}</Flex>;
};

export default memo(SocialLinks);
