/** @jsx jsx */
import type { Property } from "csstype";
import { FC, memo, ReactNode } from "react";
import { jsx, StylePropertyValue, Theme, useThemeUI } from "theme-ui";

const resolveSpaceValue = (
  value: StylePropertyValue<Property.MarginLeft<string | number> | undefined>,
  spaces: Theme["space"]
): string | number | undefined => {
  switch (typeof value) {
    case "number":
      return spaces?.[value] || "0px";
    case "string":
      return value;
  }

  return undefined;
};

export const negateSpace = (
  space: StylePropertyValue<Property.MarginLeft<string | number> | undefined>,
  spaces: Theme["space"]
): string | undefined | (string | undefined)[] => {
  return Array.isArray(space)
    ? space.map((value) => {
        const resolvedValue: string | number | undefined = resolveSpaceValue(
          value,
          spaces
        );
        return resolvedValue ? `calc(${resolvedValue} * -1)` : undefined;
      })
    : typeof space === "string" || typeof space === "number"
    ? `calc(${resolveSpaceValue(space, spaces)} * -1)`
    : undefined;
};

interface Props {
  children: ReactNode;
  top?: StylePropertyValue<Property.MarginLeft<string | number> | undefined>;
  bottom?: StylePropertyValue<Property.MarginLeft<string | number> | undefined>;
  left?: StylePropertyValue<Property.MarginLeft<string | number> | undefined>;
  right?: StylePropertyValue<Property.MarginLeft<string | number> | undefined>;
  horizontal?: StylePropertyValue<
    Property.MarginLeft<string | number> | undefined
  >;
  vertical?: StylePropertyValue<
    Property.MarginLeft<string | number> | undefined
  >;
}

/**
 * @description Renders a container with negative margins, allowing content
 * to "bleed" (see https://en.wikipedia.org/wiki/Bleed_(printing)) into the
 * surrounding layout. This is designed to make it easy to visually break
 * out of a parent container without having to refactor the entire component tree.
 */
const Bleed: FC<Props> = ({
  children,
  top,
  bottom,
  left,
  right,
  horizontal,
  vertical,
}) => {
  const { theme } = useThemeUI();

  return (
    <div
      sx={
        theme.space
          ? {
              marginBottom: negateSpace(bottom, theme.space),
              marginLeft: negateSpace(left, theme.space),
              marginRight: negateSpace(right, theme.space),
              marginTop: negateSpace(top, theme.space),
              marginX: negateSpace(horizontal, theme.space),
              marginY: negateSpace(vertical, theme.space),
            }
          : undefined
      }
    >
      {children}
    </div>
  );
};

export default memo(Bleed);
