import { responsive } from "@boxoffice/screenplay";
import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<
  "wrapperContent" | "item" | "itemTitle" | "itemImageWrapper",
  ThemeUIStyleObject
> = {
  wrapperContent: {
    display: responsive({ xs: "block", sm: "block", md: "flex" }),
    alignItems: "flex-start",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },
  item: {
    flex: 1,
    maxWidth: responsive({ xs: "100%", md: "50%" }),
    marginLeft: responsive({ xs: 0, md: 4 }),
    marginTop: responsive({ xs: 4, md: 0 }),
    "&:first-of-type": {
      marginLeft: 0,
      marginTop: 0,
    },
  },
  itemTitle: { fontSize: "20px" },
  itemImageWrapper: {
    borderTopLeftRadius: "medium",
    borderTopRightRadius: "medium",
    overflow: "hidden",
  },
};

export default sxStyles;
