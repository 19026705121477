import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<"selectMenu", ThemeUIStyleObject> = {
  selectMenu: {
    backgroundColor: "white",
    borderWidth: "2px",
    borderColor: "primary",
    color: "black",
    "& + svg": {
      fill: "black",
    },
    ":invalid": {
      color: "rgba(0,0,0,.5)",
    },
  },
};

export default sxStyles;
