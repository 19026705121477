/** @jsx jsx */
import { Heading, Stack } from "@boxoffice/screenplay";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import EventMetaData from "../EventMetaData";
import sxStyles from "./styles";

export type Props = {
  endAt?: string | null;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  startAt?: string | null;
  timeZone?: string | null;
  title: string;
  type: string;
};

const EventTitle: React.FC<Props> = ({
  endAt,
  level = 1,
  startAt,
  timeZone,
  title,
  type,
}) => {
  return (
    <Heading level={level}>
      <Stack>
        {type && <div sx={sxStyles.eventType}>{type}</div>}
        <div sx={sxStyles.eventTitle}>{title}</div>
        <div sx={sxStyles.eventMetaData}>
          <EventMetaData startAt={startAt} endAt={endAt} timeZone={timeZone} />
        </div>
      </Stack>
    </Heading>
  );
};

export default memo(EventTitle);
