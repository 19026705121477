import { responsive } from "@boxoffice/screenplay";

type Config = {
  imageRatio: number;
  imageWidth: (number | undefined)[];
};

const config: Config = {
  imageRatio: 16 / 9,
  imageWidth: responsive({
    xs: 576,
    sm: 576,
    md: 422,
    lg: 678,
    xl: 934,
    xxl: 1190,
  }),
};

export default config;
