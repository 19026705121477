/** @jsx jsx */
import React, { memo } from "react";
import { Option } from "shared/types/option";
import { jsx } from "theme-ui";

import type { MovieCollection } from "../../types";
import OptionComponent from "./Component";
import Wrapper from "./Wrapper";

export interface Props {
  view: MovieCollection;
  options: Option<MovieCollection>[];
  onClick: (value: MovieCollection) => void;
}

const Options: React.FC<Props> = ({ options, onClick, view }) => (
  <Wrapper>
    {options.map((option) => {
      return (
        <OptionComponent
          key={option.value}
          option={option}
          active={view === option.value}
          onClick={(option) => {
            onClick(option.value);
          }}
        >
          <span className={"label"}>{option.label}</span>
        </OptionComponent>
      );
    })}
  </Wrapper>
);

export default memo(Options);
