/** @jsx jsx */
import { CDNImage, responsive } from "@boxoffice/screenplay";
import { headerMessages, HeaderProps } from "gatsby-theme-header";
import { MainMenu } from "gatsby-theme-main-menu";
import { SocialLinks } from "gatsby-theme-social-links";
import { TheaterSelector } from "gatsby-theme-theater-selector";
import React, { Fragment } from "react";
import { Icon, mdiMapMarker } from "shared/components/Icon";
import Link from "shared/components/Link";
import ThemeProvider from "shared/components/ThemeProvider";
import { getMostVisible, getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { getPresentationWidth } from "shared/helpers/image";
import useAnalytics from "shared/hooks/useAnalytics";
import usePageContext from "shared/hooks/usePageContext";
import { Button, Flex, jsx } from "theme-ui";

const HeaderComponent: React.FC<HeaderProps> = ({
  logo,
  logoProbe,
  socialLinks,
  websiteName,
}) => {
  const analytics = useAnalytics();
  const { isSingleLocation } = usePageContext();
  const { formatMessage } = useIntl();

  const hasSocialLinks = Object.values(socialLinks || {}).some(Boolean);
  const displaySubHeader = !isSingleLocation || hasSocialLinks;

  const SUB_HEADER_HEIGHT = 36;
  const HEADER_HEIGHT = 90;
  const LOGO_HEIGHT = 60;

  return (
    <Fragment>
      {/**
       * Sub header
       */}
      {displaySubHeader && (
        <ThemeProvider
          colors={(rawColors) => {
            const bg = getTinyColor(rawColors?.headerBackground);
            return {
              background: bg.isLight()
                ? bg.tint(25).toHexString()
                : bg.shade(25).toHexString(),
            };
          }}
          useAltFallback
        >
          <div
            sx={{
              backgroundColor: "background",
              color: "text",
              minHeight: SUB_HEADER_HEIGHT,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: responsive({ xs: 3, lg: 4 }),
            }}
          >
            {/**
             * Socials
             */}
            <div
              sx={{
                fontSize: "18px",
              }}
            >
              <div
                sx={{
                  display: responsive({
                    xs: isSingleLocation ? "block" : "none",
                    lg: "block",
                  }),
                }}
              >
                <SocialLinks links={socialLinks} size={"small"} />
              </div>
            </div>

            {/**
             * Theater selector
             */}
            <div>
              {!isSingleLocation && (
                <TheaterSelector
                  triggerPrefix={
                    <Flex
                      sx={{
                        alignItems: "center",
                        display: responsive({ xs: "none", sm: "flex" }),
                      }}
                    >
                      <Icon
                        path={mdiMapMarker}
                        size={0.5}
                        sx={{ marginRight: 2, display: "block" }}
                      />
                      <div sx={{ fontWeight: "normal" }}>
                        {formatMessage(headerMessages.yourTheater)}
                      </div>
                    </Flex>
                  }
                  renderTrigger={({ children, onClick }) => {
                    return (
                      <Button
                        variant={"secondary"}
                        onClick={onClick}
                        sx={{
                          lineHeight: 1.4,
                          borderRadius: "small",
                          fontSize: 0,
                          paddingY: 1,
                          paddingX: 2,
                          minHeight: 0,
                          borderColor: "transparent",
                          "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &":
                            {
                              borderColor: (theme) =>
                                getMostVisible(theme.rawColors?.background, [
                                  theme.rawColors?.primary,
                                  theme.rawColors?.accent,
                                ]).toHexString(),
                            },
                        }}
                      >
                        {children}
                      </Button>
                    );
                  }}
                  dropdownSxStyle={{
                    fontSize: 2,
                    left: 0,
                    right: 0,
                    width: "auto",
                  }}
                />
              )}
            </div>
          </div>
        </ThemeProvider>
      )}

      {/**
       * Header
       */}
      <div sx={{ position: "sticky", top: 0, zIndex: "frame" }}>
        <ThemeProvider
          colors={(rawColors) => ({
            background: rawColors?.headerBackground,
          })}
        >
          <header
            sx={{
              backgroundColor: "background",
              color: "text",
            }}
          >
            <Flex
              sx={{
                alignItems: "center",
                paddingX: responsive({ xs: 3, lg: 4 }),
                height: HEADER_HEIGHT,
                justifyContent: "space-between",
                gap: 4,
              }}
            >
              {/**
               * Logo
               */}
              <div
                sx={{
                  maxWidth: "100%",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                {logo && (
                  <Link
                    to={"/"}
                    sx={{
                      display: "block",
                    }}
                    onClick={() => {
                      analytics.track("click", {
                        category: "Navigation",
                        label: "logo",
                      });
                    }}
                  >
                    <CDNImage
                      src={logo}
                      alt={websiteName || ""}
                      fit={"crop"}
                      width={getPresentationWidth(
                        LOGO_HEIGHT,
                        logoProbe?.ratio
                      )}
                      height={LOGO_HEIGHT}
                      sx={{ borderRadius: 0 }}
                    />
                  </Link>
                )}
              </div>
              {/**
               * Menu
               */}
              <div sx={{ height: "100%" }}>
                <div
                  sx={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <MainMenu />
                </div>
              </div>
            </Flex>
          </header>
        </ThemeProvider>
      </div>
    </Fragment>
  );
};

export default React.memo(HeaderComponent);
