/** @jsx jsx */
import { DateTime } from "luxon";
import React, { memo, useEffect, useState } from "react";
import { FormattedNumber } from "shared/components/FormattedNumber";
import Table, { Column } from "shared/components/Table";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import { getOrderHistory } from "../../helpers";
import useLoyaltyMember from "../../hooks/useLoyaltyMember";
import useLoyaltySettings from "../../hooks/useLoyaltySettings";
import { Orders } from "../../types";
import messages from "./i18n";

const OrderHistory: React.FC = () => {
  const {
    isSingleLocation,
    intl: {
      locale: { long: locale },
    },
  } = usePageContext();
  const [member, memberLoading] = useLoyaltyMember();
  const { formatMessage } = useIntl();
  const [orderHistoryLoading, setOrderHistoryLoading] = useState(false);
  const [orderHistory, setOrderHistory] = useState<Orders>();
  const config = useLoyaltySettings();

  const loading = memberLoading || orderHistoryLoading;

  useEffect(() => {
    // Fetch order history.
    if (member?.userSessionId && config) {
      setOrderHistoryLoading(true);
      getOrderHistory(config, member?.userSessionId)
        .then((orders) => {
          setOrderHistory(orders);
        })
        .finally(() => {
          setOrderHistoryLoading(false);
        });
    }
  }, [member, config]);

  // Sort by date, most recent first.
  const orders = orderHistory?.orders?.sort(
    (a, b) =>
      new Date(b?.sessionDate || "").getTime() -
      new Date(a?.sessionDate || "").getTime()
  );

  if (!orders && !loading) {
    return null;
  }

  return (
    <Table
      aria-label={"order history"}
      emptyStateMessage={formatMessage(messages.emptyState)}
      loading={loading}
      rowCount={orders?.length || 0}
      pageSize={8}
    >
      <Column id={"date"} header={formatMessage(messages.date)}>
        {(rowIndex) =>
          DateTime.fromISO(orders?.[rowIndex]?.sessionDate || "")
            .setLocale(locale)
            .toLocaleString(DateTime.DATETIME_SHORT)
        }
      </Column>
      <Column id={"details"} header={formatMessage(messages.details)}>
        {(rowIndex) => orders?.[rowIndex]?.bookedFilmData?.title}
      </Column>
      {!isSingleLocation && (
        <Column id={"location"} header={formatMessage(messages.location)}>
          {(rowIndex) => orders?.[rowIndex]?.cinemaName}
        </Column>
      )}
      <Column id={"value"} header={formatMessage(messages.value)}>
        {(rowIndex) => {
          const order = orders?.[rowIndex];
          return typeof order?.grandTotal === "number" && order.currencyCode ? (
            <FormattedNumber
              value={order.grandTotal / 100}
              style={"currency"}
              currency={order.currencyCode}
            />
          ) : (
            "—"
          );
        }}
      </Column>
    </Table>
  );
};

export default memo(OrderHistory);
