import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<"greeting" | "value", ThemeUIStyleObject> = {
  greeting: {
    fontSize: 4,
    fontWeight: "bold",
  },
  value: {
    fontWeight: "bold",
  },
};

export default sxStyles;
