import { ReactNode, ReactPortal } from "react";
import ReactDOM from "react-dom";

export const createModalPortal = (children: ReactNode): ReactPortal | null => {
  if (!children || typeof window === "undefined") {
    return null;
  }

  const modalRoot = document.getElementById("modal-root");

  if (!modalRoot) {
    return null;
  }

  return ReactDOM.createPortal(children, modalRoot);
};
