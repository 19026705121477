import groupBy from "lodash/groupBy";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { gql, OperationResult, useClient } from "urql";

import { btoa } from "../../helpers/string";
import { Dig } from "../../utils/ts";
import type {
  ScheduleQuery,
  ScheduleQueryVariables,
} from "./__generated__/index.types";

type MovieLanguage = Exclude<
  Dig<
    ScheduleQuery,
    ["movieShowtimeList", "edges", number, "node", "movie", "languages"]
  >,
  undefined
>;

export type MovieShowtime = Exclude<
  Dig<
    ScheduleQuery,
    ["movieShowtimeList", "edges", number, "node", "showtimes", number]
  >,
  undefined
> & {
  isExpired: boolean;
  movieLanguage?: MovieLanguage[number] | undefined | null;
};

type MovieId = string;
type ScheduleDate = string;
export type MovieSchedule = Record<ScheduleDate, MovieShowtime[]>;
export type Schedule = Record<MovieId, MovieSchedule>;

const useAllocineSchedule = (
  theaterId: string | null | undefined,
  theaterTimeZone: string | undefined,
  movieIds: string[],
  startDate: string | undefined,
  endDate: string | undefined,
  withoutOffset?: boolean
): { schedule: Schedule; loading: boolean } => {
  const [loading, setLoading] = useState<boolean>(true);
  const [queryResults, setQueryResults] = useState<
    (OperationResult<ScheduleQuery> | null)[]
  >([]);

  const b64TheaterId = btoa(`Theater:${theaterId}`);

  const skip =
    !theaterId ||
    !theaterTimeZone ||
    !startDate ||
    !endDate ||
    !movieIds.length;

  const client = useClient();

  const fetch = async () => {
    setLoading(true);
    const queries: Promise<OperationResult<ScheduleQuery> | null>[] = [];

    movieIds.forEach((movieId) => {
      if (skip) {
        queries.push(
          new Promise((resolve) => {
            resolve(null);
          })
        );
      }

      queries.push(
        client
          .query<ScheduleQuery, ScheduleQueryVariables>(
            // query
            gql`
              query Schedule(
                $theaterId: String!
                $from: DateTime!
                $to: DateTime!
                $movieIds: [String]!
              ) {
                movieShowtimeList(
                  theater: $theaterId
                  first: 120
                  order: WEEKLY_POPULARITY
                  from: $from
                  to: $to
                  movie: $movieIds
                ) {
                  edges {
                    node {
                      movie {
                        id
                        internalId
                        languages
                      }
                      showtimes {
                        id
                        startsAt
                        diffusionVersion
                        tags
                        projection
                        isPreview
                        data {
                          ticketing {
                            urls
                            type
                            provider
                          }
                        }
                      }
                    }
                  }
                }
              }
            `,
            // variables
            {
              theaterId: b64TheaterId,
              from: startDate,
              to: endDate,
              movieIds: [btoa(`Movie:${movieId}`)],
            }
          )
          .toPromise()
      );
    });

    await Promise.all(queries).then((results) => {
      setQueryResults(results);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch().catch(() => {
      setQueryResults([]);
      setLoading(false);
    });
  }, [startDate, endDate, theaterId]);

  const schedule: Schedule = {};

  if (!theaterId || !theaterTimeZone || !startDate || !endDate) {
    return { schedule, loading };
  }

  const nowAtTheater = DateTime.local().setZone(theaterTimeZone);
  const thisMorningAtTheater = DateTime.local()
    .setZone(theaterTimeZone)
    .set({ hour: 3, minute: 0, second: 0, millisecond: 0 });

  queryResults?.forEach((queryResult, index) => {
    const movieId = movieIds?.[index] ? movieIds[index].toString() : undefined;

    if (movieId) {
      const node = queryResult?.data?.movieShowtimeList?.edges?.[0]?.node;
      const movieLanguage = node?.movie?.languages?.[0];

      // Prepare showtimes
      const showtimes: MovieShowtime[] = [];
      node?.showtimes?.map((showtime) => {
        if (showtime && showtime.startsAt && theaterTimeZone) {
          const showtimeAtTheater = DateTime.fromISO(showtime.startsAt, {
            zone: theaterTimeZone,
          });

          // Showtime is in the past
          const isExpired = showtimeAtTheater.toISO() <= nowAtTheater.toISO();

          // Showtimes is in the previous day
          const isOutdated =
            showtimeAtTheater.toISO() < thisMorningAtTheater.toISO();

          if (!isOutdated) {
            showtimes.push({
              ...showtime,
              isExpired,
              movieLanguage,
            });
          }
        }
      });

      // Group showtimes by date
      const groupedShowtimes: MovieSchedule = groupBy(showtimes, (showtime) => {
        return (
          DateTime.fromISO(showtime.startsAt, { zone: theaterTimeZone })
            // Cinema day offset
            .minus({ hours: withoutOffset ? 0 : 3 })
            .toISODate()
        );
      });

      Object.keys(groupedShowtimes).forEach((date) => {
        if (!schedule[movieId]) {
          schedule[movieId] = {};
        }

        if (!schedule[movieId][date]) {
          schedule[movieId][date] = [];
        }

        if (schedule[movieId] && schedule[movieId][date]) {
          schedule[movieId][date] = groupedShowtimes[date];
        }
      });
    }
  });

  return { schedule, loading };
};

export default useAllocineSchedule;
