import sxStylesOrig from "gatsby-theme-footer/src/components/Footer/styles";

const sxStyles: typeof sxStylesOrig = {
  ...sxStylesOrig,
  wrapper: {},
  footer: {
    display: "grid",
    gap: 4,
  },
  socialLinks: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    fontSize: 4,
  },
  secondaryMenu: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    textAlign: "center",
  },
};

export default sxStyles;
