import { responsive } from "@boxoffice/screenplay";
import { ThemeUIStyleObject } from "theme-ui";

const button: ThemeUIStyleObject = {
  background: "none",
  height: responsive({ xs: "40px", sm: "40px" }),
  textAlign: "center",
  ":focus": {
    background: "none",
  },
};

const sxStyles: Record<
  "wrapper" | "pageButton" | "controlButton" | "activeButton",
  ThemeUIStyleObject
> = {
  wrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 3,
  },
  pageButton: {
    ...button,
    borderColor: "transparent",
    borderRadius: "maximum",
    fontWeight: "normal",
    marginRight: 1,
    minWidth: "40px",
    paddingX: responsive({ xs: 2, sm: 2 }),
  },
  activeButton: {
    borderColor: "primary",
    pointerEvents: "none",
  },
  controlButton: {
    ...button,
    border: "none",
    color: "text",
    paddingX: responsive({ xs: 1, sm: 1 }),
    ":hover": {
      background: "none",
      color: "highlight",
    },
  },
};

export default sxStyles;
