/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const CloseSubMenuIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    sx={{
      width: "1em",
      height: "1em",
    }}
  >
    <path
      fill="currentColor"
      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
    />
  </svg>
);

export default memo(CloseSubMenuIcon);
