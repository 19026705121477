import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  closedPerm: {
    id: "closure-message.closed-perm-message",
    defaultMessage: "Theater closed permanently.",
  },
  closedTemp: {
    id: "closure-message.closed-temp-message",
    defaultMessage: "Theater closed temporarily.",
  },
  closedTime: {
    id: "closure-message.closed-time-message",
    defaultMessage: "We are closed from {startsAt} to {endsAt}.",
  },
});

export default messages;
