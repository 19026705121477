import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  privacySettings: {
    id: "secondary-menu.privacy-policy-call-to-action-label",
    defaultMessage: "Privacy settings",
  },
});

export default messages;
