/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

import type { LayoutElementSize } from "../../../__generated__/gatsby.types";
import Item from "./Item";
import type { Event } from "./types";
import Wrapper from "./Wrapper";

export type Props = {
  events: Event[];
  isNested?: boolean;
  containerSize?: LayoutElementSize;
};

const FeaturedEventsComponent: React.FC<Props> = ({
  events,
  isNested,
  containerSize,
}) => {
  return (
    <Wrapper
      showEmptyState={events.length === 0}
      isNested={isNested}
      containerSize={containerSize}
    >
      {events.map((event, index) => {
        return <Item key={event.id} event={event} index={index} />;
      })}
    </Wrapper>
  );
};

export default memo(FeaturedEventsComponent);
