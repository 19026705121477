/** @jsx jsx */
import React from "react";
import { Maybe } from "shared/utils/ts";
import { jsx } from "theme-ui";

import type { Slide } from "../types";
import Custom from "./Custom";
import Event from "./Event";
import Movie from "./Movie";

export type Props = {
  slide: Maybe<Slide>;
};

const SlideComponent: React.FC<Props> = ({ slide }) => {
  if (slide?.movie) {
    return <Movie slide={slide} />;
  }

  if (slide?.event) {
    return <Event slide={slide} />;
  }

  return <Custom slide={slide} />;
};

export default React.memo(SlideComponent);
