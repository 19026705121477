/** @jsx jsx */
import React, { memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import { jsx } from "theme-ui";

import Select, { Props } from "../Select";
import messages from "./i18n";

const Gender: React.FC<Omit<Props, "children">> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
}) => {
  const { formatMessage } = useIntl();
  const GENDER_OPTIONS = [
    {
      label: formatMessage(messages.male),
      value: "male",
    },
    {
      label: formatMessage(messages.female),
      value: "female",
    },
  ];

  return (
    <Select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      required={false}
    >
      <option disabled value="" />
      {GENDER_OPTIONS.map((gender) => (
        <option value={gender.value} key={gender.value}>
          {gender.label}
        </option>
      ))}
    </Select>
  );
};

export default memo(Gender);
