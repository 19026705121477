/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { TheaterSelector } from "gatsby-theme-theater-selector";
import React from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type { ScheduleOptionsBarQuery } from "../../../__generated__/gatsby.types";
import CollectionSelector from "./CollectionSelector";
import ScheduleOptionsBarComponent from "./Component";
import messages from "./i18n";
import PeriodSelector from "./PeriodSelector";
import type { ScheduleOptionsBarProps } from "./types";

const ScheduleOptionsBar: React.FC<ScheduleOptionsBarProps> = ({
  hasMoviesComingSoon = false,
  periodFilter,
  setView,
  view,
  movieIds,
  includeTheaterSelector,
  includeComingSoonTab,
  includeDatePicker,
  includeDateSlider,
  theaterId,
  dateSliderSlidesPerViewScale,
  periodSelectorDisabled,
  fullWidth,
}) => {
  const { formatMessage } = useIntl();
  const pageContext = usePageContext();

  const data = useStaticQuery<ScheduleOptionsBarQuery>(graphql`
    query ScheduleOptionsBar {
      allTheater(sort: { fields: name, order: ASC }) {
        nodes {
          id
          showtimeWeekReleaseDay
          timeZone
        }
      }
    }
  `);

  const selectedTheater = pageContext.isSingleLocation
    ? data.allTheater.nodes[0]
    : data.allTheater.nodes.find((theater) => theater.id === theaterId);

  const theaterSelectorRender =
    !pageContext.isSingleLocation && includeTheaterSelector ? (
      <TheaterSelector
        triggerPrefix={formatMessage(messages.theaterSelectorPrefix)}
      />
    ) : null;

  const collectionSelectorRender =
    view && setView ? (
      <CollectionSelector
        setView={setView}
        view={view}
        views={[
          "NOW_PLAYING",
          hasMoviesComingSoon && includeComingSoonTab
            ? "COMING_SOON"
            : undefined,
        ]}
      />
    ) : null;

  const periodSelectorRender =
    (periodFilter?.length || 0) > 1 ||
    includeDatePicker ||
    includeDateSlider ? (
      <PeriodSelector
        periodFilter={periodFilter}
        theater={selectedTheater}
        movieIds={movieIds}
        includeDatePicker={includeDatePicker}
        includeDateSlider={includeDateSlider}
        dateSliderSlidesPerViewScale={dateSliderSlidesPerViewScale}
        disabled={periodSelectorDisabled}
      />
    ) : null;

  return (
    <ScheduleOptionsBarComponent
      theaterSelectorRender={theaterSelectorRender}
      collectionSelectorRender={collectionSelectorRender}
      periodSelectorRender={periodSelectorRender}
      noSelectedTheaterMessage={formatMessage(messages.placeholder)}
      hasMoviesComingSoon={hasMoviesComingSoon}
      selectedTheaterId={selectedTheater?.id}
      view={view}
      fullWidth={fullWidth}
    />
  );
};

export default React.memo(ScheduleOptionsBar);
