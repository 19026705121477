import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  info: {
    id: "movies.carousel.event.info-label",
    defaultMessage: "Read",
  },
});

export default messages;
