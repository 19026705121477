/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { graphql, useStaticQuery } from "gatsby";
import { FC, memo } from "react";
import Bleed from "shared/components/Bleed";
import OutboundLink from "shared/components/OutboundLink";
import { FALLBACK_SIMPLIFIED_LOCALE } from "shared/constants";
import usePageContext from "shared/hooks/usePageContext";
import { Flex, jsx } from "theme-ui";

import { StoreBadgesQuery } from "../../../__generated__/gatsby.types";
import { BADGES } from "./constants";
import { StorePlatform } from "./types";

type Props = {
  displayIOSLink: boolean;
  displayAndroidLink: boolean;
  buttonSize?: "SMALL" | "MEDIUM" | "BIG";
};

const getBadgeHeight = (size: string): string => {
  switch (size) {
    case "SMALL":
      return "32px";
    case "MEDIUM":
      return "54px";
    default:
    case "BIG":
      return "72px";
  }
};

const StoreBadges: FC<Props> = ({
  displayIOSLink,
  displayAndroidLink,
  buttonSize = "MEDIUM",
}) => {
  const pageContext = usePageContext();

  const data = useStaticQuery<StoreBadgesQuery>(graphql`
    query StoreBadges {
      theater {
        practicalInfo {
          androidUrl
          iosUrl
        }
      }
    }
  `);

  const badgeHeight = getBadgeHeight(buttonSize);

  const linkNodes: {
    platform: StorePlatform;
    link: string;
  }[] = [];

  if (data.theater?.practicalInfo?.iosUrl && displayIOSLink) {
    linkNodes.push({
      platform: "ios",
      link: data.theater.practicalInfo.iosUrl,
    });
  }

  if (data.theater?.practicalInfo?.androidUrl && displayAndroidLink) {
    linkNodes.push({
      platform: "android",
      link: data.theater.practicalInfo.androidUrl,
    });
  }

  return linkNodes.length > 0 ? (
    <Bleed horizontal={1} vertical={1}>
      <Flex
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          lineHeight: 0,
          margin: "0 auto",
        }}
      >
        {linkNodes.map((linkNode) => {
          const badges = BADGES[linkNode.platform];
          const asset = (badges[pageContext.intl.locale.long] ||
            badges[pageContext.intl.locale.short] ||
            badges[FALLBACK_SIMPLIFIED_LOCALE]) as string;

          return (
            <div
              key={linkNode.platform}
              sx={{
                margin: 1,
              }}
            >
              <OutboundLink href={linkNode.link} sx={{ display: "block" }}>
                <img
                  src={asset}
                  alt={linkNode.platform}
                  sx={{
                    display: "block",
                    width: responsive({
                      xs: "auto",
                    }),
                    height: responsive({
                      xs: badgeHeight,
                    }),
                  }}
                />
              </OutboundLink>
            </div>
          );
        })}
      </Flex>
    </Bleed>
  ) : null;
};

export default memo(StoreBadges);
