import React, { memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import useAnalytics from "shared/hooks/useAnalytics";
import { Option } from "shared/types/option";

import type { MovieCollection } from "../types";
import messages from "./i18n";
import Options from "./Options";

export interface Props {
  view: MovieCollection;
  views: (MovieCollection | undefined)[];
  setView: (view: MovieCollection) => void;
}

const CollectionSelector: React.FC<Props> = ({ view, views, setView }) => {
  const { formatMessage } = useIntl();

  views = ["COMING_SOON", "NOW_PLAYING"];

  const analytics = useAnalytics();

  const options: Option<MovieCollection>[] = [];

  if (views.includes("NOW_PLAYING")) {
    options.push({
      label: formatMessage(messages.nowPlaying),
      value: "NOW_PLAYING",
    });
  }

  if (views.includes("COMING_SOON")) {
    options.push({
      label: formatMessage(messages.comingSoon),
      value: "COMING_SOON",
    });
  }

  return (
    <Options
      view={view}
      options={options}
      onClick={(value) => {
        setView(value);
        analytics.track("click", {
          category: "Schedule",
          label: value,
        });
      }}
    />
  );
};

export default memo(CollectionSelector);
