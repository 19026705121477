import uniqueId from "lodash/uniqueId";
import React, { memo, useCallback, useEffect, useRef } from "react";

import { Trailer } from "../../types/trailer";
import { JWPLAYER_TOKEN } from "./constants";

interface Props {
  title?: string;
  video?: Trailer;
}

interface Player {
  remove: () => void;
}

const VideoPlayer: React.FC<Props> = ({ title = "", video }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<Player | null>(null);
  const id = uniqueId();

  const setup = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const player = await import("@webedia-services/webedia-player");

    try {
      playerRef.current = player.setup({
        key: JWPLAYER_TOKEN,
        label: title,
        target: id,
        sharing: false,
        paths: video,
        jwpOptions: {
          autostart: true,
          mute: false,
          width: "100%",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.remove();
        playerRef.current = null;
      }
    };
  });

  return (
    <div
      id={id}
      ref={(ref) => {
        containerRef.current = ref;
        if (video && containerRef.current) {
          setup();
        }
      }}
    />
  );
};

export default memo(VideoPlayer);
