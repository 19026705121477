/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const Letterboxd: React.FC<Record<string, unknown>> = () => (
  <svg viewBox="0 0 500 500" sx={{ width: ".8125em", height: ".8125em" }}>
    <path
      d="M250,500 C111.928813,500 0,388.071187 0,250 C0,111.928813 111.928813,0 250,0 C388.071187,0 500,111.928813 500,250 C500,388.071187 388.071187,500 250,500 Z M165,103 L165,383 L354.15639,383 L354.15639,313.100855 L237.72411,313.100855 L237.72411,103 L165,103 Z"
      id="Combined-Shape"
      fill="currentColor"
    />
  </svg>
);

export default memo(Letterboxd);
