import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  placeholder: {
    id: "schedule.no-theater-placeholder-message",
    defaultMessage: "Select a theater to see showtimes",
  },
  theaterSelectorPrefix: {
    id: "schedule.theater-selector-prefix-label",
    defaultMessage: "At:",
  },
});

export default messages;
