/** @jsx jsx */

import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import EmptyState from "../EmptyState";
import { Event } from ".";
import EventComponent from "./Event";
import sxStyles from "./styles";
import Wrapper from "./Wrapper";

const ScheduleOptionsBar = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleOptionsBar,
  }
);

export type Props = {
  events?: Event[];
};

const AllEventsComponent: React.FC<Props> = ({ events }) => {
  const { isSingleLocation, theaterId } = usePageContext();

  return (
    <React.Fragment>
      {!theaterId && !isSingleLocation && (
        <div sx={sxStyles.scheduleOptionsBar}>
          <ScheduleOptionsBar includeTheaterSelector theaterId={undefined} />
        </div>
      )}
      {events?.length ? (
        <Wrapper>
          {events.map((event) => (
            <EventComponent key={event.id} event={event} />
          ))}
        </Wrapper>
      ) : (
        <EmptyState />
      )}
    </React.Fragment>
  );
};

export default memo(AllEventsComponent);
