import { getMostReadable } from "shared/helpers/colors";
import { Theme, ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<"link", ThemeUIStyleObject> = {
  link: {
    color: (theme: Theme) =>
      getMostReadable(theme.rawColors?.background).toHexString(),
    wordBreak: "break-word",
  },
};

export default sxStyles;
