/** @jsx jsx */
import { DateTime } from "luxon";
import { FC, memo, useCallback, useState } from "react";
import { Icon, mdiChevronLeft, mdiChevronRight } from "shared/components/Icon";
import { getMostReadable, UIColors } from "shared/helpers/colors";
import useThemeUI from "shared/hooks/useThemeUI";
import { Flex, Grid, jsx } from "theme-ui";

import Component from "./Component";

type Props = {
  date: string;
  locale: string;
  maxDate?: string;
  minDate?: string;
  onChange: (dateTime: DateTime) => void;
  shouldDisableDate?: (dateTime: DateTime) => boolean;
};

const Calendar: FC<Props> = ({
  date,
  locale,
  maxDate,
  minDate,
  onChange,
  shouldDisableDate,
}) => {
  const { theme } = useThemeUI();
  const dateTime = DateTime.fromISO(date, { zone: "UTC" });
  const [cursorDate, setCursorDate] = useState<DateTime>(dateTime);
  const [selectedDate, setSelectedDate] = useState<DateTime>(dateTime);
  const minDateTime = minDate
    ? DateTime.fromISO(minDate, { zone: "UTC" })
    : undefined;
  const maxDateTime = maxDate
    ? DateTime.fromISO(maxDate, { zone: "UTC" })
    : undefined;

  const canGoPrev = minDateTime
    ? cursorDate.minus({ months: 1 }) > minDateTime.startOf("month")
    : true;

  const canGoNext = maxDateTime
    ? cursorDate.plus({ months: 1 }) <= maxDateTime.endOf("month")
    : true;

  const onClickPrev = useCallback(() => {
    if (canGoPrev) {
      setCursorDate(cursorDate.minus({ months: 1 }));
    }
  }, [setCursorDate, cursorDate, canGoPrev]);

  const onClickNext = useCallback(() => {
    if (canGoNext) {
      setCursorDate(cursorDate.plus({ months: 1 }));
    }
  }, [setCursorDate, cursorDate, canGoNext]);

  const activeUIElementsColor = getMostReadable(theme.rawColors?.background, [
    UIColors.lightAlt.toHexString(),
    UIColors.darkAlt.toHexString(),
  ]).toHexString();

  return (
    <div
      sx={{
        userSelect: "none",
      }}
    >
      <Grid gap={3}>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={onClickPrev}
            sx={
              canGoPrev
                ? {
                    cursor: "pointer",
                    "&:hover": { color: activeUIElementsColor },
                  }
                : { opacity: 0.15 }
            }
          >
            <Icon
              path={mdiChevronLeft}
              color={"currentColor"}
              size={1.5}
              sx={{ display: "block" }}
            />
          </div>
          {cursorDate
            .setLocale(locale)
            .toLocaleString({ month: "long", year: "numeric" })}
          <div
            onClick={onClickNext}
            sx={
              canGoNext
                ? {
                    cursor: "pointer",
                    "&:hover": { color: activeUIElementsColor },
                  }
                : { opacity: 0.15 }
            }
          >
            <Icon
              path={mdiChevronRight}
              color={"currentColor"}
              size={1.5}
              sx={{ display: "block" }}
            />
          </div>
        </Flex>
        <div
          sx={{
            borderBottomColor: "currentcolor",
            borderBottomStyle: "solid",
            borderBlockWidth: "1px",
            opacity: 0.15,
          }}
        />
        <Component
          cursorDate={cursorDate}
          locale={locale}
          maxDateTime={maxDateTime}
          minDateTime={minDateTime}
          onChange={onChange}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          shouldDisableDate={shouldDisableDate}
        />
      </Grid>
    </div>
  );
};

export default memo(Calendar);
