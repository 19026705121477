/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const OpenSubMenuIcon: React.FC = () => (
  <svg
    viewBox="0 0 24 24"
    sx={{
      width: "1em",
      height: "1em",
    }}
  >
    <path
      fill="currentColor"
      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
    />
  </svg>
);

export default memo(OpenSubMenuIcon);
