/** @jsx jsx */
import React, { memo, useRef } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "shared/components/Swiper";
import ChevronLeftSquare from "shared/components/Swiper/Arrow/ChevronLeftSquare";
import ChevronRightSquare from "shared/components/Swiper/Arrow/ChevronRightSquare";
import { Maybe } from "shared/utils/ts";
import { jsx, ThemeUIStyleObject } from "theme-ui";

import SlideComponent from "./Slide";
import sxStyles from "./styles";
import type { Slide } from "./types";

export type Props = {
  isNested: boolean;
  slides?: Maybe<ReadonlyArray<Maybe<Slide>>>;
};

const COMMON_ARROW_SX: ThemeUIStyleObject = {
  position: "absolute",
  zIndex: 1,
  top: "50%",
  transform: "translateY(-50%)",
};

const HeroCarousel: React.FC<Props> = ({ slides }) => {
  const swiperInstanceRef = useRef<SwiperClass | null>(null);

  if (!slides?.length) {
    return null;
  }

  return (
    <div sx={{ ...sxStyles.wrapper, position: "relative" }}>
      <Swiper
        onInit={(instance) => {
          swiperInstanceRef.current = instance || null;
        }}
        loop={slides.length > 1}
        slidesPerView={1}
        freeMode
        grabCursor
      >
        {slides?.map((slide, index) => {
          return (
            <SwiperSlide key={index}>
              <SlideComponent slide={slide} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {slides.length > 1 && (
        <div
          sx={{
            ...COMMON_ARROW_SX,
            left: 0,
          }}
          onClick={() => {
            swiperInstanceRef.current?.slidePrev?.();
          }}
        >
          <ChevronLeftSquare />
        </div>
      )}
      {slides.length > 1 && (
        <div
          sx={{
            ...COMMON_ARROW_SX,
            right: 0,
          }}
          onClick={() => {
            swiperInstanceRef.current?.slideNext?.();
          }}
        >
          <ChevronRightSquare />
        </div>
      )}
    </div>
  );
};

export default memo(HeroCarousel);
