import sxStylesOrig from "gatsby-theme-secondary-menu/src/components/SecondaryMenu/styles";
import { getMostReadable } from "shared/helpers/colors";

const sxStyles: typeof sxStylesOrig = {
  ...sxStylesOrig,
  link: {
    color: (theme) =>
      getMostReadable(theme.rawColors?.background).toHexString(),
    lineHeight: "2",
  },
};

export default sxStyles;
