/** @jsx jsx */
import { CDNImage } from "@boxoffice/screenplay";
import { EventSlideProps } from "gatsby-theme-hero-carousel";
import React from "react";
import Link from "shared/components/Link";
import useThemeUI from "shared/hooks/useThemeUI";
import { AspectRatio, Flex, jsx } from "theme-ui";

import config from "../../config";
import sxStyles from "../../styles";

const CustomSlide: React.FC<EventSlideProps> = ({ slide }) => {
  const { theme } = useThemeUI();

  const Image = (
    <AspectRatio
      ratio={config.imageRatio}
      sx={sxStyles.slideImageWrapperContent}
    >
      <CDNImage
        src={slide?.image?.src || slide?.movie?.poster}
        alt={slide?.image?.alt || ""}
        width={config.imageWidth}
        sx={sxStyles.slideImage}
      />
    </AspectRatio>
  );

  const Caption = (
    <Flex sx={sxStyles.slideContent}>
      {/* Caption text */}
      {(slide?.caption || slide?.subCaption) && (
        <div
          sx={{
            ...sxStyles.slideCaptionWrapper,
            ...(!slide?.button?.link ? { bottom: 0 } : {}),
          }}
        >
          <div sx={sxStyles.slideCaptionContent}>
            <div sx={sxStyles.slideCaptionBackdrop} />
            {slide.subCaption && (
              <div sx={sxStyles.slideCaptionSubCaption}>{slide.subCaption}</div>
            )}
            {slide.caption && (
              <div sx={sxStyles.slideCaptionText}>{slide.caption}</div>
            )}
          </div>
        </div>
      )}
      {/* Caption buttons */}
      {slide?.button?.link && (
        <div sx={{ position: "relative", zIndex: 1 }}>
          <Flex>
            <Link
              sx={{
                variant: "buttons.secondary",
                borderRadius: "medium",
                backgroundColor: theme.rawColors?.background,
              }}
              to={slide?.button?.link || "/"}
            >
              {slide?.button?.label || slide?.caption}
            </Link>
          </Flex>
        </div>
      )}
    </Flex>
  );

  return (
    <React.Fragment>
      <div sx={sxStyles.slideWrapper}>
        {Image}
        {Caption}
      </div>
    </React.Fragment>
  );
};

export default React.memo(CustomSlide);
