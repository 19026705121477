/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { graphql, useStaticQuery } from "gatsby";
import groupBy from "lodash/groupBy";
import React, { memo, ReactNode, useEffect, useState } from "react";
import {
  Icon,
  mdiChevronDown,
  mdiCrossHairsGps,
  mdiMapMarker,
} from "shared/components/Icon";
import useIntl from "shared/helpers/i18n/useIntl";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { Property } from "shared/types/css";
import { jsx, Spinner, ThemeUIStyleObject } from "theme-ui";

import type { TheaterSelectorQuery } from "../../../__generated__/gatsby.types";
import { getNearestTheater } from "./helpers";
import messages from "./i18n";
import ItemGroup from "./ItemGroup";
import ItemLabel, { Theater } from "./ItemLabel";

type Props = {
  dropdownSxStyle?: ThemeUIStyleObject;
  triggerSxStyle?: ThemeUIStyleObject;
  variant?: "ScheduleOptionBar";
  triggerPrefix?: ReactNode;
  renderTrigger?: (args: {
    children: ReactNode;
    onClick: (
      event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>
    ) => void;
  }) => ReactNode;
};

const TheaterSelector: React.FC<Props> = ({
  dropdownSxStyle,
  triggerSxStyle,
  variant = "",
  triggerPrefix,
  renderTrigger,
}) => {
  const [selectedTheaterId, setSelectedTheaterId] = useSelectedTheaterId();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [geolocating, setGeoLocating] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const hideDropdown = (event: KeyboardEvent | MouseEvent) => {
    if (event instanceof MouseEvent) {
      setDropdownVisible(false);
    }

    if (event instanceof KeyboardEvent && event.key === "Escape") {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", hideDropdown);
    window.addEventListener("keydown", hideDropdown);
    return () => {
      window.removeEventListener("click", hideDropdown);
      window.removeEventListener("keydown", hideDropdown);
    };
  }, []);

  const data = useStaticQuery<TheaterSelectorQuery>(graphql`
    query TheaterSelector {
      allTheater(sort: { fields: name, order: ASC }) {
        nodes {
          id
          name
          timeZone
          practicalInfo {
            closed
            temporaryClosure {
              endsAt
            }
            location {
              state
            }
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
    }
  `);

  const theaterGroups: Record<string, Theater[]> = groupBy(
    data.allTheater.nodes,
    (theater) => {
      return theater.practicalInfo?.location?.state || "";
    }
  );

  const theaterGroupsSorted: string[] = Object.keys(theaterGroups).sort(
    (label1, label2) => {
      if (label1 === "") return 1;
      if (label2 === "") return -1;
      return label1.localeCompare(label2);
    }
  );

  const onSelect = (nextTheaterId: string) => {
    setSelectedTheaterId(nextTheaterId);
    setDropdownVisible(false);
  };

  const selectNearestTheater = () => {
    if (geolocating) return;
    setGeoLocating(true);
    navigator.geolocation.getCurrentPosition(
      (currentPos: GeolocationPosition) => {
        const userCoords: GeolocationCoordinates = currentPos.coords;

        const nearestTheater = getNearestTheater(
          userCoords,
          data.allTheater.nodes
        );
        if (nearestTheater) {
          setSelectedTheaterId(nearestTheater.id);
        }
        setGeoLocating(false);
        setDropdownVisible(false);
      },
      () => {
        setGeoLocating(false);
        setDropdownVisible(false);
      }
    );
  };

  const selectedTheater = data.allTheater.nodes.find(
    (theater) => theater.id === selectedTheaterId
  );

  const triggerWrapperProps: ThemeUIStyleObject = {
    variant: "forms.theaterSelectTriggerWrapper",
  };

  const triggerSxProps: ThemeUIStyleObject = {
    variant: `forms.theaterSelectTrigger${variant}`,
    ...triggerSxStyle,
  };

  const dropdownSxProps: ThemeUIStyleObject = {
    variant: `forms.theaterSelectDropdown${variant}`,
    ...dropdownSxStyle,
  };

  const dropdownOptionSxProps: ThemeUIStyleObject = {
    variant: `forms.theaterSelectDropdownOption${variant}`,
  };

  const triggerChildren: ReactNode = (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
    >
      <ItemLabel
        theater={selectedTheater}
        prefix={triggerPrefix}
        isTrigger
        lines={1}
      />
      <Icon
        path={mdiChevronDown}
        sx={{ width: "1em", height: "1em", display: "block" }}
      />
    </div>
  );

  const triggerOnClick = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };

  const locationTriggerOnClick = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>
  ): void => {
    if (event.currentTarget) {
      event.currentTarget.blur();
    }
    selectNearestTheater();
  };

  return (
    <div
      sx={{
        alignItems: "center",
        display: "flex",
        position: "relative",
        width: "100%",
        gap: responsive({ xs: 2 }),
        flexDirection: responsive<Property.FlexDirection>({
          xs: "column",
          sm: "row",
        }),
      }}
    >
      <div sx={triggerWrapperProps}>
        {renderTrigger ? (
          renderTrigger({ children: triggerChildren, onClick: triggerOnClick })
        ) : (
          <div onClick={triggerOnClick} sx={triggerSxProps}>
            {triggerChildren}
          </div>
        )}
        {dropdownVisible && (
          <div
            sx={dropdownSxProps}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <ItemGroup>
              <div
                onClick={() => onSelect("")}
                sx={dropdownOptionSxProps}
                tabIndex={0}
              >
                {formatMessage(messages.none)}
              </div>
              <div
                onClick={() => onSelect("")}
                sx={dropdownOptionSxProps}
                tabIndex={0}
              >
                <div
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    locationTriggerOnClick(event);
                  }}
                >
                  <span
                    sx={{
                      marginRight: ".5em",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {geolocating ? (
                      <Spinner size={"1em"} sx={{ color: "inherit" }} />
                    ) : (
                      <Icon
                        path={mdiCrossHairsGps}
                        sx={{ width: "1em", height: "1em" }}
                      />
                    )}
                  </span>
                  {formatMessage(messages.useMyLocation)}
                </div>
              </div>
            </ItemGroup>
            {theaterGroupsSorted.map((label) => {
              const group: Theater[] = theaterGroups[label].sort(
                (theater1, theater2) =>
                  (theater1.name || "").localeCompare(theater2.name || "")
              );

              return (
                <ItemGroup
                  key={label}
                  label={theaterGroupsSorted.length > 1 ? label : undefined}
                >
                  {group.map((theater) => {
                    const isSelected = selectedTheaterId === theater.id;

                    return (
                      <div
                        key={theater.id}
                        onClick={() => onSelect(theater.id)}
                        tabIndex={0}
                        sx={{
                          fontWeight: isSelected ? "bold" : undefined,
                          display: "flex",
                          alignItems: "center",
                          ...dropdownOptionSxProps,
                        }}
                      >
                        <ItemLabel
                          theater={theater}
                          prefix={
                            isSelected ? (
                              <span
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  path={mdiMapMarker}
                                  sx={{ width: "1em", height: "1em" }}
                                />
                              </span>
                            ) : undefined
                          }
                        />
                      </div>
                    );
                  })}
                </ItemGroup>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TheaterSelector);
