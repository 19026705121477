import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  male: {
    id: "loyalty.form.field.gender.male-label",
    defaultMessage: "Male",
  },
  female: {
    id: "loyalty.form.field.gender.female-label",
    defaultMessage: "Female",
  },
});

export default messages;
