/** @jsx jsx */
import {
  CDNPoster,
  getAspectRatioFromCountryCode,
  RibbonContainer,
  ThemeSpec,
} from "@boxoffice/screenplay";
import * as React from "react";
import { jsx } from "theme-ui";

import { ANIMATION_DURATION } from "../../constants";
import { getMostReadable, getTinyColor } from "../../helpers/colors";
import useIntl from "../../helpers/i18n/useIntl";
import usePageContext from "../../hooks/usePageContext";
import messages from "./i18n";

type Props = {
  poster: string | null | undefined;
  title: string | null | undefined;
  sizes: number | (string | number | null | undefined)[];
  ribbonMessage?: keyof typeof messages | null | undefined;
};

const MoviePoster: React.FC<Props> = ({
  poster,
  title,
  sizes,
  ribbonMessage,
}) => {
  const pageContext = usePageContext();
  const { formatMessage } = useIntl();
  const component = (
    <div
      sx={{
        transition: `transform ${ANIMATION_DURATION}`,
        "a:hover &": {
          transform: "scale(1.05)",
        },
      }}
    >
      <CDNPoster
        sizes={sizes}
        src={poster || undefined}
        alt={title || ""}
        aspectRatio={getAspectRatioFromCountryCode(
          pageContext.intl.country.code
        )}
        sx={(theme) => {
          const bg = getTinyColor(theme.rawColors?.background);
          const cardBG = bg.isLight()
            ? bg.shade(5).toHexString()
            : bg.tint(5).toHexString();

          return {
            backgroundColor: cardBG,
            // FIXME
            // fix this important
            "& > div": {
              color: `${getMostReadable(cardBG).toHexString()} !important`,
            },
          };
        }}
      />
    </div>
  );

  return (
    <div
      sx={{
        overflow: "hidden",
        // @ts-expect-error weird type
        borderRadius: (theme: ThemeSpec) => theme.images?.poster?.borderRadius,
      }}
    >
      {ribbonMessage ? (
        <RibbonContainer text={formatMessage(messages[ribbonMessage])}>
          {component}
        </RibbonContainer>
      ) : (
        component
      )}
    </div>
  );
};

export default React.memo(MoviePoster);
