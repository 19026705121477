import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  date: {
    id: "loyalty.order-history.date-label",
    defaultMessage: "Date",
  },
  details: {
    id: "loyalty.order-history.details-label",
    defaultMessage: "Details",
  },
  location: {
    id: "loyalty.order-history.location-label",
    defaultMessage: "Location",
  },
  value: {
    id: "loyalty.order-history.value-label",
    defaultMessage: "Value",
  },
  points: {
    id: "loyalty.order-history.points-label",
    defaultMessage: "Points",
  },
  emptyState: {
    id: "loyalty.order-history.empty-state-message",
    defaultMessage: "No orders to display",
  },
});

export default messages;
