/** @jsx jsx */
import { CDNImage, responsive } from "@boxoffice/screenplay";
import {
  movieSlideMessages,
  MovieSlideProps,
} from "gatsby-theme-hero-carousel";
import React from "react";
import { Icon, mdiPlay, mdiTicket } from "shared/components/Icon";
import Link from "shared/components/Link";
import useIntl from "shared/helpers/i18n/useIntl";
import { createModalPortal } from "shared/helpers/portal";
import useMovieTrailer from "shared/hooks/useMovieTrailer";
import { AspectRatio, Button, Flex, jsx } from "theme-ui";

import config from "../../config";
import sxStyles from "../../styles";

const MovieSlide: React.FC<MovieSlideProps> = ({ slide }) => {
  const { formatMessage } = useIntl();

  const [TrailerComponent, onShowTrailer, hasTrailer] = useMovieTrailer({
    title: slide?.caption,
    trailer: slide?.movie?.trailer,
  });

  const shouldUsePoster = !slide?.image?.src && !!slide?.movie?.poster;

  const Image = (
    <AspectRatio
      ratio={config.imageRatio}
      sx={sxStyles.slideImageWrapperContent}
    >
      {shouldUsePoster ? (
        <div sx={sxStyles.slidePosterWrapper}>
          <CDNImage
            src={slide?.movie?.poster}
            alt={slide?.image?.alt || ""}
            fit={"cover"}
            width={config.imageWidth}
            sx={sxStyles.slidePoster}
          />
        </div>
      ) : null}
      <div
        sx={{
          height: "100%",
          paddingTop: shouldUsePoster
            ? responsive({ xs: 0, md: 0, xl: 5 })
            : undefined,
          paddingBottom: shouldUsePoster
            ? responsive({ xs: 0, md: 0, xl: 6 })
            : undefined,
        }}
      >
        <CDNImage
          src={slide?.image?.src || slide?.movie?.poster}
          alt={slide?.image?.alt || ""}
          width={config.imageWidth}
          sx={
            shouldUsePoster
              ? sxStyles.slideImageWithPoster
              : sxStyles.slideImage
          }
        />
      </div>
    </AspectRatio>
  );

  const Caption = (
    <Flex sx={sxStyles.slideContent}>
      {/* Caption text */}
      {(slide?.caption || slide?.subCaption) && (
        <div sx={sxStyles.slideCaptionWrapper}>
          <div sx={sxStyles.slideCaptionContent}>
            <div sx={sxStyles.slideCaptionBackdrop} />
            {slide.subCaption && (
              <div sx={sxStyles.slideCaptionSubCaption}>{slide.subCaption}</div>
            )}
            {slide.caption && (
              <div sx={sxStyles.slideCaptionText}>{slide.caption}</div>
            )}
          </div>
        </div>
      )}
      {/* Caption buttons */}
      <div
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Flex>
          {hasTrailer && (
            <Button
              sx={{
                borderRadius: "medium",
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              onClick={() => {
                onShowTrailer();
              }}
            >
              {formatMessage(movieSlideMessages.trailer)}
              <Icon
                path={mdiPlay}
                sx={{
                  height: "1.5em",
                  width: "1.5em",
                  marginLeft: 1,
                }}
              />
            </Button>
          )}
          <Link
            sx={{
              variant: "buttons.secondary",
              borderRadius: "medium",
              borderTopLeftRadius: hasTrailer ? 0 : undefined,
              borderBottomLeftRadius: hasTrailer ? 0 : undefined,
              backgroundColor: "background",
            }}
            to={slide?.movie?.path || "/"}
          >
            {formatMessage(movieSlideMessages.tickets)}
            <Icon
              path={mdiTicket}
              sx={{
                height: "1.5em",
                width: "1.5em",
                transform: "rotate(-45deg)",
                marginLeft: 2,
              }}
            />
          </Link>
        </Flex>
      </div>
    </Flex>
  );

  return (
    <React.Fragment>
      <div sx={sxStyles.slideWrapper}>
        {Image}
        {Caption}
      </div>
      {createModalPortal(TrailerComponent)}
    </React.Fragment>
  );
};

export default React.memo(MovieSlide);
