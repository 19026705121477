import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  usernameField: {
    id: "loyalty.forgot-password.field.email-label",
    defaultMessage: "Email",
  },
  submit: {
    id: "loyalty.forgot-password.field.submit-label",
    defaultMessage: "Reset password",
  },
  goBack: {
    id: "loyalty.forgot-password.field.go-back-label",
    defaultMessage: "Go back to login",
  },
  success: {
    id: "loyalty.forgot-password.success-title",
    defaultMessage: "Check your inbox and follow the reset password steps",
  },
  genericError: {
    id: "loyalty.forgot-password.generic-error-message",
    defaultMessage: "Error sending reset password email",
  },
});

export default messages;
