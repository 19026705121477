/** @jsx jsx */
import React, { memo } from "react";
import { Container, jsx } from "theme-ui";

export type Props = {
  withContainer?: boolean;
  children: React.ReactNode;
};

const WarningMessageWrapper: React.FC<Props> = ({
  children,
  withContainer = false,
}) => {
  if (withContainer) {
    return <Container>{children}</Container>;
  }
  return <div>{children}</div>;
};

export default memo(WarningMessageWrapper);
