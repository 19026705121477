/** @jsx jsx */
import { Item } from "gatsby-theme-main-menu";
import React, { memo } from "react";
import Link from "shared/components/Link";
import ThemeProvider from "shared/components/ThemeProvider";
import { ANIMATION_DURATION } from "shared/constants";
import { getMostVisible } from "shared/helpers/colors";
import { Grid, jsx } from "theme-ui";

export type Props = {
  item: Item | null;
};

const Dropdown: React.FC<Props> = ({ item }) => {
  return (
    <ThemeProvider
      colors={(rawColors) => ({
        background: rawColors?.muted,
      })}
    >
      <div
        sx={{
          backgroundColor: "background",
          color: "text",
          borderRadius: "medium",
          boxShadow:
            "0 1.5rem 2.5rem 0.25rem rgb(0 0 0 / 14%), 0 0.5rem 2.5rem 0.5rem rgb(0 0 0 / 12%), 0 0.625rem 1rem -0.5rem rgb(0 0 0 / 20%)",
          padding: 4,
        }}
      >
        <Grid gap={1}>
          {item?.children?.map((child) => {
            const slug = child?.url || `/${child?.page?.slug}`;

            return (
              <div
                key={child?.id}
                sx={{ marginTop: 1, "&:first-of-type": { marginTop: 0 } }}
              >
                <Link
                  to={slug}
                  partiallyActive={slug !== "/"}
                  sx={{
                    fontSize: 2,
                    display: "inline-block",
                    "&:after": {
                      content: '""',
                      borderBottomStyle: "solid",
                      borderBottomWidth: "large",
                      borderBottomColor: (theme) =>
                        getMostVisible(theme.rawColors?.background, [
                          theme.rawColors?.primary,
                        ]).toHexString(),
                      display: "block",
                      transform: "scaleX(0)",
                      transformOrigin: "center",
                      transition: `transform ${ANIMATION_DURATION}`,
                    },
                    "&:hover, &:focus, &.active": {
                      outline: "none",
                      textDecoration: "none",
                      "&:after": {
                        transform: "scaleX(1)",
                      },
                    },
                  }}
                >
                  {child?.label}
                </Link>
              </div>
            );
          })}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default memo(Dropdown);
