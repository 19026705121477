/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { useState } from "react";
import { jsx } from "theme-ui";

import CloseIcon from "../components/icons/CloseIcon";
import VideoPlayer from "../components/VideoPlayer";
import { Trailer } from "../types/trailer";

interface Props {
  title?: string | null;
  trailer?: Trailer | null;
}

const useMovieTrailer = ({
  title,
  trailer,
}: Props): [React.ReactNode, () => void, boolean] => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const MovieTrailer = isOpen ? (
    <div
      sx={{
        alignItems: "center",
        backgroundColor: "rgba(0,0,0, .85)",
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        left: 0,
        position: "fixed",
        right: 0,
        top: 0,
        zIndex: 2000,
      }}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <div
        sx={{
          position: "relative",
          width: responsive({ xs: "100vw", md: "640px" }),
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div
          sx={{
            bottom: "100%",
            color: "#fff",
            cursor: "pointer",
            position: "absolute",
            right: 0,
            fontSize: 4,
            lineHeight: "1em",
            "&:hover": {
              color: "primary",
            },
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseIcon />
        </div>
        <VideoPlayer title={title || undefined} video={trailer || undefined} />
      </div>
    </div>
  ) : null;

  return [
    MovieTrailer,
    () => {
      setIsOpen(true);
    },
    !!trailer?.SD || !!trailer?.HD,
  ];
};

export default useMovieTrailer;
