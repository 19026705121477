import {
  BOOST_TICKETING_PASSWORD_RESET_CODE_URL_PARAM,
  BOOST_TICKETING_PASSWORD_RESET_DEFAULT_CODE,
  BOOST_TICKETING_PASSWORD_RESET_EMAIL_URL_PARAM,
} from "shared/constants";

import {
  AddMembershipRequest,
  AddMembershipResponse,
  CompletePaymentRequest,
  CompletePaymentResponse,
  CreateMemberRequest,
  CreateMemberResponse,
  EditMemberRequest,
  EditMemberResponse,
  GenericResponse,
  GetMembershipListResponse,
  GiftCard,
  LoyaltyConfig,
  Member,
  MemberCredentialsRefreshRequest,
  MemberCredentialsRequest,
  MemberSearchRequest,
  MemberSearchResponse,
  Orders,
  ResetPasswordRequest,
  SetMembershipToActivateOnCompleteRequest,
  SetMembershipToActivateOnCompleteResponse,
  UsernameReminderResponse,
} from "./types";

const unifiedFetch = async <T>(
  endpoint: string,
  config: LoyaltyConfig,
  body?: string | null,
  method = "POST"
): Promise<T> => {
  try {
    const results = await fetch(endpoint, {
      body,
      method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Platform-Version": config.platformVersion,
        Authorization: config.xAuthorization,
      },
    });
    return results.json();
  } catch (e) {
    throw new Error(e.message);
  }
};

export const loginMember = async (
  config: LoyaltyConfig,
  body: MemberCredentialsRequest
): Promise<Member> => {
  try {
    const member = await unifiedFetch<Member>(
      `${config.endpoint}/Member/Login?circuitId=${config.circuitId}`,
      config,
      JSON.stringify(body)
    );
    return member;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const refreshLogin = async (
  config: LoyaltyConfig,
  body: MemberCredentialsRefreshRequest
): Promise<Member> => {
  try {
    const member = await unifiedFetch<Member>(
      `${config.endpoint}/Member/RefreshLogin?circuitId=${config.circuitId}&memberId=${body.memberId}&userSessionId=${body.userSessionId}`,
      config,
      undefined,
      "GET"
    );
    return member;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getOrderHistory = async (
  config: LoyaltyConfig,
  userSessionId: string
): Promise<Orders> => {
  try {
    const orderHistory = await unifiedFetch<Orders>(
      `${config.endpoint}/Member/v2/OrderHistory?circuitId=${config.circuitId}&userSessionId=${userSessionId}`,
      config,
      null,
      "GET"
    );
    return orderHistory;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getGiftCardBalance = async (
  config: LoyaltyConfig,
  body: GiftCard
): Promise<GiftCard> => {
  try {
    const giftCard = await unifiedFetch<GiftCard>(
      `${config.endpoint}/GiftCard/CheckGiftCardBalance?cinemaId=${config.cinemaId}`,
      config,
      JSON.stringify(body),
      "POST"
    );
    return giftCard;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createTieredMember = async (
  config: LoyaltyConfig,
  body: SetMembershipToActivateOnCompleteRequest
): Promise<CompletePaymentResponse | undefined> => {
  try {
    // Step 1: Get loyalty tiers listed as concessions
    const membershipList = await unifiedFetch<GetMembershipListResponse>(
      `${config.endpoint}/Member/GetMembershipList/${config.cinemaId}/?circuitId=${config.circuitId}`,
      config,
      undefined,
      "GET"
    );
    const item = membershipList?.listConcessionGrouping?.[0]?.items?.[0]; // Should this be in WM config?
    if (!item?.code || !item?.memberLevelId) {
      return;
    }
    item.cost = 0;
    item.quantity = 1;
    item.isLoyaltyMembershipActivation = true;

    // Step 2: Send new user data to start membership activation
    const setMembershipRequestBody: SetMembershipToActivateOnCompleteRequest = {
      ...body,
      memberLevelId: parseInt(item.memberLevelId || "1", 10),
      memberItemId: item.code,
      clubId: config.clubId,
    };
    const setMembership =
      await unifiedFetch<SetMembershipToActivateOnCompleteResponse>(
        `${config.endpoint}/Member/SetMemberToActivateOnComplete?circuitId=${config.circuitId}`,
        config,
        JSON.stringify(setMembershipRequestBody),
        "POST"
      );
    if (!setMembership?.userSessionId || setMembership.peachCode !== 0) {
      return;
    }

    // Step 3: Add new membership to correct loyalty tier
    const addMembershipRequestBody: AddMembershipRequest = {
      userSessionId: setMembership.userSessionId,
      cinemaId: config.cinemaId,
      emailAddress: body.email,
      mobile: body.phoneNumber,
      concessions: [item],
    };
    const addMembership = await unifiedFetch<AddMembershipResponse>(
      `${config.endpoint}/Member/AddMembership/${config.cinemaId}`,
      config,
      JSON.stringify(addMembershipRequestBody),
      "POST"
    );
    if (!addMembership?.orderId) {
      return;
    }

    // Step 4: Complete process
    const completePaymentRequestBody: CompletePaymentRequest = {
      QueryString: null,
      PostValues: null,
      PostValuesModel: null,
      IsNewMember: true,
      AmountPaidPaymentProvider: 0,
      OtherPayments: null,
      OrderId: parseInt(addMembership.orderId, 10),
      Customer: {
        Name: `${body.firstname} ${body.lastname}`,
        Email: body.email,
      },
    };
    const completePayment = await unifiedFetch<CompletePaymentResponse>(
      `${config.endpoint}/Member/CompletePayment/?cinemaId=${config.cinemaId}&circuitId=${config.circuitId}`,
      config,
      JSON.stringify(completePaymentRequestBody),
      "POST"
    );
    return completePayment;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const createNonTieredMember = async (
  config: LoyaltyConfig,
  body: CreateMemberRequest
): Promise<CreateMemberResponse> => {
  try {
    const createMember = await unifiedFetch<CreateMemberResponse>(
      `${config.endpoint}/Member/Create?circuitId=${config.circuitId}`,
      config,
      JSON.stringify({
        ...body,
        clubId: config.clubId,
        username: body?.email,
        phoneNumber: body?.mobileNumber,
        userSessionId: null,
        memberId: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipCode: null,
        houseSize: 0,
        maritalStatus: "",
        sendNewsletter: "False",
        wishToReceiveSMS: "False",
        contactByThirdParty: "False",
        newsletterFrequency: "-1",
        pickupCinemaId: null,
        preferredCinemaId: null,
        preferredCinemaList: [],
        preferenceList: null,
        preferredGenres: [],
        memberItemId: null,
        memberLevelId: 0,
        nationalId: null,
        updatePreferences: false,
        memberCardNumber: null,
      }),
      "POST"
    );
    return createMember;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const updateMemberDetails = async (
  config: LoyaltyConfig,
  body: EditMemberRequest
): Promise<EditMemberResponse> => {
  try {
    const editMember = await unifiedFetch<EditMemberResponse>(
      `${config.endpoint}/Member/Edit?circuitId=${config.circuitId}`,
      config,
      JSON.stringify(body),
      "POST"
    );
    return editMember;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const sendUsernameReminder = async (
  config: LoyaltyConfig,
  email: string
): Promise<UsernameReminderResponse> => {
  try {
    const usernameReminder = await unifiedFetch<UsernameReminderResponse>(
      `${config.endpoint}/Member/UsernameReminder/${
        config.circuitId
      }/${encodeURIComponent(email)}`,
      config,
      undefined,
      "GET"
    );
    return usernameReminder;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const sendResetPasswordCode = async (
  config: LoyaltyConfig,
  emailAddress: string
): Promise<GenericResponse> => {
  try {
    const resetPasswordUrl = new URL(window.location.href);
    resetPasswordUrl.searchParams.append(
      BOOST_TICKETING_PASSWORD_RESET_EMAIL_URL_PARAM,
      emailAddress
    );
    // Vista will automatically replace {0} with real code
    resetPasswordUrl.searchParams.append(
      BOOST_TICKETING_PASSWORD_RESET_CODE_URL_PARAM,
      BOOST_TICKETING_PASSWORD_RESET_DEFAULT_CODE
    );

    const getParams = {
      circuitId: config.circuitId,
      cinemaId: config.cinemaId,
      emailAddress,
      rootUrl: window.location.host,
      resetPasswordLink: resetPasswordUrl.href.replace(
        encodeURIComponent(BOOST_TICKETING_PASSWORD_RESET_DEFAULT_CODE),
        BOOST_TICKETING_PASSWORD_RESET_DEFAULT_CODE
      ),
    };

    const passwordReset = await unifiedFetch<GenericResponse>(
      `${config.endpoint}/Member/GetResetPasswordCode?${new URLSearchParams(
        getParams
      ).toString()}`,
      config,
      undefined,
      "GET"
    );

    return passwordReset;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const memberSearch = async (
  config: LoyaltyConfig,
  body: MemberSearchRequest
): Promise<MemberSearchResponse> => {
  try {
    const member = await unifiedFetch<MemberSearchResponse>(
      `${config.endpoint}/Member/MemberSearch/${config.circuitId}`,
      config,
      JSON.stringify(body),
      "POST"
    );
    return member;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const resetPassword = async (
  config: LoyaltyConfig,
  password: string
): Promise<GenericResponse> => {
  try {
    const params = new URLSearchParams(window.location.search);
    const emailAddress = params.get(
      BOOST_TICKETING_PASSWORD_RESET_EMAIL_URL_PARAM
    );
    const resetCode = params.get(BOOST_TICKETING_PASSWORD_RESET_CODE_URL_PARAM);

    const member = await memberSearch(config, {
      emailAddress: emailAddress || undefined,
    });

    const validateResetCodeResponse = await unifiedFetch<GenericResponse>(
      `${config.endpoint}/Member/ValidatePasswordResetCode?circuitId=${config.circuitId}&cinemaId=${config.cinemaId}&emailAddress=${emailAddress}&resetCode=${resetCode}`,
      config,
      undefined,
      "GET"
    );

    if (validateResetCodeResponse.statusCode !== 200) {
      return validateResetCodeResponse;
    }

    const resetPasswordRequestBody: ResetPasswordRequest = {
      membershipId: member?.memberId,
      password,
      resetCode: resetCode || undefined,
    };
    const resetPassword = await unifiedFetch<GenericResponse>(
      `${config.endpoint}/Member/ResetPassword?circuitId=${config.circuitId}&cinemaId=${config.cinemaId}`,
      config,
      JSON.stringify(resetPasswordRequestBody),
      "POST"
    );

    return resetPassword;
  } catch (e) {
    throw new Error(e.message);
  }
};
