/** @jsx jsx */
import { Masonry } from "@boxoffice/screenplay";
import React, { Fragment, memo, useEffect, useState } from "react";
import { Icon, mdiClose } from "shared/components/Icon";
import { jsx } from "theme-ui";

import { ImageMode, Pictures } from "../../types";
import Carousel from "../Carousel";
import Item from "../Item";

interface Props {
  readonly aspectRatio?: number;
  readonly displayImageMode: ImageMode;
  readonly displayImageColumns: number;
  readonly displayOptionsZoomable: boolean;
  readonly pictures?: Pictures;
}

const Gallery: React.FC<Props> = ({
  aspectRatio = 1,
  displayImageMode,
  displayImageColumns,
  displayOptionsZoomable,
  pictures,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const hideModal = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setShowModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", hideModal);
    return () => {
      window.removeEventListener("keydown", hideModal);
    };
  }, []);

  if (!pictures?.length) {
    return null;
  }

  const carouselPictures = pictures.filter(
    (picture) => picture?.src && !picture?.linkTo
  );

  return (
    <Fragment>
      <Masonry maxCols={displayImageColumns}>
        {pictures.map((picture, index) => (
          <Item
            key={`gallery-${index}`}
            picture={picture}
            aspectRatio={aspectRatio}
            displayImageMode={displayImageMode}
            displayImageColumns={displayImageColumns}
            displayOptionsZoomable={displayOptionsZoomable}
            onClick={() => {
              if (displayOptionsZoomable && !picture?.linkTo) {
                setInitialSlide(
                  carouselPictures.findIndex(
                    (modalPicture) => modalPicture?.src === picture?.src
                  )
                );
                setShowModal(true);
              }
            }}
          />
        ))}
      </Masonry>
      {showModal && (
        <div
          sx={{
            backgroundColor: "rgba(0,0,0, .85)",
            bottom: 0,
            left: 0,
            position: "fixed",
            right: 0,
            top: 0,
            zIndex: 2000,
          }}
        >
          <div
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <Carousel initial={initialSlide} pictures={carouselPictures} />
          </div>
          <div
            onClick={() => {
              setShowModal(false);
            }}
            sx={{
              position: "absolute",
              top: 2,
              right: 2,
              backgroundColor: "rgba(0,0,0,.5)",
              borderRadius: "100%",
              color: "#fff",
              cursor: "pointer",
              lineHeight: 0,
              opacity: 0.5,
              padding: 2,
              zIndex: 1,
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <Icon path={mdiClose} size={1} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default memo(Gallery);
