/** @jsx jsx */
import React, { memo } from "react";
import { jsx, Theme } from "theme-ui";

export type Props = {
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  setArrowElement: (element: HTMLDivElement | null) => void;
};

const Arrow: React.FC<Props> = ({ styles, attributes, setArrowElement }) => {
  return (
    <div
      ref={setArrowElement}
      style={styles.arrow}
      data-popper-placement={attributes?.popper?.["data-popper-placement"]}
      sx={{
        position: "absolute",
        width: 0,
        height: 0,
        borderStyle: "solid",
        "&[data-popper-placement='bottom']": {
          bottom: "100%",
          borderWidth: "0 8px 8px 8px",
          borderColor: (theme: Theme) =>
            `transparent transparent ${theme.colors?.muted} transparent`,
        },
        "&[data-popper-placement='top']": {
          top: "100%",
          borderWidth: "8px 8px 0 8px",
          borderColor: (theme: Theme) =>
            `${theme.colors?.muted} transparent transparent transparent`,
        },
      }}
    />
  );
};

export default memo(Arrow);
