import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  aboutYou: {
    id: "loyalty.signup.about-you-title",
    defaultMessage: "About You",
  },
  firstNameField: {
    id: "loyalty.signup.field.first-name-label",
    defaultMessage: "First name",
  },
  lastNameField: {
    id: "loyalty.signup.field.last-name-label",
    defaultMessage: "Last name",
  },
  genderField: {
    id: "loyalty.signup.field.gender-label",
    defaultMessage: "Gender (Optional)",
  },
  dateOfBirthField: {
    id: "loyalty.signup.field.date-of-birth-label",
    defaultMessage: "Date of birth",
  },
  contactDetails: {
    id: "loyalty.signup.contact-details-title",
    defaultMessage: "Contact Details",
  },
  emailField: {
    id: "loyalty.signup.field.email-label",
    defaultMessage: "Email",
  },
  mobileNumberField: {
    id: "loyalty.signup.field.phone-number-label",
    defaultMessage: "Phone no.",
  },
  createPassword: {
    id: "loyalty.signup.create-password-title",
    defaultMessage: "Create Password",
  },
  passwordField: {
    id: "loyalty.signup.field.password-label",
    defaultMessage: "Create password",
  },
  confirmPasswordField: {
    id: "loyalty.signup.field.confirm-password-label",
    defaultMessage: "Confirm password",
  },
  termsAndConditionsField: {
    id: "loyalty.signup.field.terms-and-conditions-label",
    defaultMessage:
      "I confirm I have read and agree to the Terms and conditions",
  },
  sendNewsletterField: {
    id: "loyalty.signup.field.send-newsletter-label",
    defaultMessage: "I agree to receive marketing emails.",
  },
  register: {
    id: "loyalty.signup.register-label",
    defaultMessage: "Sign up",
  },
  genericError: {
    id: "loyalty.signup.generic-error-message",
    defaultMessage: "Could not sign up, check your info and retry",
  },
  successTitle: {
    id: "loyalty.signup.success-title",
    defaultMessage: "Your account has been created",
  },
  successMessage: {
    id: "loyalty.signup.success-message",
    defaultMessage: "Check your inbox for further details",
  },
});

export const errorMessages = defineMessages({
  required: {
    id: "loyalty.signup.error.field-required-label",
    defaultMessage: "This field is required",
  },
  email: {
    id: "loyalty.signup.error.invalid-email-label",
    defaultMessage: "This field must be a valid email",
  },
  matchEmail: {
    id: "loyalty.signup.error.match-email-label",
    defaultMessage: "Emails must match.",
  },
  matchPassword: {
    id: "loyalty.signup.error.match-password-label",
    defaultMessage: "Passwords must match.",
  },
  mustBeChecked: {
    id: "loyalty.signup.error.must-be-checked-label",
    defaultMessage: "This field must be checked.",
  },
});

export default messages;
