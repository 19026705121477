import React from "react";
import { createIntl, IntlContext, IntlShape } from "react-intl";

export type { IntlFormatters, MessageDescriptor } from "react-intl";
export { FormattedMessage } from "react-intl";

const useIntl = (): IntlShape =>
  React.useContext(IntlContext) || createIntl({ locale: "en" });

export default useIntl;
