import cloneDeep from "lodash/cloneDeep";

type Page = {
  readonly slug?: string | null;
  readonly relatedEntity?: {
    readonly id?: string | null;
  } | null;
  readonly parentType?: "THEATER" | null;
  readonly childPages?: readonly (Page | null | undefined)[] | null;
};

type Item = {
  readonly page?: Page | null;
};

export const preparePortalMenuItem = <T extends Item>(
  item: T,
  selectedTheaterId: string
): T => {
  const preparedItem: T = cloneDeep(item);

  if (preparedItem.page?.parentType === "THEATER" && selectedTheaterId) {
    const getSelectedTheaterPageSlug = preparedItem.page.childPages?.find(
      (childPage) => childPage?.relatedEntity?.id === selectedTheaterId
    )?.slug;

    // @ts-expect-error slug is readonly
    preparedItem.page.slug = getSelectedTheaterPageSlug;
  }

  return preparedItem;
};
