import { getColor } from "@boxoffice/screenplay";
import { lighten } from "polished";
import { Theme, ThemeUIStyleObject } from "theme-ui";

const cellPadding: ThemeUIStyleObject = {
  paddingX: 2,
  paddingY: 3,
  ":first-of-type": {
    paddingLeft: 0,
  },
  ":last-of-type": {
    paddingRight: 0,
  },
};

const sxStyles: Record<
  | "wrapper"
  | "table"
  | "row"
  | "header"
  | "cell"
  | "loadingWrapper"
  | "emptyState",
  ThemeUIStyleObject
> = {
  wrapper: {
    overflowX: "auto",
  },
  table: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
  },
  row: {
    borderBottomColor: "primary",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
  },
  header: {
    fontSize: 3,
    fontWeight: "bold",
    ...cellPadding,
  },
  cell: {
    ...cellPadding,
  },
  loadingWrapper: {
    display: "flex",
    justifyContent: "center",
    paddingY: 3,
  },
  emptyState: {
    color: (theme: Theme) => lighten(0.7, getColor(theme, "text")),
    display: "flex",
    fontSize: 3,
    fontWeight: "bold",
    justifyContent: "center",
    paddingY: 3,
  },
};

export default sxStyles;
