/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import React, { memo, useEffect, useState } from "react";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { Container, jsx } from "theme-ui";

import type { WarningEmergencyEventsQuery } from "../../../__generated__/gatsby.types";
import WarningMessage from "../WarningMessage";

const EmergencyMessage: React.FC = () => {
  const [mounted, setMounted] = useState<boolean>(false);
  const data = useStaticQuery<WarningEmergencyEventsQuery>(graphql`
    query WarningEmergencyEvents {
      allEvent(filter: { emergency: { eq: true } }) {
        nodes {
          title
          id
          description
          theaters {
            id
          }
        }
      }
    }
  `);
  useEffect(() => {
    setMounted(true);
  }, []);
  const [selectedTheaterId] = useSelectedTheaterId();
  const { theaterId } = usePageContext();
  const pageTheaterId = theaterId ?? selectedTheaterId;
  const event = data?.allEvent?.nodes?.find((node) =>
    node.theaters?.find((theater) => theater?.id === pageTheaterId)
  );
  if (!mounted || !event) {
    return null;
  }

  return (
    <Container>
      <WarningMessage
        title={event.title}
        message={event?.description || undefined}
        messageTextAlign="left"
      />
    </Container>
  );
};

export default memo(EmergencyMessage);
