/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import uniq from "lodash/uniq";
import uniqueId from "lodash/uniqueId";
import React, { memo } from "react";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import { AllAttributesQuery, Maybe } from "../../../__generated__/gatsby.types";
import Attribute, { AttributeSize } from "../Attribute";
import sxStyles from "../styles";
import { filterTags } from "./helpers";

type Props = {
  forcedTheaterId?: string;
  tags: Maybe<string>[];
  centered?: boolean;
  wrappedItems?: boolean;
  size: AttributeSize;
};

const Attributes: React.FC<Props> = ({
  forcedTheaterId,
  tags,
  centered,
  wrappedItems,
  size,
}) => {
  const pageContext = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();

  const theaterId = forcedTheaterId || selectedTheaterId;

  const data = useStaticQuery<AllAttributesQuery>(graphql`
    query AllAttributes {
      allAttribute {
        nodes {
          label
          description
          tag
          combinedTags
          logo
          logoProbe {
            ratio
          }
          theater {
            id
          }
        }
      }
    }
  `);

  const theaterAttributes = data?.allAttribute?.nodes?.filter(
    (node) =>
      (pageContext.isSingleLocation || node?.theater?.id === theaterId) &&
      node?.tag
  );

  const parentTags: Maybe<string>[] = uniq(
    tags.map((tag) => {
      const parentAttribute = theaterAttributes?.find((attribute) =>
        attribute?.combinedTags?.includes(tag)
      );
      if (
        parentAttribute?.tag &&
        parentAttribute?.combinedTags?.every(
          (nestedTag) => nestedTag && tags.includes(nestedTag)
        )
      ) {
        return parentAttribute.tag;
      }
      return tag;
    })
  );

  return (
    <div
      sx={{
        ...sxStyles.attributes,
        justifyContent: centered ? "center" : undefined,
      }}
    >
      {filterTags(parentTags).map((tag) => {
        return (
          tag && (
            <Attribute
              key={uniqueId("attribute")}
              tag={tag}
              attribute={theaterAttributes.find(
                (attribute) => attribute.tag === tag
              )}
              wrapped={wrappedItems}
              size={size}
            />
          )
        );
      })}
    </div>
  );
};

export default memo(Attributes);
