import { getSrcWithParameters } from "@boxoffice/screenplay/build/components/CDNImage/CloudFlareImage/helpers";
import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { Helmet } from "react-helmet";
import usePageContext from "shared/hooks/usePageContext";

import type { SeoQuery } from "../../../__generated__/gatsby.types";

interface Props {
  description?: string;
  image?: string;
  title?: string;
  type?: "website";
  url?: string;
}

/**
 * https://loqbooq.app/blog/add-favicon-modern-browser-guide
 */
const ICONS: { rel: string; sizes: [number, number] }[] = [
  {
    rel: "icon",
    sizes: [192, 192],
  },
  {
    rel: "icon",
    sizes: [48, 48],
  },
  {
    rel: "icon",
    sizes: [32, 32],
  },
  {
    rel: "icon",
    sizes: [16, 16],
  },
  {
    rel: "apple-touch-icon",
    sizes: [180, 180],
  },
  {
    rel: "apple-touch-icon",
    sizes: [167, 167],
  },
];

const SEO: React.FC<Props> = ({
  description = "",
  image = "",
  title = "",
  type = "website",
  url = "",
}) => {
  const pageContext = usePageContext();

  const data = useStaticQuery<SeoQuery>(graphql`
    query SEO {
      settings {
        name
        description
        favicon
      }
    }
  `);

  const fullTitle = `${title ? `${title} - ` : ""}${data.settings?.name}`;
  const fullUrl = `${url ? `/${url}` : ""}`;
  const fullImage = image;
  const fullDescription = description || data.settings?.description || "";

  return (
    <Helmet defer={false}>
      <html lang={pageContext.intl.locale.short} />
      <title>{fullTitle}</title>
      <meta property="og:title" content={fullTitle} />
      <meta name="twitter:title" content={fullTitle} />

      <meta name="description" content={fullDescription} />
      <meta property="og:description" content={fullDescription} />
      <meta name="twitter:description" content={fullDescription} />

      <meta name="image" content={fullImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={fullImage} />
      <meta property="og:image" content={fullImage} />
      <meta property="og:image" content={fullImage} />

      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content={type} />

      {data.settings?.favicon &&
        ICONS.map((icon, index) => {
          const href = data.settings?.favicon
            ? getSrcWithParameters(data.settings.favicon, {
                width: icon.sizes[0],
                height: icon.sizes[1],
                fit: "contain",
                quality: 100,
              })
            : null;

          return href ? (
            <link
              key={index}
              rel={icon.rel}
              type="image/png"
              href={href}
              sizes={`${icon.sizes[0]}x${icon.sizes[1]}`}
            />
          ) : null;
        })}
    </Helmet>
  );
};

export default memo(SEO);
