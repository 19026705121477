/** @jsx jsx */
import times from "lodash/times";
import React, { memo } from "react";
import { Button, jsx } from "theme-ui";

import {
  Icon,
  mdiArrowLeft,
  mdiArrowRight,
  mdiPageFirst,
  mdiPageLast,
} from "../../Icon";
import sxStyles from "./styles";

const MAX_PAGES = 4;

type Props = {
  rowCount: number;
  pageIndex: number;
  pageSize: number;
  onChange: (nextIndex: number) => void;
};

const Pagination: React.FC<Props> = ({
  rowCount,
  pageIndex,
  pageSize,
  onChange,
}) => {
  const pageCount = pageSize ? Math.ceil(rowCount / pageSize) : 1;
  let first = MAX_PAGES
    ? Math.floor(pageIndex / (MAX_PAGES - 1)) * (MAX_PAGES - 1)
    : 0;
  const nextCount = pageCount - first - 1;
  let displayedPages = MAX_PAGES;
  if (nextCount < MAX_PAGES - 1) {
    first = first - MAX_PAGES + nextCount + 1;
    if (first <= 0) {
      first = 0;
      displayedPages = pageCount;
    }
  }

  return (
    <div sx={sxStyles.wrapper}>
      {times(displayedPages, (i) => {
        const index = first + i;
        const isActive = index === pageIndex;
        return (
          <Button
            key={`pagination-${index}`}
            onClick={() => onChange(index)}
            sx={{
              ...sxStyles.pageButton,
              ...(isActive ? sxStyles.activeButton : {}),
            }}
          >
            {index + 1}
          </Button>
        );
      })}
      <Button
        disabled={pageIndex === 0}
        onClick={() => onChange(0)}
        sx={sxStyles.controlButton}
      >
        <Icon path={mdiPageFirst} size={"2.5rem"} />
      </Button>
      <Button
        disabled={pageIndex === 0}
        onClick={() => onChange(pageIndex - 1)}
        sx={sxStyles.controlButton}
      >
        <Icon path={mdiArrowLeft} size={"2rem"} />
      </Button>
      <Button
        disabled={!pageCount || pageIndex === pageCount - 1}
        onClick={() => onChange(pageIndex + 1)}
        sx={sxStyles.controlButton}
      >
        <Icon path={mdiArrowRight} size={"2rem"} />
      </Button>
      <Button
        disabled={!pageCount || pageIndex === pageCount - 1}
        onClick={() => onChange(pageCount - 1)}
        sx={sxStyles.controlButton}
      >
        <Icon path={mdiPageLast} size={"2.5rem"} />
      </Button>
    </div>
  );
};

export default memo(Pagination);
