import { responsive } from "@boxoffice/screenplay";
import { Property } from "shared/types/css";
import { ThemeUIStyleObject } from "theme-ui";

const secondaryMenu: ThemeUIStyleObject = {
  display: "flex",
  flex: 1,
  justifyContent: "center",
  textAlign: "center",
};

const secondaryMenuWithSocialLinks: ThemeUIStyleObject = {
  ...secondaryMenu,
  justifyContent: responsive({ xs: "center", sm: "flex-end" }),
  textAlign: responsive<Property.TextAlign>({ xs: "center", sm: "right" }),
};

const sxStyles: Record<
  | "wrapper"
  | "footer"
  | "socialLinks"
  | "mobileLinks"
  | "secondaryMenu"
  | "secondaryMenuWithSocialLinks"
  | "credits",
  ThemeUIStyleObject
> = {
  wrapper: { variant: "boxes.credits" },
  footer: {
    display: responsive({ xs: "block", sm: "flex" }),
    justifyContent: "space-between",
  },
  socialLinks: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginBottom: 4,
  },
  mobileLinks: {
    flex: 1,
    marginBottom: 4,
  },
  secondaryMenu,
  secondaryMenuWithSocialLinks,
  credits: { opacity: 0.5 },
};

export default sxStyles;
