/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const MenuIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" sx={{ width: "1em", height: "1em" }}>
    <path
      fill="currentColor"
      d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
    />
  </svg>
);

export default memo(MenuIcon);
