/** @jsx jsx */
import React, { memo } from "react";
import { FALLBACK_TIME_ZONE } from "shared/constants";
import { timeString } from "shared/helpers/date/getReleaseWeekFromDateTime";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import useScheduleContext from "../../../../hooks/useScheduleContext";
import useShowtimesDates from "../../../../hooks/useShowtimesDates";
import { Theater } from "../../types";
import Calendar from "./Calendar";

export interface Props {
  setShowModal: (showModal: boolean) => void;
  theater: Theater;
  movieIds?: string[];
}

const DatePickerComponent: React.FC<Props> = ({
  setShowModal,
  theater,
  movieIds,
}) => {
  const pageContext = usePageContext();
  const { rangeSelection, setRangeSelection, ranges } = useScheduleContext();

  const { showtimesDates, maxDate, minDate } = useShowtimesDates({
    theaterId: theater.id,
    theaterTimeZone: theater.timeZone || FALLBACK_TIME_ZONE,
    showtimeWeekReleaseDay: theater.showtimeWeekReleaseDay,
    movieIds: movieIds || [],
  });

  return (
    <Calendar
      locale={pageContext.intl.locale.long}
      date={rangeSelection.range[0]}
      shouldDisableDate={(dateTime) => {
        return !showtimesDates
          .concat(ranges.TODAY[0].split("T")[0])
          .includes(dateTime?.toISODate() || "");
      }}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(dateTime) => {
        setRangeSelection({
          range: [
            `${dateTime.toISODate()}${timeString}`,
            `${dateTime.plus({ days: 1 }).toISODate()}${timeString}`,
          ],
          source: "calendar",
        });

        setShowModal(false);
      }}
    />
  );
};

export default memo(DatePickerComponent);
