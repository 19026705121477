import { responsive } from "@boxoffice/screenplay";
import configOrig from "gatsby-theme-hero-carousel/src/components/HeroCarousel/config";

const config: typeof configOrig = {
  ...configOrig,
  imageRatio: 21 / 9,
  imageWidth: responsive({
    xs: 640,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  }),
};

export default config;
