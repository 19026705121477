import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<
  "scheduleOptionsBar" | "eventTitle" | "eventLink",
  ThemeUIStyleObject
> = {
  scheduleOptionsBar: { marginBottom: 5 },
  eventTitle: { fontSize: "20px" },
  eventLink: { fontSize: "20px" },
};

export default sxStyles;
