import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  giftCardNumberField: {
    id: "loyalty.gift-card-balance-checker.field.gift-card-number-label",
    defaultMessage: "Gift card",
  },
  submit: {
    id: "loyalty.gift-card-balance-checker.field.submit-label",
    defaultMessage: "Check balance",
  },
  results: {
    id: "loyalty.gift-card-balance-checker.results-title",
    defaultMessage: "Your balance is {value}",
  },
  genericError: {
    id: "loyalty.gift-card-balance-checker.generic-error-message",
    defaultMessage: "Could not check balance, check gift card number and retry",
  },
});

export const errorMessages = defineMessages({
  required: {
    id: "loyalty.gift-card-balance-checker.error.field-required-label",
    defaultMessage: "This field is required",
  },
});

export default messages;
