/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import React, { memo, useEffect, useState } from "react";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import type { HeaderQuery, Maybe } from "../../../__generated__/gatsby.types";
import HeaderComponent from "./Component";

type SocialLinks =
  | Maybe<{
      readonly __typename?: "Links";
      readonly facebook?: Maybe<string>;
      readonly instagram?: Maybe<string>;
      readonly linkedin?: Maybe<string>;
      readonly pinterest?: Maybe<string>;
      readonly snapchat?: Maybe<string>;
      readonly twitter?: Maybe<string>;
      readonly vk?: Maybe<string>;
      readonly wechat?: Maybe<string>;
      readonly youtube?: Maybe<string>;
    }>
  | undefined;

const combineSocialLinks = (
  data: HeaderQuery,
  isSingleLocation?: boolean,
  selectedTheaterId?: string
): SocialLinks => {
  if (isSingleLocation) {
    return data.allTheater.nodes?.[0]?.practicalInfo?.links;
  }
  const theaterLinks =
    data.allTheater.nodes?.find((theater) => theater.id === selectedTheaterId)
      ?.practicalInfo?.links || {};
  return (Object.keys(theaterLinks) as (keyof typeof theaterLinks)[]).reduce(
    (links, key) => {
      if (!theaterLinks[key]) {
        return links;
      }
      return {
        ...links,
        [key]: theaterLinks[key],
      };
    },
    { ...data.settings?.circuit?.links }
  );
};

const Header: React.FC<Record<string, unknown>> = () => {
  const { templateName, isSingleLocation } = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();
  const [socialLinks, setSocialLinks] = useState<SocialLinks | undefined>(
    undefined
  );
  const data = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      allTheater {
        nodes {
          id
          practicalInfo {
            links {
              facebook
              instagram
              linkedin
              pinterest
              snapchat
              twitter
              vk
              wechat
              letterboxd
              youtube
            }
          }
        }
      }
      settings {
        name
        isSingleLocation
        logo
        logoProbe {
          ratio
        }
        circuit {
          links {
            facebook
            instagram
            linkedin
            pinterest
            snapchat
            twitter
            vk
            wechat
            letterboxd
            youtube
          }
        }
      }
    }
  `);

  useEffect(() => {
    setSocialLinks(
      combineSocialLinks(data, isSingleLocation, selectedTheaterId)
    );
  }, [data, isSingleLocation, selectedTheaterId]);

  const hasSocialLinks = !Object.values(socialLinks || {}).every(
    (link) => !link
  );
  return (
    <HeaderComponent
      logo={data.settings?.logo}
      logoProbe={data.settings?.logoProbe || undefined}
      websiteName={data.settings?.name}
      displaySecondaryHeader={
        templateName === "fincher" &&
        (!data.settings?.isSingleLocation || hasSocialLinks)
      }
      socialLinks={socialLinks}
    />
  );
};

export default memo(Header);
