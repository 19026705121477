import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  greeting: {
    id: "loyalty.welcome-message.greeting-message",
    defaultMessage: "Hi {name},",
  },
  pointsAndExpiration: {
    id: "loyalty.welcome-message.points-and-expiration-message",
    defaultMessage:
      "Your <value>{amount}</value> points expire on <value>{expiryDate}</value>.",
  },
  pointsBalance: {
    id: "loyalty.welcome-message.points-balance-message",
    defaultMessage: "You have <value>{amount}</value> points.",
  },
  cashBalance: {
    id: "loyalty.welcome-message.cash-balance-message",
    defaultMessage: "You have <value>{amount}</value>.",
  },
});

export default messages;
