/** @jsx jsx */
import React, { memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx, Themed } from "theme-ui";

import messages from "./i18n";

const EmptyState: React.FC = () => {
  const { formatMessage } = useIntl();
  const pageContext = usePageContext();

  return (
    <div sx={{ textAlign: "center" }}>
      <Themed.p
        sx={{
          fontSize: 3,
        }}
      >
        {formatMessage(
          pageContext.isSingleLocation
            ? messages.emptyState
            : messages.emptyStateMulti
        )}
      </Themed.p>
    </div>
  );
};

export default memo(EmptyState);
