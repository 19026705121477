import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { getVisibilityStatus } from "shared/helpers/event";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { Dig } from "shared/utils/ts";

import type { AllEventsQuery } from "../../../__generated__/gatsby.types";
import AllEventsComponent from "./Component";

export type Event = Exclude<
  Dig<AllEventsQuery, ["allEvent", "nodes", number]>,
  undefined
>;

const AllEvents: React.FC<Record<string, unknown>> = () => {
  const pageContext = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();

  const data = useStaticQuery<AllEventsQuery>(graphql`
    query AllEvents {
      allEvent(
        filter: { emergency: { eq: false } }
        sort: { fields: startAt, order: ASC }
      ) {
        nodes {
          id
          title
          type
          path
          shortDescription
          startAt
          endAt
          visibleEndAt
          visibleStartAt
          timeZone
          theaters {
            id
          }
          poster
        }
      }
    }
  `);

  const theaterId = pageContext.theaterId || selectedTheaterId;

  let filteredEvents: Event[] = [];

  data.allEvent.nodes.forEach((node) => {
    const timeZone = node.timeZone;

    // If no timeZone, accept the event anyway and bail
    if (!timeZone) {
      filteredEvents.push(node);
      return;
    }

    const visibilityStatus = getVisibilityStatus(
      node.visibleStartAt,
      node.visibleEndAt,
      timeZone
    );

    if (visibilityStatus.isLive) {
      filteredEvents.push(node);
    }
  });

  filteredEvents =
    !pageContext.isSingleLocation && theaterId
      ? filteredEvents?.filter((event) =>
          event?.theaters?.map((theater) => theater?.id).includes(theaterId)
        )
      : filteredEvents;

  return <AllEventsComponent events={filteredEvents} />;
};

export default memo(AllEvents);
