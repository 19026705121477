/** @jsx jsx */
import toStyle from "css-to-style";
import parse, { domToReact, HTMLReactParserOptions } from "html-react-parser";
import React, { Fragment, memo } from "react";
import { jsx, Themed, ThemeUIStyleObject } from "theme-ui";

import Link from "../Link";

interface Props {
  children: string;
}

const ParsedHTML: React.FC<Props> = ({ children }) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (!("attribs" in domNode)) {
        return;
      }

      const { style, ...attribs } = domNode.attribs || {};
      const parsedStyle = toStyle(style || "") as unknown as ThemeUIStyleObject;

      switch (domNode.name) {
        case "a":
          return (
            <Link to={attribs?.href} sx={{ ...parsedStyle }}>
              {domNode.children ? domToReact(domNode.children, options) : null}
            </Link>
          );
        case "b":
        case "strong":
          return (
            <Themed.strong {...attribs} sx={{ ...parsedStyle }}>
              {domNode.children ? domToReact(domNode.children, options) : null}
            </Themed.strong>
          );
        case "i":
        case "em":
          return (
            <Themed.em {...attribs} sx={{ ...parsedStyle }}>
              {domNode.children ? domToReact(domNode.children, options) : null}
            </Themed.em>
          );
        case "p":
          return (
            <Themed.p sx={{ ...parsedStyle }}>
              {domNode.children ? domToReact(domNode.children, options) : null}
            </Themed.p>
          );
        case "ul":
          return (
            <Themed.ul {...attribs} sx={{ ...parsedStyle }}>
              {domNode.children ? domToReact(domNode.children, options) : null}
            </Themed.ul>
          );
        case "ol":
          return (
            <Themed.ol {...attribs} sx={{ ...parsedStyle }}>
              {domNode.children ? domToReact(domNode.children, options) : null}
            </Themed.ol>
          );
        default:
          return;
      }
    },
  };

  const parsedHTML = parse(children, options);

  return <Fragment>{parsedHTML}</Fragment>;
};

export default memo(ParsedHTML);
