/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { useBreakpointIndex } from "@theme-ui/match-media";
import {
  CloseSubMenuIcon,
  MainMenuComponentProps,
  MainMenuItem,
  MainMenuWrapper,
} from "gatsby-theme-main-menu";
import React, { memo } from "react";
import { animated, useTransition } from "shared/helpers/animation";
import { getMostReadable, UIColors } from "shared/helpers/colors";
import { Property } from "shared/types/css";
import { jsx } from "theme-ui";

const MainMenuComponent: React.FC<MainMenuComponentProps> = ({
  selectedItem,
  visibleItems,
  onCloseSubMenu,
  onItemSelected,
  onVisibilityChange,
}) => {
  const breakpointIndex = useBreakpointIndex({ defaultIndex: 3 });
  const isDropdownMode = breakpointIndex > 2;

  const menu: {
    id: string;
    selectedItem: MainMenuComponentProps["selectedItem"];
    visibleItems: MainMenuComponentProps["visibleItems"];
  } = {
    id: selectedItem?.id || "",
    selectedItem,
    visibleItems,
  };

  const transitions = useTransition(menu, {
    key: menu.id,
    initial: {
      opacity: 1,
      transform: "translate3d(0,0,0)",
      position: "static",
    },
    from: {
      opacity: 0,
      transform: selectedItem?.id
        ? "translate3d(100%,0,0)"
        : "translate3d(-100%,0,0)",
      position: "static",
    },
    enter: {
      opacity: 1,
      transform: "translate3d(0%,0,0)",
      position: "static",
    },
    leave: {
      opacity: 0,
      transform: selectedItem?.id
        ? "translate3d(-100%,0,0)"
        : "translate3d(100%,0,0)",
      position: "absolute",
      bottom: 0,
    },
  });

  return (
    <div>
      <MainMenuWrapper onVisibilityChange={onVisibilityChange}>
        {transitions((style, menu) => (
          <animated.div
            // @ts-expect-error weird type
            style={style}
            sx={{
              overflow: responsive({ xs: "hidden", lg: "visible" }),
              top: responsive({
                xs: 4,
                lg: 0,
              }),
              left: responsive({
                xs: 4,
                lg: 0,
              }),
              right: responsive({
                xs: 4,
                lg: 0,
              }),
            }}
          >
            <div
              sx={{
                display: "flex",
                flexDirection: responsive<Property.FlexDirection>({
                  xs: "column",
                  lg: "row",
                }),
                gap: responsive({ xs: 2, lg: 4 }),
              }}
            >
              {/** Responsive header */}
              <div
                sx={{
                  display: "flex",
                  flexDirection: responsive<Property.FlexDirection>({
                    xs: "column",
                    lg: "row",
                  }),
                  gap: responsive({ xs: 2, lg: 4 }),
                }}
              >
                {!isDropdownMode && menu.selectedItem?.label && (
                  <div
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      display: "flex",
                      fontWeight: "bold",
                      justifyContent: "space-between",
                      fontSize: 3,
                      borderBottomStyle: "solid",
                      borderBottomWidth: "medium",
                      borderBottomColor: (theme) =>
                        getMostReadable(theme.rawColors?.muted)
                          .setAlpha(0.2)
                          .toRgbString(),
                      "&:hover": {
                        color: (theme) =>
                          getMostReadable(theme.rawColors?.muted, [
                            UIColors.lightAlt.toHexString(),
                            UIColors.darkAlt.toHexString(),
                          ]).toHexString(),
                      },
                    }}
                    onClick={() => {
                      onCloseSubMenu();
                    }}
                  >
                    <div>{menu.selectedItem.label}</div>
                    <div sx={{ fontSize: 5 }}>
                      <CloseSubMenuIcon />
                    </div>
                  </div>
                )}
                {menu.visibleItems.map((item) => {
                  return (
                    <MainMenuItem
                      key={item?.id}
                      isDropdownMode={isDropdownMode}
                      item={item}
                      onItemSelected={onItemSelected}
                    />
                  );
                })}
              </div>
            </div>
          </animated.div>
        ))}
      </MainMenuWrapper>
    </div>
  );
};

export default memo(MainMenuComponent);
