/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import { Button, Flex, Grid, Input, jsx, Label, Spinner } from "theme-ui";

import { resetPassword } from "../../../helpers";
import { LoyaltyConfig } from "../../../types";
import ErrorMessage from "../ErrorMessage";
import messages from "./i18n";

type FormShape = {
  password: string;
  confirmPassword: string;
};

interface Props {
  widgetId: string;
  loyaltySettings: LoyaltyConfig;
  handleGoBack: (message?: string) => void;
}

const ResetPassword: React.FC<Props> = ({
  widgetId,
  loyaltySettings,
  handleGoBack,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const { values, setValue, setFieldTouched, errors, touched, isValid } =
    useForm<FormShape>({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: yup.object({
        password: yup.string().required("required"),
        confirmPassword: yup
          .string()
          .required("required")
          .test({
            test: (value, context) => context.parent.password === value,
            message: "matchPassword",
          }),
      }),
    });

  const onSubmit = async () => {
    setLoading(true);
    resetPassword(loyaltySettings, values.password)
      .then((data) => {
        if (data?.peachCode === 0) {
          handleGoBack(formatMessage(messages.success));
        } else {
          handleGoBack(formatMessage(messages.genericError));
        }
      })
      .catch(() => {
        handleGoBack(formatMessage(messages.genericError));
      });
  };

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (!errors?.password && !errors?.confirmPassword) {
          await onSubmit();
        }
      }}
    >
      <Grid columns={responsive({ xs: "1fr" })}>
        <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
          <div>
            <Label htmlFor={`${widgetId}-password`}>
              {`${formatMessage(messages.passwordField)} *`}
            </Label>
            <Input
              id={`${widgetId}-password`}
              name={"password"}
              type={"password"}
              value={values.password}
              onChange={(event) => {
                setValue("password", event.target.value);
              }}
              onBlur={() => {
                setFieldTouched("password");
              }}
            />
            {touched.password && errors.password && (
              <ErrorMessage type={errors.password} />
            )}
          </div>
          <div>
            <Label htmlFor={`${widgetId}-confirm-password`}>
              {`${formatMessage(messages.confirmPasswordField)} *`}
            </Label>
            <Input
              id={`${widgetId}-confirm-password`}
              name={"confirmPassword"}
              type={"password"}
              value={values.confirmPassword}
              onChange={(event) => {
                setValue("confirmPassword", event.target.value);
              }}
              onBlur={() => {
                setFieldTouched("confirmPassword");
              }}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <ErrorMessage type={errors.confirmPassword} />
            )}
          </div>
        </Grid>

        <div>
          <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
            <div>
              <Button
                variant={"buttons.secondary"}
                disabled={!isValid || loading}
                type={"submit"}
              >
                {formatMessage(messages.submit)}
              </Button>
            </div>
            <div sx={{ marginLeft: 3 }}>
              {loading && (
                <div>
                  <Spinner size={"2rem"} />
                </div>
              )}
            </div>
          </Flex>
        </div>
        <div
          role="button"
          sx={{
            cursor: "pointer",
            textAlign: "center",
            textDecoration: "underline",
          }}
          onClick={() => {
            handleGoBack();
          }}
        >
          {formatMessage(messages.goBack)}
        </div>
      </Grid>
    </form>
  );
};

export default memo(ResetPassword);
