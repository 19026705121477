import sxStylesOrig from "gatsby-theme-event-pages/src/components/FeaturedEvents/styles";
import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: typeof sxStylesOrig & {
  itemLinkWrapper: ThemeUIStyleObject;
  itemLink: ThemeUIStyleObject;
} = {
  ...sxStylesOrig,
  itemImageWrapper: {
    overflow: "hidden",
  },
  itemLinkWrapper: { display: "flex", justifyContent: "center" },
  itemLink: {
    variant: "buttons.primary",
  },
};

export default sxStyles;
