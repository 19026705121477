import { DateTime, Interval } from "luxon";
import { timeString } from "shared/helpers/date/getReleaseWeekFromDateTime";
import { getRanges } from "shared/helpers/ranges";
import useAllocineSchedule from "shared/hooks/useAllocineSchedule";
import useTheaterShowtimesDates from "shared/hooks/useAllocineSchedule/useTheaterShowtimesDates";

type ShowtimesDates = {
  interval: Interval[] | null;
  maxDate: string;
  minDate: string;
  showtimesDates: string[];
  theaterShowtimesDates: string[];
};

type ShowtimesDatesArgs = {
  movieIds: string[];
  showtimeWeekReleaseDay: number;
  theaterId: string | null | undefined;
  theaterTimeZone: string;
};

const useShowtimesDates = ({
  movieIds,
  showtimeWeekReleaseDay,
  theaterId,
  theaterTimeZone,
}: ShowtimesDatesArgs): ShowtimesDates => {
  const ranges = getRanges(showtimeWeekReleaseDay, theaterTimeZone);

  const theaterShowtimesDates = useTheaterShowtimesDates(
    theaterId,
    ranges.ALL_FUTURE[0],
    ranges.ALL_FUTURE[1]
  );

  const { schedule } = useAllocineSchedule(
    theaterId,
    theaterTimeZone,
    movieIds,
    ranges.ALL_FUTURE[0],
    ranges.ALL_FUTURE[1]
  );

  const moviesShowtimesDates: string[] = [];

  Object.keys(schedule).forEach((movieShowtimesDates) => {
    Object.keys(schedule[movieShowtimesDates]).forEach((movieShowtimesDate) => {
      if (schedule[movieShowtimesDates][movieShowtimesDate].length) {
        moviesShowtimesDates.push(movieShowtimesDate);
      }
    });
  });

  const minDate = ranges.ALL_FUTURE[0];

  const maxDate = theaterShowtimesDates.length
    ? `${theaterShowtimesDates[theaterShowtimesDates.length - 1]}${timeString}`
    : ranges.NEXT_WEEK[1];

  const interval = Interval.fromDateTimes(
    DateTime.fromISO(minDate, { zone: "UTC" }),
    DateTime.fromISO(
      maxDate > ranges.NEXT_WEEK[1] ? maxDate : ranges.NEXT_WEEK[1],
      { zone: "UTC" }
    ).plus({ days: 1 })
  ).splitBy({ days: 1 });

  return {
    theaterShowtimesDates,
    showtimesDates: movieIds.length
      ? moviesShowtimesDates
      : theaterShowtimesDates,
    minDate,
    maxDate,
    interval,
  };
};

export default useShowtimesDates;
