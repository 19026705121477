/** @jsx jsx */
import { CDNImage, responsive } from "@boxoffice/screenplay";
import {
  FeaturedEventsItemProps,
  FeaturedEventsMessages as messages,
} from "gatsby-theme-event-pages";
import React, { memo } from "react";
import Link from "shared/components/Link";
import ThemeProvider from "shared/components/ThemeProvider";
import Truncate from "shared/components/Truncate";
import { getTinyColor } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import { AspectRatio, Grid, jsx, Themed } from "theme-ui";

import sxStyles from "../styles";

const FeaturedEventsItem: React.FC<FeaturedEventsItemProps> = ({ event }) => {
  const { formatMessage } = useIntl();

  return (
    <ThemeProvider
      colors={(rawColors) => ({
        background:
          rawColors?.background === rawColors?.highlight
            ? getTinyColor(rawColors?.highlight).tint(6).toHexString()
            : rawColors?.highlight,
      })}
    >
      <div
        sx={{
          padding: 3,
          textAlign: "center",
          background: (theme) => theme.rawColors?.background,
          color: (theme) => theme.rawColors?.text,
          borderRadius: "medium",
        }}
      >
        <Grid>
          <div
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              minHeight: "4rem",
              textAlign: "center",
            }}
          >
            <Themed.h2 sx={{ fontSize: responsive({ xs: 4, lg: 4 }) }}>
              <Truncate lines={2}>{event.title || ""}</Truncate>
            </Themed.h2>
          </div>
          <AspectRatio ratio={16 / 9} sx={sxStyles.itemImageWrapper}>
            <CDNImage
              src={event.poster}
              alt={event.title}
              width={580}
              height={Math.ceil(580 / (16 / 9))}
              fit={"cover"}
            />
          </AspectRatio>
          <div sx={{ minHeight: "3.375rem" }}>
            <Truncate lines={2}>{event.shortDescription}</Truncate>
          </div>
          <div sx={sxStyles.itemLinkWrapper}>
            <Link sx={sxStyles.itemLink} to={event.path || "/"}>
              {formatMessage(messages.readMoreLink)}
            </Link>
          </div>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default memo(FeaturedEventsItem);
