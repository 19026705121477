/** @jsx jsx */
import { FC, memo } from "react";
import { jsx } from "theme-ui";

import ArrowIcon from "../ArrowIcon";
import { COMMON_ARROW_SX } from "./ChevronLeftSquare";

const ChevronRightSquare: FC = () => {
  return (
    <div
      sx={{
        ...COMMON_ARROW_SX,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <ArrowIcon arrowType={"chevron"} direction={"next"} />
    </div>
  );
};

export default memo(ChevronRightSquare);
