/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby";
import { SecondaryMenu } from "gatsby-theme-secondary-menu";
import { SocialLinks } from "gatsby-theme-social-links";
import React, { memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import usePageContext from "shared/hooks/usePageContext";
import { jsx } from "theme-ui";

import type { FooterQuery } from "../../../__generated__/gatsby.types";
import Component from "./Component";
import messages from "./i18n";
import Wrapper from "./Wrapper";

const Footer: React.FC<Record<string, unknown>> = () => {
  const { formatMessage } = useIntl();
  const pageContext = usePageContext();

  const data = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      theater {
        practicalInfo {
          links {
            facebook
            instagram
            linkedin
            pinterest
            snapchat
            twitter
            vk
            wechat
            youtube
            letterboxd
          }
        }
      }
      settings {
        footerMobileLinks
      }
    }
  `);

  const links = data.theater?.practicalInfo?.links;

  const credits = formatMessage(messages.credits, {
    year: new Date().getFullYear(),
  });

  return (
    <Wrapper credits={credits}>
      <Component
        secondaryMenuComponent={<SecondaryMenu />}
        socialLinksComponent={
          pageContext.isSingleLocation ? (
            <div sx={{ fontSize: "1.5em" }}>
              <SocialLinks links={links} withBackground />
            </div>
          ) : null
        }
        includeMobileLinks={!!data?.settings?.footerMobileLinks}
      />
    </Wrapper>
  );
};

export default memo(Footer);
