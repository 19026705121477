/** @jsx jsx */
import { FC, memo } from "react";
import { jsx, ThemeUIStyleObject } from "theme-ui";

import { ANIMATION_DURATION } from "../../../constants";
import { getMostReadable, getTinyColor } from "../../../helpers/colors";
import ArrowIcon from "../ArrowIcon";

export const COMMON_ARROW_SX: ThemeUIStyleObject = {
  fontSize: 4,
  userSelect: "none",
  cursor: "pointer",
  padding: 2,
  borderRadius: "small",
  transition: `all ${ANIMATION_DURATION}`,
  backgroundColor: (theme) =>
    getTinyColor(theme.rawColors?.background).setAlpha(0.25).toRgbString(),
  color: (theme) => getMostReadable(theme.rawColors?.background).toHexString(),
  "&:hover": {
    backgroundColor: (theme) => theme.rawColors?.background,
  },
};

const ChevronLeftSquare: FC = () => {
  return (
    <div
      sx={{
        ...COMMON_ARROW_SX,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
    >
      <ArrowIcon arrowType={"chevron"} direction={"previous"} />
    </div>
  );
};

export default memo(ChevronLeftSquare);
