/** @jsx jsx */
import { FooterWrapperProps } from "gatsby-theme-footer";
import React, { memo } from "react";
import ThemeProvider from "shared/components/ThemeProvider";
import { getTinyColor } from "shared/helpers/colors";
import { Container, jsx } from "theme-ui";

const FooterWrapper: React.FC<FooterWrapperProps> = ({ children, credits }) => {
  return (
    <ThemeProvider
      colors={(rawColors) => ({ background: rawColors?.footerBackground })}
    >
      <footer
        sx={{
          backgroundColor: (theme) => theme.rawColors?.background,
          color: (theme) => theme.rawColors?.text,
        }}
      >
        <div sx={{ paddingY: 4 }}>
          <Container>{children}</Container>
        </div>
        <ThemeProvider
          colors={(rawColors) => {
            const bg = getTinyColor(rawColors?.footerBackground);
            return {
              background: bg.isLight()
                ? bg.shade(5).toHexString()
                : bg.tint(5).toHexString(),
            };
          }}
        >
          <div
            sx={{
              backgroundColor: "background",
              color: "text",
            }}
          >
            <Container>
              <div
                sx={{
                  textAlign: "center",
                  paddingY: 3,
                }}
              >
                {credits}
              </div>
            </Container>
          </div>
        </ThemeProvider>
      </footer>
    </ThemeProvider>
  );
};

export default memo(FooterWrapper);
