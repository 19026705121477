/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo, useEffect, useState } from "react";
import { Manager, Popper, Reference } from "shared/components/Popper";
import ThemeProvider from "shared/components/ThemeProvider";
import { getTinyColor } from "shared/helpers/colors";
import { isRangeEqual, Range, RangeKey } from "shared/helpers/ranges";
import useAnalytics from "shared/hooks/useAnalytics";
import { jsx } from "theme-ui";

import useScheduleContext from "../../../hooks/useScheduleContext";
import { Theater } from "../types";
import DatePicker from "./DatePicker";
import DatePickerTrigger from "./DatePickerTrigger";
import DateSlider from "./DateSlider";
import PeriodButton from "./PeriodButton";
import Wrapper from "./Wrapper";

export type PeriodOption = {
  label: string;
  value: RangeKey;
  range: Range;
};

export type Props = {
  options: PeriodOption[];
  theater?: Theater;
  movieIds?: string[];
  includeDatePicker?: boolean;
  includeDateSlider?: boolean;
  dateSliderSlidesPerViewScale?: Parameters<typeof responsive>[0];
  disabled?: boolean;
};

const PeriodSelectorComponent: React.FC<Props> = ({
  options,
  theater,
  movieIds,
  includeDatePicker,
  includeDateSlider,
  dateSliderSlidesPerViewScale,
  disabled,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { rangeSelection, setRangeSelection } = useScheduleContext();
  const analytics = useAnalytics();

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    window.addEventListener("click", hideModal);
    return () => {
      window.removeEventListener("click", hideModal);
    };
  });

  return (
    <Manager>
      <Wrapper>
        {includeDateSlider ? (
          <DateSlider
            theater={theater}
            movieIds={movieIds}
            dateSliderSlidesPerViewScale={dateSliderSlidesPerViewScale}
          />
        ) : null}

        {options.length > 1 &&
          options.map((option) => {
            return (
              <PeriodButton
                key={option.value}
                option={option}
                active={isRangeEqual(rangeSelection.range, option.range)}
                disabled={disabled || false}
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  if (option.range) {
                    setRangeSelection({
                      range: option.range,
                      source: "shortcut",
                    });
                    analytics.track("click", {
                      category: "Schedule",
                      label: option.value,
                    });
                  }
                }}
              />
            );
          })}

        {includeDatePicker ? (
          <Reference>
            {({ ref }) => (
              <div ref={ref} aria-label={"calendar"}>
                <DatePickerTrigger
                  disabled={disabled}
                  sx={{
                    pointerEvents: disabled ? "none" : undefined,
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowModal(!showModal);
                  }}
                />
              </div>
            )}
          </Reference>
        ) : null}
      </Wrapper>

      {showModal && theater && (
        <ThemeProvider
          colors={(rawColors) => ({
            background:
              rawColors?.background === rawColors?.muted
                ? getTinyColor(rawColors?.muted).tint(6).toHexString()
                : rawColors?.muted,
          })}
        >
          <Popper>
            {({ ref, style, placement, arrowProps }) => (
              <div
                ref={ref}
                style={style}
                data-placement={placement}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                sx={{
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <div
                  sx={{
                    overflow: "hidden",
                    borderRadius: "medium",
                    backgroundColor: "background",
                    color: "text",
                    boxShadow: "0 2rem 1rem 0 rgba(0,0,0,.15)",
                    width: 320,
                    padding: 2,
                  }}
                >
                  <DatePicker
                    setShowModal={setShowModal}
                    theater={theater}
                    movieIds={movieIds}
                  />
                </div>
                <div
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                  data-placement={placement}
                  sx={{
                    borderLeft: "0.5rem solid transparent",
                    borderRight: "0.5rem solid transparent",
                    height: 0,
                    position: "absolute",
                    width: 0,
                    "&[data-placement='bottom']": {
                      borderBottom: "0.5rem solid transparent",
                      borderBottomColor: "muted",
                      bottom: "100%",
                    },
                    "&[data-placement='top']": {
                      borderTop: "0.5rem solid transparent",
                      borderTopColor: "muted",
                      top: "100%",
                    },
                  }}
                />
              </div>
            )}
          </Popper>
        </ThemeProvider>
      )}
    </Manager>
  );
};

export default memo(PeriodSelectorComponent);
