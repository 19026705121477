import { DateTime } from "luxon";

import { WidgetShowtimesPeriodFilter } from "../../gatsby-modules/gatsby-theme-pages/__generated__/gatsby.types";
import {
  FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
  FALLBACK_TIME_ZONE,
} from "../constants";
import { getReleaseWeekFromDateTime } from "./date/getReleaseWeekFromDateTime";

export type Range = [string, string];
export type RangeKey = WidgetShowtimesPeriodFilter;

export const isRangeEqual = (
  rangeA: Range | null | undefined,
  rangeB: Range | null | undefined
): boolean => {
  if (!rangeA || !rangeB) {
    return false;
  }

  return rangeA[0] === rangeB[0] && rangeA[1] === rangeB[1];
};

export const getRanges = (
  showtimeWeekReleaseDay: number | null | undefined,
  theaterTimeZone: string | null | undefined
): Record<RangeKey, Range> => {
  const now = DateTime.local().setZone(theaterTimeZone || FALLBACK_TIME_ZONE);

  const adjustedNow = now.set({
    hour: 3,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  const todayRange: [string, string] = [
    adjustedNow.toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) || "",
    adjustedNow.plus({ days: 1 }).toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) || "",
  ];

  const tomorrowRange: [string, string] = [
    adjustedNow.plus({ days: 1 }).toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) || "",
    adjustedNow.plus({ days: 2 }).toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) || "",
  ];

  const weekRange = getReleaseWeekFromDateTime(
    now,
    showtimeWeekReleaseDay || FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
    true
  );

  const nextWeekRange = getReleaseWeekFromDateTime(
    now.plus({ weeks: 1 }),
    showtimeWeekReleaseDay || FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
    true
  );

  const allFutureRange: [string, string] = [
    todayRange[0],
    adjustedNow.plus({ years: 1 }).toISO({
      suppressMilliseconds: true,
      includeOffset: false,
    }) || "",
  ];

  return {
    TODAY: todayRange,
    TOMORROW: tomorrowRange,
    ALL_WEEK: weekRange,
    NEXT_WEEK: nextWeekRange,
    ALL_FUTURE: allFutureRange,
    INDIVIDUAL_DAYS: ["", ""],
  };
};
