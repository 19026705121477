/** @jsx jsx */
import { FC, memo, ReactNode } from "react";
import { jsx } from "theme-ui";

const Truncate: FC<{ lines?: number; children: ReactNode }> = ({
  children,
  lines,
}) => {
  return (
    <div
      sx={{
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
      }}
    >
      {children}
    </div>
  );
};

export default memo(Truncate);
