import { getMostReadable } from "shared/helpers/colors";
import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<
  "eventType" | "eventTitle" | "eventMetaData",
  ThemeUIStyleObject
> = {
  eventType: {
    fontSize: 0,
    textTransform: "uppercase",
    color: (theme) =>
      getMostReadable(theme.rawColors?.background, [
        theme.rawColors?.primary,
      ]).toHexString(),
  },
  eventTitle: { marginBottom: 1 },
  eventMetaData: { fontSize: 1, fontWeight: "normal" },
};

export default sxStyles;
