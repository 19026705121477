/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import { RangeKey } from "shared/helpers/ranges";
import { jsx } from "theme-ui";

import { useScheduleContext } from "../../../..";
import { Theater } from "../types";
import Options, { PeriodOption } from "./Component";
import messages from "./i18n";

export interface Props {
  periodFilter?: readonly (RangeKey | null)[] | null;
  theater?: Theater;
  movieIds?: string[];
  includeDatePicker?: boolean;
  includeDateSlider?: boolean;
  dateSliderSlidesPerViewScale?: Parameters<typeof responsive>[0];
  disabled?: boolean;
}

const PeriodSelector: React.FC<Props> = ({
  periodFilter,
  theater,
  movieIds,
  includeDatePicker,
  includeDateSlider,
  dateSliderSlidesPerViewScale,
  disabled,
}) => {
  const { formatMessage } = useIntl();
  const { ranges } = useScheduleContext();

  const options: PeriodOption[] = [
    {
      label: formatMessage(messages.TODAY),
      value: "TODAY",
      range: ranges.TODAY,
    },
    {
      label: formatMessage(messages.TOMORROW),
      value: "TOMORROW",
      range: ranges.TOMORROW,
    },
    {
      label: formatMessage(messages.ALL_WEEK),
      value: "ALL_WEEK",
      range: ranges.ALL_WEEK,
    },
    {
      label: formatMessage(messages.NEXT_WEEK),
      value: "NEXT_WEEK",
      range: ranges.NEXT_WEEK,
    },
  ];

  const filteredOptions: PeriodOption[] = [];

  periodFilter?.forEach((period) => {
    const option = options.find((option) => option.value === period);
    if (option) {
      filteredOptions.push(option);
    }
  });

  return (
    <Options
      options={filteredOptions}
      theater={theater}
      movieIds={movieIds}
      includeDatePicker={includeDatePicker}
      includeDateSlider={includeDateSlider}
      dateSliderSlidesPerViewScale={dateSliderSlidesPerViewScale}
      disabled={disabled}
    />
  );
};

export default memo(PeriodSelector);
