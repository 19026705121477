/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import React, { memo } from "react";
import { getVisibilityStatus } from "shared/helpers/event";
import { jsx } from "theme-ui";

import type {
  FeaturedEventsQuery,
  LayoutElementSize,
} from "../../../__generated__/gatsby.types";
import Component from "./Component";

interface Props extends Record<string, unknown> {
  theaterId?: string;
  isNested?: boolean;
  containerSize?: LayoutElementSize;
}

const FeaturedEvents: React.FC<Props> = ({
  theaterId,
  isNested,
  containerSize,
}) => {
  const data = useStaticQuery<FeaturedEventsQuery>(graphql`
    query FeaturedEvents {
      allEvent(
        filter: { emergency: { eq: false } }
        sort: { fields: startAt, order: ASC }
      ) {
        nodes {
          title
          id
          startAt
          path
          timeZone
          visibleStartAt
          visibleEndAt
          shortDescription
          poster
          theaters {
            id
          }
        }
      }
    }
  `);

  const events = data.allEvent.nodes
    .filter((event) => {
      const timeZone = event.timeZone;

      // If no timeZone, accept the event anyway and bail
      if (!timeZone) {
        return true;
      }

      if (
        theaterId &&
        !event.theaters?.map((theater) => theater?.id).includes(theaterId)
      ) {
        return false;
      }

      const visibilityStatus = getVisibilityStatus(
        event.visibleStartAt,
        event.visibleEndAt,
        timeZone
      );

      return visibilityStatus.isLive;
    })
    .slice(0, 4);

  return (
    <Component
      key={`${theaterId}${events.map((event) => event.id).join("")}`}
      events={events}
      isNested={isNested}
      containerSize={containerSize}
    />
  );
};

export default memo(FeaturedEvents);
