/** @jsx jsx */
import React, { memo } from "react";
import { jsx, Select as ThemeSelect } from "theme-ui";

import SelectArrow from "./SelectArrow";
import sxStyles from "./styles";

export interface Props {
  id: string;
  name: string;
  defaultValue?: string | number;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: () => void;
  children: React.ReactNode;
  required?: boolean;
}

const Select: React.FC<Props> = ({
  id,
  name,
  defaultValue,
  value,
  onChange,
  onBlur,
  children,
  required = true,
}) => {
  return (
    <ThemeSelect
      arrow={<SelectArrow />}
      id={id}
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      sx={sxStyles.selectMenu}
      onBlur={onBlur}
      required={required}
      value={value}
    >
      {children}
    </ThemeSelect>
  );
};

export default memo(Select);
