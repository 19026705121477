/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { CollectionSelectorOptionsComponentProps } from "gatsby-theme-schedule-options-bar";
import React, { memo } from "react";
import { ANIMATION_DURATION } from "shared/constants";
import { getMostVisible } from "shared/helpers/colors";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

const OptionComponent: React.FC<CollectionSelectorOptionsComponentProps> = ({
  option,
  onClick,
  active,
}) => {
  const { theme } = useThemeUI();

  const uiColor = getMostVisible(theme.rawColors?.background, [
    theme.rawColors?.primary,
  ]);

  return (
    <div
      key={option.value}
      role={"button"}
      sx={{
        cursor: "pointer",
        fontSize: "1em",
        textAlign: "center",
        display: responsive({ xs: "inline-flex" }),
        position: "relative",
        textDecoration: "none",
        marginTop: responsive({ xs: 2, sm: 0 }),
        "&:first-of-type": {
          marginTop: 0,
        },
        "&:after": {
          content: '""',
          position: "absolute",
          left: 0,
          right: 0,
          top: "100%",
          borderBottomStyle: "solid",
          borderBottomWidth: "large",
          borderBottomColor: uiColor.toHexString(),
          display: "block",
          transform: "scaleX(0)",
          transformOrigin: "center",
          transition: `transform ${ANIMATION_DURATION}`,
        },
        "&:hover, &:focus, &.active": {
          outline: "none",
          textDecoration: "none",
          "&:after": {
            transform: "scaleX(1)",
          },
        },
      }}
      className={active ? "active" : undefined}
      onClick={() => {
        onClick(option);
      }}
    >
      <span className={"label"}>{option.label}</span>
    </div>
  );
};

export default memo(OptionComponent);
