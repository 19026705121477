export const getTarget = (
  hostname: string,
  href: string
): string | undefined => {
  const hostnameParts = hostname.split(".");
  const rootDomain = hostnameParts.slice(hostnameParts.length - 2).join(".");
  const regex = new RegExp(`//([A-Za-z0-9-]+.)?${rootDomain}`);
  const target = regex.test(href) ? undefined : "_blank";
  return target;
};
