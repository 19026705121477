import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { LOYALTY_COOKIE_NAME, useCookies } from "shared/helpers/cookie";
import { Maybe } from "shared/utils/ts";

import { refreshLogin } from "../helpers";
import { MemberDetails } from "../types";
import useLoyaltySettings from "./useLoyaltySettings";

type Props = {
  useCachedMember: boolean;
};

const useLoyaltyMember = (
  props?: Props
): [
  Maybe<MemberDetails>,
  boolean,
  (data: Maybe<MemberDetails> | undefined) => void
] => {
  const config = useLoyaltySettings();

  const [cookies, setCookie] = useCookies([LOYALTY_COOKIE_NAME]);
  const [member, setMember] = useState<Maybe<MemberDetails>>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const cookedMember: Maybe<MemberDetails> = cookies?.loyalty || null;

  const setCookedMember = (data: Maybe<MemberDetails> | undefined) => {
    setCookie(LOYALTY_COOKIE_NAME, data, {
      expires: DateTime.local().plus({ days: 30 }).toJSDate(),
      path: "/",
    });
  };

  useEffect(() => {
    if (
      !props?.useCachedMember &&
      cookedMember?.memberId &&
      cookedMember?.userSessionId &&
      config
    ) {
      // We can fetch data
      setLoading(true);
      refreshLogin(config, {
        memberId: cookedMember.memberId,
        userSessionId: cookedMember.userSessionId,
      })
        .then((response) => {
          if (
            (response?.statusCode === 0 || response?.statusCode === 200) &&
            response.memberDetails
          ) {
            // Data has been found
            setMember({
              ...response.memberDetails,
              encryptedSessionId: cookedMember?.encryptedSessionId, // not included in RefreshLogin response
            });
          }
        })
        .catch(() => {
          setMember(null);
        })
        .finally(() => {
          // Let's stop loading
          setLoading(false);
        });
    } else {
      // We can't fetch data, let's stop loading
      setMember(null);
      setLoading(false);
    }
  }, [
    cookedMember?.memberId,
    cookedMember?.userSessionId,
    config,
    props?.useCachedMember,
  ]);

  return [
    props?.useCachedMember ? cookedMember : member || null,
    loading,
    setCookedMember,
  ];
};

export default useLoyaltyMember;
