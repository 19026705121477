/** @jsx jsx */
import { CDNImage, responsive } from "@boxoffice/screenplay";
import { ClassNames } from "@emotion/react";
import React, { memo, useRef } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "shared/components/Swiper";
import ChevronLeftSquare from "shared/components/Swiper/Arrow/ChevronLeftSquare";
import ChevronRightSquare from "shared/components/Swiper/Arrow/ChevronRightSquare";
import useThemeUI from "shared/hooks/useThemeUI";
import { css as toEmotionCSS, jsx } from "theme-ui";

import { Picture } from "../../types";

interface Props {
  pictures: Picture[];
  initial: number;
}

const GalleryCarousel: React.FC<Props> = ({ pictures, initial }) => {
  const swiperInstanceRef = useRef<SwiperClass | null>(null);
  const { theme } = useThemeUI();

  if (!pictures?.length) {
    return null;
  }

  return (
    <div sx={{ position: "relative", height: "100%", width: "100%" }}>
      <ClassNames>
        {({ css }) => {
          const swiperClassName = css(
            toEmotionCSS({
              height: "100%",
            })(theme)
          );

          const swiperSlideClassName = css(
            toEmotionCSS({
              height: "100%",
            })(theme)
          );

          return (
            <Swiper
              onInit={(instance) => {
                swiperInstanceRef.current = instance || null;
              }}
              loop={pictures.length > 1}
              slidesPerView={1}
              freeMode
              grabCursor
              initialSlide={initial}
              className={swiperClassName}
            >
              {pictures.map((picture, index) => (
                <SwiperSlide
                  key={`gallery-carousel-${index}`}
                  className={swiperSlideClassName}
                >
                  <CDNImage
                    src={picture?.src}
                    alt={picture?.alt || ""}
                    fit="contain"
                    width={responsive({
                      xs: 640,
                      sm: 640,
                      md: 768,
                      lg: 1024,
                      xl: 1280,
                      xxl: 1536,
                    })}
                    sx={{
                      objectFit: "contain",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </SwiperSlide>
              ))}
              {pictures.length > 1 && (
                <div
                  sx={{
                    position: "absolute",
                    left: 0,
                    zIndex: 1,
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: 6,
                    userSelect: "none",
                  }}
                  onClick={() => {
                    swiperInstanceRef.current?.slidePrev?.();
                  }}
                >
                  <ChevronLeftSquare />
                </div>
              )}
              {pictures.length > 1 && (
                <div
                  sx={{
                    position: "absolute",
                    right: 0,
                    zIndex: 1,
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: 6,
                    userSelect: "none",
                  }}
                  onClick={() => {
                    swiperInstanceRef.current?.slideNext?.();
                  }}
                >
                  <ChevronRightSquare />
                </div>
              )}
            </Swiper>
          );
        }}
      </ClassNames>
    </div>
  );
};

export default memo(GalleryCarousel);
