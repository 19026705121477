import { findNearest } from "geolib";
import { GeolibInputCoordinates } from "geolib/es/types";
import { Dig } from "shared/utils/ts";

import type { TheaterSelectorQuery } from "../../../__generated__/gatsby.types";

export type Coordinates = {
  latitude: number;
  longitude: number;
};

type Theater = Dig<TheaterSelectorQuery, ["allTheater", "nodes", number]>;

export const getNearestTheater = (
  userCoords: GeolocationCoordinates,
  theaters: readonly Theater[]
): Theater | undefined => {
  const coordsList: GeolibInputCoordinates[] = theaters?.reduce(
    (acc, theater) => {
      const coords = theater?.practicalInfo?.coordinates;

      if (coords?.latitude && coords?.longitude) {
        acc.push({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      }
      return acc;
    },
    [] as GeolibInputCoordinates[]
  );

  const nearestCoords: GeolibInputCoordinates = findNearest(
    {
      latitude: userCoords.latitude,
      longitude: userCoords.longitude,
    },
    coordsList
  );

  if ("latitude" in nearestCoords && "longitude" in nearestCoords) {
    const nearestTheater = theaters?.find((theater) => {
      const coords = theater?.practicalInfo?.coordinates;
      return (
        coords?.latitude === nearestCoords?.latitude &&
        coords?.longitude === nearestCoords?.longitude
      );
    });

    return nearestTheater;
  }

  return undefined;
};
