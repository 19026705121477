/** @jsx jsx */
import { useBreakpointIndex } from "@theme-ui/match-media";
import { graphql, useStaticQuery } from "gatsby";
import React, { memo, useState } from "react";
import { preparePortalMenuItem } from "shared/helpers/menu/portalItem";
import useAnalytics from "shared/hooks/useAnalytics";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import type { MainMenuQuery } from "../../../__generated__/gatsby.types";
import Component from "./Component";
import { Item } from "./types";

const MainMenuComponent: React.FC = () => {
  const data = useStaticQuery<MainMenuQuery>(graphql`
    query MainMenu {
      menu(position: { eq: HEADER }) {
        items {
          id
          label
          url
          page {
            slug
            parentType
            childPages {
              slug
              relatedEntity {
                id
              }
            }
          }
          children {
            id
            label
            url
            page {
              slug
              parentType
              childPages {
                slug
                relatedEntity {
                  id
                }
              }
            }
          }
        }
      }
    }
  `);

  const [selectedTheaterId] = useSelectedTheaterId();
  const analytics = useAnalytics();
  const breakpointIndex: number = useBreakpointIndex({ defaultIndex: 3 });
  const isDropdownMode = breakpointIndex > 2;

  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  const preparedItems: Item[] = [];
  data.menu?.items?.forEach((item) => {
    if (item) {
      const nextItem = { ...item };
      if (nextItem.children) {
        nextItem.children = nextItem.children.map((child) => {
          return child ? preparePortalMenuItem(child, selectedTheaterId) : null;
        });
      }
      preparedItems.push(preparePortalMenuItem(nextItem, selectedTheaterId));
    }
  });

  const selectedChildItems: Item[] = [];
  selectedItem?.children?.forEach((item) => {
    if (item) {
      selectedChildItems.push(item);
    }
  });

  const visibleItems: Item[] =
    !isDropdownMode && selectedItem && selectedChildItems.length
      ? selectedChildItems
      : preparedItems;

  return (
    <Component
      selectedItem={selectedItem}
      visibleItems={visibleItems}
      onCloseSubMenu={() => {
        setSelectedItem(null);
      }}
      onItemSelected={(item) => {
        setSelectedItem(item);
      }}
      onVisibilityChange={(isOpen) => {
        analytics.track("click", {
          category: "Navigation",
          label: isOpen ? "close main menu" : "open main menu",
        });
      }}
    />
  );
};

export default memo(MainMenuComponent);
