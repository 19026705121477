/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const PlayIcon: React.FC<Record<string, unknown>> = () => (
  <svg viewBox="0 0 24 24" sx={{ width: "24px", height: "24px" }}>
    <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
  </svg>
);

export default memo(PlayIcon);
