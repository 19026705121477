import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  usernameField: {
    id: "loyalty.forgot-username.field.email-label",
    defaultMessage: "Email",
  },
  submit: {
    id: "loyalty.forgot-username.field.submit-label",
    defaultMessage: "Send me my username",
  },
  goBack: {
    id: "loyalty.forgot-username.field.go-back-label",
    defaultMessage: "Go back to login",
  },
  success: {
    id: "loyalty.forgot-username.success-title",
    defaultMessage: "Check your inbox for your username reminder",
  },
  genericError: {
    id: "loyalty.forgot-username.generic-error-message",
    defaultMessage: "Error sending username reminder email",
  },
});

export default messages;
