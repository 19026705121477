/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { ClassNames } from "@emotion/react";
import {
  EmptyState,
  FeaturedEventsWrapperProps,
} from "gatsby-theme-event-pages";
import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "shared/components/Swiper";
import ChevronLeftSquare from "shared/components/Swiper/Arrow/ChevronLeftSquare";
import ChevronRightSquare from "shared/components/Swiper/Arrow/ChevronRightSquare";
import useThemeUI from "shared/hooks/useThemeUI";
import { css as toEmotionCSS, jsx, ThemeUIStyleObject } from "theme-ui";

const COMMON_ARROW_SX: ThemeUIStyleObject = {
  position: "absolute",
  zIndex: 1,
  top: "50%",
  transform: "translateY(-50%)",
  fontSize: responsive({ xs: 5, md: 6 }),
  userSelect: "none",
  cursor: "pointer",
};

const FeaturedEventsWrapper: React.FC<FeaturedEventsWrapperProps> = ({
  children,
  showEmptyState,
  isNested,
  containerSize,
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const { theme } = useThemeUI();
  const swiperInstanceRef = useRef<SwiperClass | null>(null);

  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div>
      {showEmptyState ? (
        <EmptyState />
      ) : (
        <ClassNames>
          {({ css }) => {
            const swiperClassName = css(
              toEmotionCSS({
                maxWidth: responsive({
                  xs: "270px",
                  sm: "320px",
                  md: "420px",
                }),
              })(theme)
            );

            return (
              <div sx={{ position: "relative" }}>
                <Swiper
                  onInit={(instance) => {
                    swiperInstanceRef.current = instance || null;
                  }}
                  slidesPerView={"auto"}
                  freeMode
                  grabCursor
                  spaceBetween={32}
                  slidesOffsetBefore={
                    !isNested || containerSize === "FULL" ? 32 : 0
                  }
                  slidesOffsetAfter={
                    !isNested || containerSize === "FULL" ? 32 : 0
                  }
                  centerInsufficientSlides
                >
                  {childrenArray.map((child, index) => {
                    return (
                      <SwiperSlide key={index} className={swiperClassName}>
                        {child}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {isNested && containerSize !== "FULL" && (
                  <Fragment>
                    {childrenArray.length > 1 && (
                      <div
                        sx={{
                          ...COMMON_ARROW_SX,
                          left: 0,
                        }}
                        onClick={() => {
                          swiperInstanceRef.current?.slidePrev?.();
                        }}
                      >
                        <ChevronLeftSquare />
                      </div>
                    )}
                    {childrenArray.length > 1 && (
                      <div
                        sx={{
                          ...COMMON_ARROW_SX,
                          right: 0,
                        }}
                        onClick={() => {
                          swiperInstanceRef.current?.slideNext?.();
                        }}
                      >
                        <ChevronRightSquare />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            );
          }}
        </ClassNames>
      )}
    </div>
  );
};

export default memo(FeaturedEventsWrapper);
