import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  startAt: {
    id: "events.event.start-at-label",
    defaultMessage: "From: {date}",
  },
  endAt: {
    id: "events.event.end-at-label",
    defaultMessage: "To: {date}",
  },
  sameDayPeriod: {
    id: "events.event.same-day-period-label",
    defaultMessage: "{date}, from {startTime} to {endTime}",
  },
});

export default messages;
