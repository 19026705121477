/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { CollectionSelectorOptionsWrapperProps } from "gatsby-theme-schedule-options-bar";
import React, { Children, Fragment, memo } from "react";
import { getTinyColor } from "shared/helpers/colors";
import { Property } from "shared/types/css";
import { jsx } from "theme-ui";

const Wrapper: React.FC<CollectionSelectorOptionsWrapperProps> = ({
  children,
}) => {
  const childrenArray = Children.toArray(children);

  return (
    <div
      sx={{
        display: "flex",
        flexDirection: responsive<Property.FlexDirection>({
          xs: "column",
          sm: "row",
        }),
        alignItems: "center",
        justifyContent: "center",
        fontSize: responsive({ xs: 3, md: 4 }),
        whiteSpace: "nowrap",
      }}
    >
      {childrenArray.map((child, index) => (
        <Fragment key={index}>
          {child}
          {index < childrenArray.length - 1 && (
            <div
              sx={{
                display: responsive({ xs: "none", sm: "block" }),
                width: "1px",
                backgroundColor: (theme) => {
                  const bg = getTinyColor(theme.rawColors?.background);
                  return bg.isLight()
                    ? bg.shade(20).toHexString()
                    : bg.tint(20).toHexString();
                },
                minHeight: "1em",
                marginX: responsive({ xs: 3, lg: 4 }),
              }}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default memo(Wrapper);
