/** @jsx jsx */
import type { Props } from "gatsby-theme-pages/src/components/Widget/Section/Wrapper/Component";
import React, { Fragment, memo } from "react";
import { Container, jsx } from "theme-ui";

const SectionWidgetContainer: React.FC<Props> = ({
  wrapperSize,
  containerSize,
  children,
  className,
}) => {
  if (wrapperSize === "FULL") {
    switch (containerSize) {
      case "FULL": {
        return <div className={className}>{children}</div>;
      }
      case "STANDARD":
      default: {
        return (
          <div className={className}>
            <Container>{children}</Container>
          </div>
        );
      }
      case "NARROW": {
        return (
          <div className={className}>
            <Container>
              <div sx={{ maxWidth: "768px", margin: "0 auto" }}>{children}</div>
            </Container>
          </div>
        );
      }
    }
  }

  if (wrapperSize === "STANDARD") {
    switch (containerSize) {
      case "FULL":
      case "STANDARD":
      default: {
        return (
          <Container>
            <div className={className}>{children}</div>
          </Container>
        );
      }
      case "NARROW": {
        return (
          <Container>
            <div className={className}>
              <div sx={{ maxWidth: "768px", margin: "0 auto" }}>{children}</div>
            </div>
          </Container>
        );
      }
    }
  }

  if (wrapperSize === "NARROW") {
    switch (containerSize) {
      case "FULL":
      case "STANDARD":
      default:
      case "NARROW": {
        return (
          <Container>
            <div sx={{ maxWidth: "768px", margin: "0 auto" }}>
              <div className={className}>{children}</div>
            </div>
          </Container>
        );
      }
    }
  }

  return <Fragment>{children}</Fragment>;
};

export default memo(SectionWidgetContainer);
