/** @jsx jsx */
import { Carousel, responsive } from "@boxoffice/screenplay";
import { SliderInstance } from "@boxoffice/screenplay/build/components/Carousel";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { DateTime } from "luxon";
import React, { memo, useEffect, useRef } from "react";
import {
  FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
  FALLBACK_TIME_ZONE,
} from "shared/constants";
import { timeString } from "shared/helpers/date/getReleaseWeekFromDateTime";
import { jsx } from "theme-ui";

import useScheduleContext from "../../../../hooks/useScheduleContext";
import useShowtimesDates from "../../../../hooks/useShowtimesDates";
import { Theater } from "../../types";
import Item from "./Item";
import Wrapper from "./Wrapper";

export type Props = {
  theater?: Theater | null;
  movieIds?: string[];
  dateSliderSlidesPerViewScale?: Parameters<typeof responsive>[0];
};

const PeriodSelectorComponent: React.FC<Props> = ({
  theater,
  movieIds,
  dateSliderSlidesPerViewScale,
}) => {
  const breakPointIndex = useBreakpointIndex();
  const { rangeSelection, setRangeSelection } = useScheduleContext();

  const { showtimesDates, interval } = useShowtimesDates({
    theaterId: theater?.id,
    theaterTimeZone: theater?.timeZone || FALLBACK_TIME_ZONE,
    showtimeWeekReleaseDay:
      theater?.showtimeWeekReleaseDay || FALLBACK_SHOWTIME_WEEK_RELEASE_DAY,
    movieIds: movieIds || [],
  });

  const sliderInstance = useRef<SliderInstance | null>(null);

  useEffect(() => {
    if (rangeSelection.source !== "slider") {
      const dateIndex = interval?.findIndex((intervalSegment) => {
        return (
          `${intervalSegment.start.toISODate()}${timeString}` ===
          rangeSelection.range[0]
        );
      });
      if (typeof dateIndex === "number") {
        sliderInstance.current?.moveToSlide(dateIndex);
      }
    }
  }, [rangeSelection]);

  const now = theater?.timeZone
    ? DateTime.local().setZone(theater.timeZone).toISODate()
    : null;

  const slidesPerViewScale = responsive(
    dateSliderSlidesPerViewScale || {
      xs: 2,
      sm: 2,
      md: 5,
      lg: 7,
      xl: 7,
      xxl: 7,
    }
  );

  const slidesPerView = (slidesPerViewScale[breakPointIndex] ??
    slidesPerViewScale[slidesPerViewScale.length - 1]) as number;

  const carouselKey = `${interval?.[0].start.toISO()}${interval?.[
    interval.length - 1
  ].end.toISO()}`;

  return (
    <Wrapper>
      <div sx={{ position: "absolute", left: 0, top: 0, right: 0 }}>
        <Carousel
          key={carouselKey}
          ref={sliderInstance}
          slidesPerView={slidesPerView}
          centered={false}
          arrowType={"menu"}
          arrowPosition={"outside"}
          spacing={0}
          mode={"free"}
        >
          {interval?.map((intervalSegment) => {
            const dateTime = intervalSegment.start;
            const ISODate = dateTime.toISODate();
            const isActive =
              showtimesDates.includes(ISODate) || ISODate === now;
            const isSelected =
              rangeSelection.range[0] === `${ISODate}${timeString}`;

            const setSelection = () => {
              setRangeSelection({
                range: [
                  `${ISODate}${timeString}`,
                  `${dateTime.plus({ days: 1 }).toISODate()}${timeString}`,
                ],
                source: "slider",
              });
            };

            return (
              <div
                key={ISODate}
                tabIndex={0}
                sx={{
                  pointerEvents: !isActive ? "none" : undefined,
                  opacity: !isActive ? 0.15 : 1,
                  "&:hover, &:focus": {
                    outline: "none",
                  },
                }}
                onClick={() => {
                  setSelection();
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setSelection();
                  }
                }}
              >
                <Item
                  dateTime={dateTime}
                  isActive={isActive}
                  isSelected={isSelected}
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </Wrapper>
  );
};

export default memo(PeriodSelectorComponent);
