/** @jsx jsx */
import React, { memo } from "react";
import { getMostVisible } from "shared/helpers/colors";
import { jsx } from "theme-ui";

type Props = {
  label?: string;
  children: React.ReactNode;
};

const ItemGroup: React.FC<Props> = ({ label, children }) => {
  return (
    <div
      sx={{
        borderBottomColor: (theme) =>
          getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.primary,
          ]).toHexString(),
        borderBottomStyle: "solid",
        borderBottomWidth: "small",
        paddingY: 3,
        ":first-of-type": {
          paddingTop: 0,
        },
        ":last-of-type": {
          borderBottom: "none",
          paddingBottom: 1,
        },
      }}
    >
      {!!label && (
        <div
          sx={{
            fontSize: 2,
            fontWeight: "bold",
            paddingLeft: 3,
            textTransform: "uppercase",
          }}
        >
          {label}
        </div>
      )}
      {children}
    </div>
  );
};

export default memo(ItemGroup);
