import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  nowPlaying: {
    id: "schedule.toolbar.now-playing-label",
    defaultMessage: "Now playing",
    description: `Tab option to display "now playing" movies`,
  },
  comingSoon: {
    id: "schedule.toolbar.coming-soon-label",
    defaultMessage: "Coming soon",
    description: `Tab option to display "coming soon" movies`,
  },
});

export default messages;
