/** @jsx jsx */
import { FC, memo } from "react";
import { jsx } from "theme-ui";

const ChevronLeft: FC = () => {
  return (
    <svg
      width="12px"
      height="22px"
      viewBox="0 0 12 22"
      sx={{ height: "1em", width: "1em", display: "block" }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-12.000000, -289.000000)" fill="currentColor">
          <g transform="translate(0.000000, 280.000000)">
            <g transform="translate(4.000000, 6.000000)">
              <path
                d="M8.74975,24.49975 C8.55775,24.49975 8.36575,24.42675 8.21975,24.27975 C7.92675,23.98675 7.92675,23.51275 8.21975,23.21975 L17.68875,13.74975 L8.21975,4.27975 C7.92675,3.98675 7.92675,3.51275 8.21975,3.21975 C8.51275,2.92675 8.98675,2.92675 9.27975,3.21975 L19.27975,13.21975 C19.57275,13.51275 19.57275,13.98675 19.27975,14.27975 L9.27975,24.27975 C9.13375,24.42675 8.94175,24.49975 8.74975,24.49975"
                transform="translate(13.749750, 13.749875) rotate(-180.000000) translate(-13.749750, -13.749875) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(ChevronLeft);
