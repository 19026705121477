/** @jsx jsx */
import type { DateSliderWrapperProps } from "gatsby-theme-schedule-options-bar";
import React, { memo } from "react";
import { jsx } from "theme-ui";

const DateSliderWrapper: React.FC<DateSliderWrapperProps> = ({ children }) => {
  return (
    <div
      sx={{
        position: "relative",
        flex: 10,
        alignSelf: "stretch",
        minHeight: "60px",
      }}
    >
      {children}
    </div>
  );
};

export default memo(DateSliderWrapper);
