import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  readMoreLink: {
    id: "events.read-more-link",
    defaultMessage: "Read",
  },
});

export default messages;
