import { DateTime } from "luxon";

const weekDays: string[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

const cinemaDayHourOffset = 3;
export const timeString = `T0${cinemaDayHourOffset}:00:00`;

export const getReleaseWeekFromDateTime = (
  dateTime: DateTime,
  releaseWeekStart: number,
  strictCinemaDay = true // Should the output be precise to the hour and follow cinema day rules
): [string, string] => {
  const date = dateTime.minus({
    hours: strictCinemaDay ? cinemaDayHourOffset : 0,
  });
  const weekDay = date
    .setLocale("en-US")
    .toLocaleString({ weekday: "long" })
    .toLowerCase();
  const day = weekDays.indexOf(weekDay);
  const daysFromReleaseWeekStart = day - releaseWeekStart;

  const start =
    daysFromReleaseWeekStart >= 0
      ? date.minus({ days: daysFromReleaseWeekStart })
      : date.minus({ days: daysFromReleaseWeekStart + 7 });

  const end = start.plus({ days: strictCinemaDay ? 7 : 6 });

  return [
    `${start.toISODate()}${strictCinemaDay ? timeString : ""}`,
    `${end.toISODate()}${strictCinemaDay ? timeString : ""}`,
  ];
};
