/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import { Stack } from "@boxoffice/screenplay";
import React, { memo } from "react";
import Link from "shared/components/Link";
import { jsx } from "theme-ui";

import type { SecondaryMenuQuery } from "../../../__generated__/gatsby.types";
import sxStyles from "./styles";

export type Item = Exclude<
  Exclude<
    Exclude<SecondaryMenuQuery["menu"], null | undefined>["items"],
    null | undefined
  >[number],
  null | undefined
> & {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export interface Props {
  items: Item[];
}

const SecondaryMenu: React.FC<Props> = ({ items }) => {
  return (
    <Stack space={0}>
      {items?.map((item) => {
        const slug = item?.url || `/${item?.page?.slug}`;

        return (
          <Link
            key={item?.label || ""}
            to={slug}
            partiallyActive={slug !== "/"}
            sx={sxStyles.link}
            onClick={item.onClick}
          >
            {item?.label || "—"}
          </Link>
        );
      })}
    </Stack>
  );
};

export default memo(SecondaryMenu);
