import { DateTime, DateTimeFormatPreset } from "luxon";

export const isoStringToDateTime = (
  isoString: string,
  timeZone: string | undefined | null
): DateTime => {
  let dateTime = DateTime.fromISO(isoString, { zone: "UTC" });

  if (timeZone) {
    dateTime = dateTime.setZone(timeZone, { keepLocalTime: true });
  }

  return dateTime;
};

export const localizeDateTime = (
  dateTime: DateTime,
  locale: string,
  format: "DATE" | "TIME" | "DATE_TIME" = "DATE_TIME"
): string | undefined => {
  let formatOptions: DateTimeFormatPreset;

  switch (format) {
    case "DATE_TIME":
      formatOptions = {
        ...DateTime.DATETIME_MED,
        weekday: "long",
        month: "long",
      };
      break;
    case "DATE":
      formatOptions = { ...DateTime.DATE_MED, weekday: "long", month: "long" };
      break;
    default:
      formatOptions = { ...DateTime.TIME_SIMPLE };
      break;
  }

  const localizedDateTime = dateTime
    .setLocale(locale)
    .toLocaleString(formatOptions);

  return localizedDateTime || undefined;
};
