/** @jsx jsx */
import { Heading, responsive } from "@boxoffice/screenplay";
import { DateTime } from "luxon";
import React, { memo, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  jsx,
  Label,
  Spinner,
} from "theme-ui";

import { updateMemberDetails } from "../../helpers";
import useLoyaltyMember from "../../hooks/useLoyaltyMember";
import useLoyaltySettings from "../../hooks/useLoyaltySettings";
import GenderField from "../form/GenderField";
import ErrorMessage from "./ErrorMessage";
import messages from "./i18n";

type UserProfileFormShape = {
  firstName: string;
  lastName: string;
  gender: string | undefined;
  dateOfBirth: string;
  email: string;
  mobileNumber: string;
  sendNewsletter: boolean;
};

type State = "success" | "error" | "unknown";

const UserProfile: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<State>("unknown");
  const { formatMessage } = useIntl();
  const [member] = useLoyaltyMember();

  const {
    values,
    setValue,
    setFieldTouched,
    resetValues,
    errors,
    touched,
    isValid,
  } = useForm<UserProfileFormShape>({
    initialValues: {
      firstName: member?.firstName || "",
      lastName: member?.lastName || "",
      gender: member?.gender || "",
      dateOfBirth: member?.dateOfBirth
        ? DateTime.fromISO(member.dateOfBirth).setZone("UTC").toISODate()
        : "",
      email: member?.emailAddress || "",
      mobileNumber: member?.mobileNumber || "",
      sendNewsletter: member?.sendNewsletter ?? true,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("required"),
      lastName: yup.string().required("required"),
      gender: yup.string(),
      dateOfBirth: yup.string().required("required"),
      email: yup.string().email("email").required("required"),
      mobileNumber: yup.string().required("required"),
      sendNewsletter: yup.boolean().required("required"),
    }),
  });
  const config = useLoyaltySettings();

  if (!config) {
    return null;
  }

  const onSubmit = async () => {
    setLoading(true);
    setState("unknown");
    setState("unknown");
    updateMemberDetails(config, {
      ...values,
      phoneNumber: values?.mobileNumber || "",
      sendNewsletter: values.sendNewsletter ? "True" : "False",
      memberId: member?.memberId || "",
      clubId: member?.clubId || "",
      userSessionId: member?.userSessionId || "",
      username: values.email,
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
      houseSize: 0,
      maritalStatus: "",
      wishToReceiveSMS: "False",
      contactByThirdParty: "False",
      newsletterFrequency: "-1",
      pickupCinemaId: "",
      preferredCinemaId: "",
      preferredCinemaList: [],
      preferenceList: [],
      preferredGenres: [],
      memberItemId: "",
      memberLevelId: 0,
      nationalId: "",
      updatePreferences: false,
      memberCardNumber: "",
    })
      .then((data) => {
        setLoading(false);
        if (data.peachCode === 0) {
          // Success
          setState("success");
        } else {
          setState("error");
          resetValues();
        }
      })
      .catch(() => {
        setState("error");
        resetValues();
        setLoading(false);
      });
  };

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (!Object.keys(errors).length) {
          await onSubmit();
        }
      }}
    >
      <Grid gap={4}>
        <div>
          <Heading level={2}>{formatMessage(messages.aboutYou)}</Heading>
          <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
            <div>
              <Label htmlFor={`loyalty-firstname`}>
                {`${formatMessage(messages.firstNameField)} *`}
              </Label>
              <Input
                id={`loyalty-firstname`}
                name={"firstName"}
                type={"text"}
                value={values.firstName}
                onChange={(event) => {
                  setValue("firstName", event.target.value);
                  setState("unknown");
                }}
                onBlur={() => {
                  setFieldTouched("firstName");
                }}
              />
              {touched.firstName && errors.firstName && (
                <ErrorMessage type={errors.firstName} />
              )}
            </div>
            <div>
              <Label htmlFor={`loyalty-lastname`}>
                {`${formatMessage(messages.lastNameField)}`}
              </Label>
              <Input
                id={`loyalty-lastname`}
                name={"lastName"}
                type={"text"}
                value={values.lastName}
                onChange={(event) => {
                  setValue("lastName", event.target.value);
                  setState("unknown");
                }}
                onBlur={() => {
                  setFieldTouched("lastName");
                }}
              />
              {touched.lastName && errors.lastName && (
                <ErrorMessage type={errors.lastName} />
              )}
            </div>
            <div>
              <Label htmlFor={`loyalty-gender`}>
                {`${formatMessage(messages.genderField)}`}
              </Label>
              <GenderField
                id={`loyalty-gender`}
                name="gender"
                value={values.gender?.toLocaleLowerCase() || ""}
                onChange={(event) => {
                  setValue("gender", event.target.value);
                  setState("unknown");
                }}
                onBlur={() => {
                  setFieldTouched("gender");
                }}
              />
            </div>
            <div>
              <Label>{`${formatMessage(messages.dateOfBirthField)}`}</Label>
              <Input
                id={`loyalty-date-of-birth`}
                name={"dateOfBirth"}
                type={"date"}
                value={values.dateOfBirth}
                onChange={(event) => {
                  setValue("dateOfBirth", event.target.value);
                  setState("unknown");
                }}
                onBlur={() => {
                  setFieldTouched("dateOfBirth");
                }}
              />
              {touched.dateOfBirth && errors.dateOfBirth && (
                <ErrorMessage type={errors.dateOfBirth} />
              )}
            </div>
          </Grid>
        </div>

        <div>
          <Heading level={2}>{formatMessage(messages.contactDetails)}</Heading>
          <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
            <div>
              <Label htmlFor={`loyalty-email`}>
                {`${formatMessage(messages.emailField)} *`}
              </Label>
              <Input
                id={`loyalty-email`}
                name={"email"}
                type={"text"}
                value={values.email}
                onChange={(event) => {
                  setValue("email", event.target.value);
                  setState("unknown");
                }}
                onBlur={() => {
                  setFieldTouched("email");
                }}
              />
              {touched.email && errors.email && (
                <ErrorMessage type={errors.email} />
              )}
            </div>
            <div>
              <Label htmlFor={`loyalty-phone-number`}>
                {`${formatMessage(messages.phoneNumberField)} *`}
              </Label>
              <Input
                id={`loyalty-phone-number`}
                name={"mobileNumber"}
                type={"text"}
                value={values.mobileNumber}
                onChange={(event) => {
                  setValue("mobileNumber", event.target.value);
                  setState("unknown");
                }}
                onBlur={() => {
                  setFieldTouched("mobileNumber");
                }}
              />
              {touched.mobileNumber && errors.mobileNumber && (
                <ErrorMessage type={errors.mobileNumber} />
              )}
            </div>
          </Grid>
        </div>

        <Label sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Checkbox
            id={`send-newsletter`}
            name="send-newsletter"
            type="checkbox"
            checked={values.sendNewsletter}
            onChange={(event) => {
              setValue("sendNewsletter", event.target.checked);
            }}
          />
          {`${formatMessage(messages.sendNewsletterField)}`}
        </Label>
        <Flex
          sx={{
            alignItems: "center",
            gap: 2,
          }}
        >
          <div>
            <Button
              variant={"buttons.secondary"}
              disabled={!isValid || loading}
              type={"submit"}
            >
              {formatMessage(messages.save)}
            </Button>
          </div>
          <div>
            {loading && (
              <div>
                <Spinner size={"2rem"} />
              </div>
            )}
            {state === "error" && (
              <div sx={{ fontWeight: "bold" }}>
                {formatMessage(messages.genericError)}
              </div>
            )}
            {state === "success" && (
              <div sx={{ fontWeight: "bold" }}>
                {formatMessage(messages.genericSuccess)}
              </div>
            )}
          </div>
        </Flex>
      </Grid>
    </form>
  );
};

export default memo(UserProfile);
