import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  passwordField: {
    id: "loyalty.reset-password.field.password-label",
    defaultMessage: "Password",
  },
  confirmPasswordField: {
    id: "loyalty.reset-password.field.confirm-password-label",
    defaultMessage: "Confirm Password",
  },
  submit: {
    id: "loyalty.reset-password.field.submit-label",
    defaultMessage: "Reset Password",
  },
  goBack: {
    id: "loyalty.reset-password.field.go-back-label",
    defaultMessage: "Go back to login",
  },
  success: {
    id: "loyalty.reset-password.success-title",
    defaultMessage: "Your password has been reset",
  },
  genericError: {
    id: "loyalty.reset-password.generic-error-message",
    defaultMessage: "Error resetting password",
  },
});

export default messages;
