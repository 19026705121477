import { defineMessages } from "../helpers/i18n/defineMessages";

const messages = defineMessages({
  runtime: {
    id: "movies.movie.runtime-label",
    defaultMessage: "{hours} hr {minutes} min",
  },
});

export default messages;
