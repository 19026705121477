import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  usernameField: {
    id: "loyalty.login.field.username-label",
    defaultMessage: "Email",
  },
  passwordField: {
    id: "loyalty.login.field.password-label",
    defaultMessage: "Password",
  },
  submit: {
    id: "loyalty.login.field.submit-label",
    defaultMessage: "Log in",
  },
  forgotUsername: {
    id: "loyalty.login.field.forgot-username-label",
    defaultMessage: "I forgot my username",
  },
  forgotPassword: {
    id: "loyalty.login.field.forgot-password-label",
    defaultMessage: "I forgot my password",
  },
  genericError: {
    id: "loyalty.login.generic-error-message",
    defaultMessage: "Could not log in, check your email and password and retry",
  },
});

export const errorMessages = defineMessages({
  required: {
    id: "loyalty.login.error.field-required-label",
    defaultMessage: "This field is required",
  },
  email: {
    id: "loyalty.login.error.invalid-email-label",
    defaultMessage: "This field must be a valid email",
  },
});

export default messages;
