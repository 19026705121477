/** @jsx jsx */
import { FC, Fragment, memo, ReactNode } from "react";
import { jsx } from "theme-ui";

import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";

export type Arrow = "chevron" | null;

export type Position = "inside" | "outside";

interface Props {
  direction: "previous" | "next";
  arrowType?: Arrow;
}

const ArrowIcon: FC<Props> = ({ direction, arrowType }) => {
  let components: ReactNode[] = [null, null];

  switch (arrowType) {
    default:
    case "chevron":
      components = [
        <ChevronLeft key={"previous"} />,
        <ChevronRight key={"next"} />,
      ];
      break;
  }

  return (
    <Fragment>
      {direction === "previous" ? components[0] || null : components[1] || null}
    </Fragment>
  );
};

export default memo(ArrowIcon);
