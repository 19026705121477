import { btoa } from "../../helpers/string";
import useQuery, { gql } from "../useQuery";
import {
  TheaterShowtimesDatesQuery,
  TheaterShowtimesDatesQueryVariables,
} from "./__generated__/useTheaterShowtimesDates.types";

const useTheaterShowtimesDates = (
  theaterId: string | null | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  skip = false
): string[] => {
  const b64TheaterId = theaterId ? btoa(`Theater:${theaterId}`) : "";
  const shouldPause = skip || !theaterId || !startDate || !endDate;
  const [{ data }] = useQuery<
    TheaterShowtimesDatesQuery,
    TheaterShowtimesDatesQueryVariables
  >(
    gql`
      query TheaterShowtimesDates(
        $theaterId: String!
        $from: DateTime!
        $to: DateTime!
      ) {
        theater(id: $theaterId) {
          id
          showtimesDates(from: $from, to: $to)
        }
      }
    `,
    {
      pause: shouldPause,
      variables: {
        theaterId: b64TheaterId,
        from: startDate,
        to: endDate,
      },
    }
  );

  const showtimesDates: string[] = [];

  if (shouldPause) {
    return [];
  }

  data?.theater?.showtimesDates?.forEach((showtimesDate) => {
    if (showtimesDate) {
      showtimesDates.push(showtimesDate);
    }
  });

  return showtimesDates;
};

export default useTheaterShowtimesDates;
