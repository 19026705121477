import {
  BOOST_TICKETING_LOGIN_REDIRECT_URL_PARAM,
  BOOST_TICKETING_SESSION_REDIRECT_URL_PARAM,
} from "shared/constants";

import { LoyaltyConfig } from "../../types";

export const redirectToBoostTicketing = (
  config: LoyaltyConfig,
  encryptedSessionId: string | undefined | null
): void => {
  // Get return path
  const returnPath = new URL(window.location.href).searchParams.get(
    BOOST_TICKETING_LOGIN_REDIRECT_URL_PARAM
  );

  if (config.ticketingUrl && returnPath && encryptedSessionId) {
    const returnUrl = new URL(`${config.ticketingUrl}${returnPath}`);

    returnUrl.searchParams.append(
      BOOST_TICKETING_SESSION_REDIRECT_URL_PARAM,
      encryptedSessionId
    );

    window.location.href = returnUrl.href;
  }
};
