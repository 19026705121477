import { responsive } from "@boxoffice/screenplay";
import sxStylesOrig from "gatsby-theme-hero-carousel/src/components/HeroCarousel/styles";
import { Theme, ThemeUIStyleObject } from "theme-ui";

const slideImage: ThemeUIStyleObject = {
  borderRadius: 0,
  height: "100%",
  width: "100%",
  objectFit: "cover",
};

const slideImageWithPoster: ThemeUIStyleObject = {
  ...slideImage,
  objectFit: "contain",
  position: "relative",
};

const buttonHeight = 48;

const sxStyles: typeof sxStylesOrig & {
  slideCaptionWrapper: ThemeUIStyleObject;
  slideCaptionContent: ThemeUIStyleObject;
  slideCaptionBackdrop: ThemeUIStyleObject;
  slideCaptionSubCaption: ThemeUIStyleObject;
  slideCaptionText: ThemeUIStyleObject;
  slideButtonsWrapper: ThemeUIStyleObject;
} = {
  ...sxStylesOrig,
  wrapper: {},
  slideWrapper: {
    position: "relative",
    overflow: "hidden",
  },
  slideImageWrapperContent: {
    backgroundColor: (theme) => theme.rawColors?.highlight,
    overflow: "hidden",
  },
  slidePosterWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  slidePoster: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 0.15,
    borderRadius: 0,
  },
  slideImage,
  slideImageWithPoster,
  slideContent: {
    alignItems: "center",
    flexDirection: "column",
    marginTop: responsive({ xs: (buttonHeight / 2) * -1 }),
    textAlign: "center",
    position: "relative",
  },
  slideCaptionWrapper: {
    textShadow: "0 0 4px rgba(0,0,0,0.75)",
    zIndex: 1,
    position: "absolute",
    bottom: buttonHeight,
    marginBottom: responsive({ xs: 0, sm: 3 }),
    left: 0,
    right: 0,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    paddingX: 4,
  },
  slideCaptionContent: { position: "relative", color: "#fff" },
  slideCaptionBackdrop: {
    background:
      "radial-gradient(ellipse at center, rgba(0,0,0,.35) 0%,rgba(0,0,0,0) 50%)",
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    transform: "scale(4,5)",
    transformOrigin: "center",
  },
  slideCaptionSubCaption: { fontSize: 1, position: "relative" },
  slideCaptionText: {
    fontWeight: "bold",
    fontSize: responsive({ xs: 2, sm: 4 }),
    position: "relative",
  },
  slideButtonsWrapper: {
    position: "relative",
    border: (theme: Theme) => `2px solid ${theme.colors?.accent}`,
    borderRadius: "medium",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0 0 1rem 0 rgba(0,0,0,.5)",
  },
};

export default sxStyles;
