/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo, useEffect, useState } from "react";
import { BOOST_TICKETING_PASSWORD_RESET_CODE_URL_PARAM } from "shared/constants";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import { Button, Flex, Grid, Input, jsx, Label, Spinner } from "theme-ui";

import { loginMember } from "../../helpers";
import useLoyaltyMember from "../../hooks/useLoyaltyMember";
import useLoyaltySettings from "../../hooks/useLoyaltySettings";
import ErrorMessage from "./ErrorMessage";
import ForgotPassword from "./ForgotPassword";
import ForgotUsername from "./ForgotUsername";
import { redirectToBoostTicketing } from "./helpers";
import messages from "./i18n";
import ResetPassword from "./ResetPassword";

type FormShape = {
  username: string;
  password: string;
};

interface Props {
  widgetId: string;
}

const Login: React.FC<Props> = ({ widgetId }) => {
  const [, , setMember] = useLoyaltyMember();
  const loyaltySettings = useLoyaltySettings();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(undefined);
  const [showForgotUsername, setShowForgotUsername] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setShowResetPassword(
      window.location.search?.includes(
        `${BOOST_TICKETING_PASSWORD_RESET_CODE_URL_PARAM}=`
      )
    );
  }, []);

  useEffect(() => {
    setError(null);
  }, [showForgotUsername, showForgotPassword]);

  const {
    values,
    setValue,
    setFieldTouched,
    resetValues,
    errors,
    touched,
    isValid,
  } = useForm<FormShape>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object({
      username: yup.string().required("required"),
      password: yup.string().required("required"),
    }),
  });

  if (!loyaltySettings) {
    return null;
  }

  if (showForgotUsername) {
    return (
      <ForgotUsername
        email={values.username}
        widgetId={widgetId}
        loyaltySettings={loyaltySettings}
        handleGoBack={(message?: string) => {
          setShowForgotUsername(false);
          setMessage(message);
        }}
      />
    );
  }

  if (showForgotPassword) {
    return (
      <ForgotPassword
        email={values.username}
        widgetId={widgetId}
        loyaltySettings={loyaltySettings}
        handleGoBack={(message?: string) => {
          setShowForgotPassword(false);
          setMessage(message);
        }}
      />
    );
  }

  if (showResetPassword) {
    return (
      <ResetPassword
        widgetId={widgetId}
        loyaltySettings={loyaltySettings}
        handleGoBack={(message?: string) => {
          setShowResetPassword(false);
          setMessage(message);
        }}
      />
    );
  }

  const onSubmit = async () => {
    setLoading(true);
    setError(undefined);
    loginMember(loyaltySettings, values)
      .then((data) => {
        setLoading(false);
        if (data.peachCode === 0) {
          resetValues();
          setMember(data.memberDetails);
          redirectToBoostTicketing(
            loyaltySettings,
            data?.memberDetails?.encryptedSessionId
          );
        } else {
          setError(data.peachErrorCode);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(formatMessage(messages.genericError));
      });
  };

  return (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        if (!error) {
          await onSubmit();
        }
      }}
    >
      <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
        <div>
          <Label htmlFor={`${widgetId}-loyalty-username`}>
            {`${formatMessage(messages.usernameField)} *`}
          </Label>
          <Input
            id={`${widgetId}-loyalty-username`}
            name={"username"}
            type={"text"}
            value={values.username}
            onChange={(event) => {
              setValue("username", event.target.value);
              setError(undefined);
            }}
            onBlur={() => {
              setFieldTouched("username");
            }}
          />
          {touched.username && errors.username && (
            <ErrorMessage type={errors.username} />
          )}
        </div>

        <div>
          <Label htmlFor={`${widgetId}-loyalty-password`}>
            {`${formatMessage(messages.passwordField)} *`}
          </Label>
          <Input
            id={`${widgetId}-loyalty-password`}
            name={"password"}
            type={"password"}
            value={values.password}
            onChange={(event) => {
              setValue("password", event.target.value);
              setError(undefined);
            }}
            onBlur={() => {
              setFieldTouched("password");
            }}
          />
          {touched.password && errors.password && (
            <ErrorMessage type={errors.password} />
          )}
        </div>
      </Grid>
      <Grid columns="1fr" sx={{ justifyItems: "center", marginTop: 4 }}>
        <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
          <div>
            <Button
              variant={"buttons.secondary"}
              disabled={!isValid || loading}
              type={"submit"}
            >
              {formatMessage(messages.submit)}
            </Button>
          </div>
          <div sx={{ marginLeft: 3 }}>
            {loading && (
              <div>
                <Spinner size={"2rem"} />
              </div>
            )}
            {error && <div sx={{ fontWeight: "bold" }}>{error}</div>}
            {message && <div sx={{ fontWeight: "bold" }}>{message}</div>}
          </div>
        </Flex>
        {/* <div
          role="button"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setShowForgotUsername(true);
          }}
        >
          {formatMessage(messages.forgotUsername)}
        </div> */}
        <div
          role="button"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => {
            setShowForgotPassword(true);
          }}
        >
          {formatMessage(messages.forgotPassword)}
        </div>
      </Grid>
    </form>
  );
};

export default memo(Login);
