import androidEN from "./android/en.svg";
import androidES from "./android/es.svg";
import androidFR from "./android/fr.svg";
import iosEN from "./ios/en.svg";
import iosES_MX from "./ios/es-mx.svg";
import iosFR from "./ios/fr.svg";
import { StorePlatform } from "./types";

export const BADGES: Record<StorePlatform, Record<string, unknown>> = {
  ios: {
    en: iosEN,
    "es-MX": iosES_MX,
    fr: iosFR,
  },
  android: {
    en: androidEN,
    es: androidES,
    fr: androidFR,
  },
};
