/** @jsx jsx */
import { CDNImage, responsive } from "@boxoffice/screenplay";
import React, { memo } from "react";
import Link from "shared/components/Link";
import { getPresentationHeight } from "shared/helpers/image";
import { AspectRatio, jsx } from "theme-ui";

import { ImageMode, Picture } from "../../types";

interface Props {
  aspectRatio: number;
  displayImageMode: ImageMode;
  displayImageColumns: number;
  displayOptionsZoomable: boolean;
  picture: Picture;
  onClick: () => void;
}

const GalleryItem: React.FC<Props> = ({
  aspectRatio,
  displayImageMode,
  displayImageColumns,
  displayOptionsZoomable,
  onClick,
  picture,
}) => {
  if (!picture) {
    return null;
  }

  const pictureWidth = responsive({
    xs: 576,
    sm: Math.ceil(576 / displayImageColumns),
    md: Math.ceil(704 / displayImageColumns),
    lg: Math.ceil(960 / displayImageColumns),
    xl: Math.ceil(1216 / displayImageColumns),
    xxl: Math.ceil(1472 / displayImageColumns),
  });

  const pictureHeight = responsive({
    xs: Math.ceil(576 / aspectRatio),
    sm: Math.ceil(576 / aspectRatio / displayImageColumns),
    md: Math.ceil(704 / aspectRatio / displayImageColumns),
    lg: Math.ceil(960 / aspectRatio / displayImageColumns),
    xl: Math.ceil(1216 / aspectRatio / displayImageColumns),
    xxl: Math.ceil(1472 / aspectRatio / displayImageColumns),
  });

  const isInteractive = displayOptionsZoomable || !!picture?.linkTo;

  return (
    <Link to={picture?.linkTo || undefined} title={picture?.alt || undefined}>
      <div
        onClick={onClick}
        sx={isInteractive ? { cursor: "pointer" } : undefined}
      >
        {displayImageMode === "AUTO" ? (
          <CDNImage
            src={picture.src}
            alt={picture?.alt || ""}
            fit="scale-down"
            width={pictureWidth}
            height={getPresentationHeight(576, picture.probe?.ratio)}
          />
        ) : (
          <AspectRatio ratio={aspectRatio}>
            <CDNImage
              src={picture.src}
              alt={picture?.alt || ""}
              fit={"crop"}
              width={pictureWidth}
              height={pictureHeight}
              sx={{
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                right: 0,
                top: 0,
                width: "100%",
              }}
            />
          </AspectRatio>
        )}
      </div>
    </Link>
  );
};

export default memo(GalleryItem);
