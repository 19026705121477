import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  all: {
    id: "theater-selector.all-theaters-label",
    defaultMessage: "Please select a location",
  },
  none: {
    id: "theater-selector.unselect-theater-label",
    defaultMessage: "Please select a location",
  },
  closed: {
    id: "theater-selector.closed-label",
    defaultMessage: "Closed",
  },
  reopensOn: {
    id: "theater-selector.reopens-on",
    defaultMessage: "Reopens on {date}",
  },
  useMyLocation: {
    id: "theater-selector.use-my-location-label",
    defaultMessage: "Use my location",
  },
});

export default messages;
