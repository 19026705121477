import { responsive } from "@boxoffice/screenplay";
import sxStylesOrig from "gatsby-theme-event-pages/src/components/AllEvents/styles";
import { getTinyColor } from "shared/helpers/colors";
import { Theme, ThemeUIStyleObject } from "theme-ui";

const sxStyles: typeof sxStylesOrig & {
  itemWrapper: ThemeUIStyleObject;
  itemImageWrapper: ThemeUIStyleObject;
  itemContentWrapper: ThemeUIStyleObject;
  eventLinkWrapper: ThemeUIStyleObject;
  itemImage: ThemeUIStyleObject;
} = {
  ...sxStylesOrig,
  itemWrapper: {
    borderTopColor: (theme) => {
      const bg = getTinyColor(theme.rawColors?.background);
      return bg.isLight()
        ? bg.shade(20).toHexString()
        : bg.tint(20).toHexString();
    },
    borderTopWidth: "small",
    borderTopStyle: "solid",
    paddingX: responsive({ xs: 0, xl: 6 }),
    paddingY: 5,
    "&:first-of-type": {
      borderTopWidth: 0,
      marginTop: 0,
      paddingTop: 0,
    },
  },
  itemImageWrapper: {
    marginBottom: responsive({ xs: 0, md: 4 }),
  },
  itemImage: { width: "100%" },
  itemContentWrapper: (theme: Theme) => ({
    marginLeft: responsive<string | number>({
      xs: 0,
      md: `calc(${theme.space?.[4]} * -1)`,
    }),
    position: "relative",
    top: responsive({ xs: 0, md: 4 }),
    paddingBottom: 4,
    paddingLeft: responsive<string | number>({
      xs: 4,
      md: `calc(${theme.space?.[4]} * 2)`,
    }),
    paddingRight: 4,
    paddingTop: 4,
    backgroundColor: "background",
    color: "text",
    borderRadius: "medium",
  }),
  eventLinkWrapper: {
    borderTopWidth: "small",
    borderTopColor: (theme) => {
      const bg = getTinyColor(theme.rawColors?.background);
      return bg.isLight()
        ? bg.shade(20).toHexString()
        : bg.tint(20).toHexString();
    },
    borderTopStyle: "solid",
    paddingTop: 4,
  },
  eventLink: {
    variant: "buttons.primary",
  },
};

export default sxStyles;
