/** @jsx jsx */
import { WidgetUnion } from "gatsby-theme-pages";
import type { Props } from "gatsby-theme-pages/src/components/WidgetWrapper";
import React, { FC, memo } from "react";
import useThemeUI from "shared/hooks/useThemeUI";
import { Container, jsx, ThemeUIStyleObject } from "theme-ui";

const isWidgetRelatedToNextWidget = (
  widget: WidgetUnion | null | undefined
): boolean => {
  switch (widget?.__typename) {
    case "WidgetHeading":
      return true;
  }

  return false;
};

export const isWidgetFullWidth = (widget: WidgetUnion): boolean => {
  let widgetIsFullWidth = false;

  switch (widget.__typename) {
    case "WidgetMovies": {
      if (widget.moviesShape?.display === "heroCarousel") {
        widgetIsFullWidth = true;
      }
      break;
    }

    case "WidgetHeroImage":
    case "WidgetHeroCarousel": {
      widgetIsFullWidth = true;
      break;
    }

    case "WidgetSection": {
      if (
        (widget.sectionShape?.backgroundColor !== "transparent" ||
          !!widget.sectionShape?.border) &&
        widget.sectionShape?.wrapperSize === "FULL"
      ) {
        widgetIsFullWidth = true;
        break;
      }

      widgetIsFullWidth = false;
      break;
    }

    case "WidgetColumns": {
      if (
        (widget.columnsShape?.backgroundColor !== "transparent" ||
          !!widget.columnsShape?.border) &&
        widget.columnsShape?.wrapperSize === "FULL"
      ) {
        widgetIsFullWidth = true;
        break;
      }

      widgetIsFullWidth = false;
      break;
    }
  }

  return widgetIsFullWidth;
};

export const getWidgetSpacing = ({
  isFirst,
  isLast,
  isNested,
  widget,
  nextWidget,
  sectionVerticalGutter,
  sectionVerticalGutterAlt,
  sectionVerticalGutterReset,
}: Props): ThemeUIStyleObject => {
  const isFullWidth = widget ? isWidgetFullWidth(widget) : false;

  const spacing: ThemeUIStyleObject = {
    paddingTop:
      isFirst && !isNested && !isFullWidth
        ? sectionVerticalGutter
        : sectionVerticalGutterReset,
    paddingBottom: isWidgetRelatedToNextWidget(widget)
      ? sectionVerticalGutterAlt
      : sectionVerticalGutter,
  };

  if ((isFullWidth || isNested) && isLast) {
    spacing.paddingBottom = sectionVerticalGutterReset;
  }

  // Make sure that consecutive fullWidth widgets are not separated by a padding
  if (isFullWidth && nextWidget && isWidgetFullWidth(nextWidget)) {
    spacing.paddingBottom = sectionVerticalGutterReset;
  }

  if (nextWidget?.__typename === "WidgetSpacer") {
    spacing.paddingBottom = sectionVerticalGutterReset;
  }

  return spacing;
};

const WidgetWrapper: FC<Props> = ({
  isNested,
  isFirst,
  containerSize,
  isLast,
  children,
  widget,
  prevWidget,
  nextWidget,
  contained = true,
}) => {
  const { theme } = useThemeUI();

  return (
    <div
      sx={getWidgetSpacing({
        isNested,
        isFirst,
        containerSize,
        isLast,
        widget,
        prevWidget,
        nextWidget,
        children,
        // FIXME: type this
        // @ts-expect-error no type for sectionVerticalGutter
        sectionVerticalGutter: theme.sizes?.sectionVerticalGutter,
        // FIXME: type this
        // @ts-expect-error no type for sectionVerticalGutterAlt
        sectionVerticalGutterAlt: theme.sizes?.sectionVerticalGutterAlt,
        // FIXME: type this
        // @ts-expect-error no type for sectionVerticalGutterReset
        sectionVerticalGutterReset: theme.sizes?.sectionVerticalGutterReset,
      })}
    >
      {isNested || !contained ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <Container>{children}</Container>
      )}
    </div>
  );
};

export default memo(WidgetWrapper);
