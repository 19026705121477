/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import {
  DatePickerTriggerProps,
  periodSelectorOtherMessages,
} from "gatsby-theme-schedule-options-bar";
import { FC, memo } from "react";
import { getMostReadable, getMostVisible } from "shared/helpers/colors";
import useIntl from "shared/helpers/i18n/useIntl";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

const DatePickerTrigger: FC<DatePickerTriggerProps> = ({
  className,
  disabled,
  onClick,
}) => {
  const { formatMessage } = useIntl();
  const { theme } = useThemeUI();

  return (
    <div
      className={className}
      sx={{
        variant: "buttons.secondary",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        margin: responsive({ xs: 0, xl: 0 }),
        opacity: disabled ? 0.15 : undefined,
        backgroundColor: theme.rawColors?.background,
        color: getMostReadable(theme.rawColors?.background).toHexString(),
        borderColor: getMostVisible(theme.rawColors?.background, [
          theme.rawColors?.primary,
        ]).toHexString(),
        minHeight: "60px",
        borderRadius: "medium",
        marginLeft: responsive({ sm: 2 }),
        marginTop: responsive({ xs: 4, sm: 0 }),
        "&:hover, &:focus, &.active, a:hover &, a:focus &, a.active &": {
          color: getMostReadable(theme.rawColors?.background).toHexString(),
          borderColor: getMostVisible(theme.rawColors?.background, [
            theme.rawColors?.accent,
          ]).toHexString(),
        },
      }}
      onClick={onClick}
      title={formatMessage(periodSelectorOtherMessages.anotherDate)}
    >
      <span
        sx={{
          fontSize: 4,
          display: responsive({ xs: "none", sm: "block", lg: "none" }),
          lineHeight: 1,
        }}
      >
        <svg
          viewBox="0 0 24 24"
          sx={{
            height: "1em",
            width: "1em",
          }}
        >
          <path
            fill="currentColor"
            d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z"
          />
        </svg>
      </span>
      <span
        sx={{ display: responsive({ xs: "block", sm: "none", lg: "block" }) }}
      >
        {formatMessage(periodSelectorOtherMessages.anotherDate)}
      </span>
    </div>
  );
};

export default memo(DatePickerTrigger);
