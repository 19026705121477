/** @jsx jsx */

import React, { memo } from "react";
import { jsx } from "theme-ui";

import { ANIMATION_DURATION } from "../../constants";
import { getMostReadable } from "../../helpers/colors";
import useThemeUI from "../../hooks/useThemeUI";
import PlayIcon from "../icons/PlayIcon";

interface Props {
  onClick?: () => void;
}

const PlayButton: React.FC<Props> = ({ onClick }) => {
  const { theme } = useThemeUI();

  return (
    <div
      onClick={onClick}
      sx={{
        borderRadius: "maximum",
        backgroundColor: theme.rawColors?.accent,
        color: getMostReadable(theme.rawColors?.accent, [
          theme.rawColors?.background,
        ]).toHexString(),
        cursor: "pointer",
        lineHeight: "45px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "3rem",
        height: "3rem",
        textAlign: "center",
        transition: `all ${ANIMATION_DURATION}`,
        "&:hover, &:focus": {
          outline: "none",
          transform: "scale(1.1)",
          backgroundColor: theme.rawColors?.primary,
          color: getMostReadable(theme.rawColors?.primary, [
            theme.rawColors?.background,
          ]).toHexString(),
        },
      }}
    >
      <PlayIcon />
    </div>
  );
};

export default memo(PlayButton);
