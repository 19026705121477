/** @jsx jsx */
import React, { memo, useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Maybe } from "shared/utils/ts";
import { jsx } from "theme-ui";

import { Item } from "../types";
import Component from "./Component";

export type Props = {
  isDropdownMode: boolean;
  item?: Maybe<Item>;
  onItemSelected: (item: Item | null) => void;
};

const MainMenuItem: React.FC<Props> = ({
  isDropdownMode,
  item,
  onItemSelected,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const hideDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    window.addEventListener("click", hideDropdown);
    return () => {
      window.removeEventListener("click", hideDropdown);
    };
  }, []);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  const hasChildren = (item?.children?.length || 0) > 0;
  const slug = hasChildren ? "#" : item?.url || `/${item?.page?.slug}`;

  return (
    <Component
      attributes={attributes}
      hasChildren={hasChildren}
      isDropdownMode={isDropdownMode}
      item={item}
      setArrowElement={setArrowElement}
      setPopperElement={setPopperElement}
      setReferenceElement={setReferenceElement}
      showDropdown={showDropdown}
      slug={slug}
      styles={styles}
      onClick={
        hasChildren
          ? (event) => {
              event.preventDefault();

              if (isDropdownMode) {
                // Deliberatly delaying this call to avoid the window click that closes everything.
                setTimeout(() => {
                  setShowDropdown(!showDropdown);
                }, 0);
              } else {
                onItemSelected(item);
              }
            }
          : undefined
      }
    />
  );
};

export default memo(MainMenuItem);
