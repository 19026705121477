import { keyframes } from "@emotion/react";
import { ANIMATION_DURATION } from "shared/constants";
import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<"dropdown", ThemeUIStyleObject> = {
  dropdown: {
    animationDuration: ANIMATION_DURATION,
    animationName: keyframes({
      "0%": {
        opacity: 0,
        top: "-8px",
      },
      "100%": {
        opacity: 1,
        top: 0,
      },
    }).toString(),
  },
};

export default sxStyles;
